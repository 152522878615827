import { BrandServices } from "../services"

export const GET_BRANDS_COUNT_REQUEST = 'GET_BRANDS_COUNT_REQUEST'
export const GET_BRANDS_COUNT_SUCCESS = 'GET_BRANDS_COUNT_SUCCESS'
export const GET_BRANDS_COUNT_FAILURE = 'GET_BRANDS_COUNT_FAILURE'

export const GET_BRAND_LIST_REQUEST = 'GET_BRAND_LIST_REQUEST'
export const GET_BRAND_LIST_SUCCESS = 'GET_BRAND_LIST_SUCCESS'
export const GET_BRAND_LIST_FAILURE = 'GET_BRAND_LIST_FAILURE'

export const GET_BRAND_DETAILS_REQUEST = 'GET_BRAND_DETAILS_REQUEST'
export const GET_BRAND_DETAILS_SUCCESS = 'GET_BRAND_DETAILS_SUCCESS'
export const GET_BRAND_DETAILS_FAILURE = 'GET_BRAND_DETAILS_FAILURE'

export const GET_DEAL_DETAILS_REQUEST = 'GET_DEAL_DETAILS_REQUEST'
export const GET_DEAL_DETAILS_SUCCESS = 'GET_DEAL_DETAILS_SUCCESS'
export const GET_DEAL_DETAILS_FAILURE = 'GET_DEAL_DETAILS_FAILURE'

export const GET_BRANDS_COUPON_COUNT_REQUEST = 'GET_BRANDS_COUPON_COUNT_REQUEST'
export const GET_BRANDS_COUPON_COUNT_SUCCESS = 'GET_BRANDS_COUPON_COUNT_SUCCESS'
export const GET_BRANDS_COUPON_COUNT_FAILURE = 'GET_BRANDS_COUPON_COUNT_FAILURE'

export const GET_BRANDS_DAILY_COUPONS_REQUEST = 'GET_BRANDS_DAILY_COUPONS_REQUEST'
export const GET_BRANDS_DAILY_COUPONS_SUCCESS = 'GET_BRANDS_DAILY_COUPONS_SUCCESS'
export const GET_BRANDS_DAILY_COUPONS_FAILURE = 'GET_BRANDS_DAILY_COUPONS_FAILURE'

export const BRAND_SELECT_LIST_REQUEST = 'BRAND_SELECT_LIST_REQUEST';
export const BRAND_SELECT_LIST_SUCCESS = 'BRAND_SELECT_LIST_SUCCESS';
export const BRAND_SELECT_LIST_FAILURE = 'BRAND_SELECT_LIST_FAILURE';

export const BrandAction = {

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getBrandsCount: () => {
        return dispatch => {
            dispatch(request())
            return BrandServices.getBrandsCount()
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        }

        function request() { return { type: GET_BRANDS_COUNT_REQUEST } }
        function success(response) { return { type: GET_BRANDS_COUNT_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status.code } }
        function failure(error) { return { type: GET_BRANDS_COUNT_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }

    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getBrandList: (params) => {
        return dispatch => {
            dispatch(request(params))
            return BrandServices.getBrandList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        }

        function request(request) { return { type: GET_BRAND_LIST_REQUEST, request } }
        function success(request, response) { return { type: GET_BRAND_LIST_SUCCESS, request, message: response.message, data: response.data, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_BRAND_LIST_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getBrandDetails: (params) => {
        return dispatch => {
            dispatch(request(params))
            return BrandServices.getBrandDetails(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(params, error)); }
                );
        }


        function request(request) { return { type: GET_BRAND_DETAILS_REQUEST, request } }
        function success(request, response) { return { type: GET_BRAND_DETAILS_SUCCESS, request, message: response.message, data: response.data, totalCount: response.count, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_BRAND_DETAILS_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getDealDetailsOfBrand: (params) => {
        return dispatch => {
            dispatch(request(params))
            return BrandServices.getDealDetailsOfBrand(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(params, error)); }
                );
        }


        function request(request) { return { type: GET_DEAL_DETAILS_REQUEST, request } }
        function success(request, response) { return { type: GET_DEAL_DETAILS_SUCCESS, request, message: response.message, data: response.data, totalCount: response.count, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_DEAL_DETAILS_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }

    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getBrandCouponCounts: (params = null) => {
        return dispatch => {
            dispatch(request(params))
            return BrandServices.getBrandCouponCounts(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        }


        function request(request) { return { type: GET_BRANDS_COUPON_COUNT_REQUEST, request } }
        function success(request, response) { return { type: GET_BRANDS_COUPON_COUNT_SUCCESS, request, message: response.message, data: response.data, totalCount: response.count, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_BRANDS_COUPON_COUNT_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getDailyCouponsIssued: (params = null) => {
        return dispatch => {
            dispatch(request(params))
            return BrandServices.getDailyCouponsIssued(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(params, error)); }
                );
        }

        function request(request) { return { type: GET_BRANDS_DAILY_COUPONS_REQUEST, request } }
        function success(request, response) { return { type: GET_BRANDS_DAILY_COUPONS_SUCCESS, request, message: response.message, data: response.data, totalCount: response.count, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_BRANDS_DAILY_COUPONS_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getBrandsSelectList: () => {
        return dispatch => {
            dispatch(request());
            return BrandServices.getBrandsSelectList()
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: BRAND_SELECT_LIST_REQUEST } }
        function success(response) { return { type: BRAND_SELECT_LIST_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: BRAND_SELECT_LIST_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },















}