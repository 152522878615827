import { Box, Card, CardContent, CircularProgress, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { BrandAction } from '../../../redux/actions/BrandAction';
import { PostAuthRoutes } from '../../../routes';
import { RouteLink } from '../../../_components/controls'

function BrandsCountWidget() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { brands_count_loading: isLoading, brands_count } = useSelector((state) => state.BrandReducer);
    const getBrandsCount = () => dispatch(BrandAction.getBrandsCount());

    useEffect(() => {
        getBrandsCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Card>
            <CardContent>
                <Stack direction="row" alignItems="center" spacing={2}  >
                    <Box>
                        <Typography variant="h1">{isLoading ? <CircularProgress /> : brands_count}</Typography>
                        <RouteLink to={PostAuthRoutes('brands').path} label={PostAuthRoutes('brands').name} ></RouteLink>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )
}

export { BrandsCountWidget }