import {
    PRICING_MODEL_REQUEST, PRICING_MODEL_SUCCESS, PRICING_MODEL_FAILURE,
    PRICING_MODEL_CREATE_REQUEST, PRICING_MODEL_CREATE_SUCCESS, PRICING_MODEL_CREATE_FAILURE,
    UPDATE_PRICING_MODEL_REQUEST, UPDATE_PRICING_MODEL_SUCCESS, UPDATE_PRICING_MODEL_FAILURE,
    GET_PRICING_MODEL_DETAILS_REQUEST, GET_PRICING_MODEL_DETAILS_SUCCESS, GET_PRICING_MODEL_DETAILS_FAILURE,
    CHANGE_PRICING_MODEL_STATUS_REQUEST, CHANGE_PRICING_MODEL_STATUS_SUCCESS, CHANGE_PRICING_MODEL_STATUS_FAILURE,


} from "../actions";

const initialState = {
    page: 1, per_page: 18, total: 0, total_pages: 0,
    pricing_models_loading: false, pricing_models: [], pricing_models_errors: {},
    create_pricing_model_loading: false, create_pricing_models_errors: {},
    pricing_model_loading: false, pricing_model: [], pricing_model_errors: {},
    change_pricing_model_status_loading: false, change_pricing_model_status_errors: {},
    
};

export function PricingModelReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::pricing model list redux */
        case PRICING_MODEL_REQUEST: {
            return {
                ...state,
                pricing_modes_loading: true,
                pricing_models_errors: {}
            };
        }
        case PRICING_MODEL_SUCCESS: {

            return {
                ...state,
                pricing_modes_loading: false,
                pricing_models: action.data.pricing_models,
                pricing_models_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };

        }
        case PRICING_MODEL_FAILURE: {
            return {
                ...state,
                pricing_modes_loading: false,
                pricing_models_errors: action.errors,
                pricing_models: [],
            };
        }
        /** end::pricing model list redux */

        /** begin::Create pricing model redux */
        case PRICING_MODEL_CREATE_REQUEST: {
            return {
                ...state,
                create_pricing_model_loading: true,
                create_pricing_model_errors: {}
            };
        }
        case PRICING_MODEL_CREATE_SUCCESS: {

            return {
                ...state,
                create_pricing_model_loading: false,
                create_pricing_model_errors: {}
            };

        }
        case PRICING_MODEL_CREATE_FAILURE: {
            return {
                ...state,
                create_pricing_model_loading: false,
                create_pricing_model_errors: action.errors
            };
        }

        /** end::Create pricing model redux */

        /** begin::Update pricing model redux */
        case UPDATE_PRICING_MODEL_REQUEST: {
            return {
                ...state,
                update_pricing_model_loading: true,
                update_pricing_model_errors: {}
            };
        }
        case UPDATE_PRICING_MODEL_SUCCESS: {
            return {
                ...state,
                update_pricing_model_loading: false,
                update_pricing_model: action.data,
                update_pricing_model_errors: {}
            };
        }
        case UPDATE_PRICING_MODEL_FAILURE: {
            return {
                ...state,
                update_pricing_model_loading: false,
                update_pricing_model_errors: action.errors
            };
        }
        /** end::Update pricing model redux */

        //  begin::Get Pricing model details redux 
        case GET_PRICING_MODEL_DETAILS_REQUEST: {
            return {
                ...state,
                pricing_model_loading: true,
                pricing_model_error: {}
            };
        }
        case GET_PRICING_MODEL_DETAILS_SUCCESS: {

            return {
                ...state,
                pricing_model_loading: false,
                pricing_model: action.data,
                pricing_model_error: {},
            };
        }

        case GET_PRICING_MODEL_DETAILS_FAILURE: {
            return {
                ...state,
                pricing_model_loading: false,
                pricing_model: 0,
                pricing_model_error: action.errors
            };
        }
        // begin::Get Pricing model details redux

        
        /** begin::Change pricing models status redux */
        case CHANGE_PRICING_MODEL_STATUS_REQUEST: {
            return {
                ...state,
                change_pricing_model_status_loading: true,
                change_pricing_model_status_errors: {}
            };
        }
        case CHANGE_PRICING_MODEL_STATUS_SUCCESS: {

            let pricingModelArray = [...state.pricing_models]; //making a new array
            if (action.data.page === 1) {
                pricingModelArray = []
            }

            const updatedArray = pricingModelArray.map(data => {
                const arrayFound = action.data.find(data2 => data2._id === data._id);
                if (arrayFound) {
                  return { ...data, status: arrayFound.status };
                }
                return updatedArray;
            });

            return {
                ...state,
                change_pricing_model_status_loading: false,
                pricing_models: updatedArray,
                change_pricing_model_status_errors: {}
            };
        }
        case CHANGE_PRICING_MODEL_STATUS_FAILURE: {
            return {
                ...state,
                change_pricing_model_status_loading: false,
                change_pricing_model_status_errors: action.errors
            };
        }
        /** end::Change pricing models status redux */
        default:
            return state;

    }
}