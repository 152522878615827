import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, CardHeader, CardMedia, Divider, Grid, Link, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ExpandMore } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { DealAction } from '../../../../redux/actions/DealAction';

function DealHistory() {

    /** Initialize plugins and variables */
    let { search } = useLocation();
    const dispatch = useDispatch();

    const params = new URLSearchParams(search);
    const dealId = params.get('dId'); // plan promo from url

    /** Redux actions and state */
    const { deal_history_loading, deal_history } = useSelector((state) => state.DealReducer);
    const getDealUpdateHistory = (params) => dispatch(DealAction.getDealUpdateHistory(params));

    /** Initialize and declare state */

    useEffect(() => {
        getDealUpdateHistory({ dealId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealId])



    if (deal_history_loading)
        return '';


    return (
        <React.Fragment>

            {
                Object.keys(deal_history).length > 0 &&
                deal_history.map((deal, key) => (
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id={`deal_history${key}`}    >
                            <Typography>Deal updated on {deal.updated_at} by {deal.updated_by}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Card style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
                                <CardHeader title="Deal Details" />
                                <CardContent CardContent >
                                    <Grid container spacing={2} >
                                        <Grid item xs={12} md={12}>
                                            <Stack direction="column" spacing={1}>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="body2" component={'span'} dangerouslySetInnerHTML={{ __html: `Direct message to accompany the <br/> coupon code :` }} />
                                                    <Typography variant="h6" component={'span'} dangerouslySetInnerHTML={{ __html: deal.direct_message }} />
                                                </Box>
                                                {
                                                    deal.times_between_post &&
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Typography variant="body2"> {`How Often : `}</Typography>
                                                        <Typography variant="h6">{deal.times_between_post} </Typography>
                                                    </Box>
                                                }
                                                {/* <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="body2">Offer Instagram Handle : </Typography>
                                                    <Link href={deal.insta_handle_url} variant="body2" target="_blank" rel="noreferrer">{deal.insta_handle_with_prefix}</Link>
                                                </Box>
                                                {deal.logo &&
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Typography variant="body2">Logo : </Typography>
                                                        <CardMedia src={deal.logo} component="img" height="100" alt={deal.logo} />
                                                    </Box>
                                                }
                                                <Divider />
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="body2">Background Color : </Typography>
                                                    <Box style={{ width: '25px', height: '25px', border: '1px solid #cfd8dc', backgroundColor: `${(deal.primary_color_hex) ? (deal.primary_color_hex) : ''}` }}> </Box>
                                                </Box>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="body2">Text Color : </Typography>
                                                    <Box style={{ width: '25px', height: '25px', border: '1px solid #cfd8dc', backgroundColor: `${(deal.secondary_color_hex) ? (deal.secondary_color_hex) : ''}` }}> </Box>
                                                </Box>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="body2"> {`Button Color : `}</Typography>
                                                    <Box style={{ width: '25px', height: '25px', border: '1px solid #cfd8dc', backgroundColor: `${(deal.link_color_hex) ? (deal.link_color_hex) : ''}` }}> </Box>
                                                </Box>
                                                <Divider />
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="body2"> {`Giveaway countdown : `}</Typography>
                                                    <Typography variant="h6">{deal.is_giveaway} </Typography>
                                                </Box>
                                                {
                                                    deal.times_between_post &&
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Typography variant="body2"> {`How Often : `}</Typography>
                                                        <Typography variant="h6">{deal.times_between_post} </Typography>
                                                    </Box>
                                                }
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="body2" style={{ minWidth: `250px` }}> {`Description : `}</Typography>
                                                    <Typography variant="h6" component={'span'} dangerouslySetInnerHTML={{ __html: deal.reward_description }} />
                                                </Box>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="body2" style={{ minWidth: `250px` }}> {`Fine Print : `}</Typography>
                                                    <Typography variant="h6" component={'span'} dangerouslySetInnerHTML={{ __html: deal.offer_description }} />
                                                </Box>
                                                {
                                                    deal.giveaway_date &&
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Typography variant="body2"> {`Sweepstakes ends in : `}</Typography>
                                                        <Typography variant="h6" >{deal.giveaway_date}</Typography>
                                                    </Box>
                                                }
                                                <Divider />
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="body2" component={'span'} dangerouslySetInnerHTML={{ __html: `Send follow up message to user <br/>when they can post again : ` }} />
                                                    <Typography variant="h6" component={'span'} dangerouslySetInnerHTML={{ __html: deal.can_send_followup_msg }} />
                                                </Box>
                                                <Divider />
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="body2" component={'span'} dangerouslySetInnerHTML={{ __html: `Direct message to accompany the <br/> coupon code :` }} />
                                                    <Typography variant="h6" component={'span'} dangerouslySetInnerHTML={{ __html: deal.direct_message }} />
                                                </Box>
                                                {deal.sample_story &&
                                                    <React.Fragment>
                                                        <Divider />
                                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                                            <Typography variant="body2">Sample Story : </Typography>
                                                            <CardMedia src={deal.sample_story} component="img" height="150" alt={deal.sample_story} />
                                                        </Box>
                                                    </React.Fragment>
                                                } */}
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </AccordionDetails>
                    </Accordion>
                )
                )
            }
            {Object.keys(deal_history).length === 0 && <CardContent>Not found</CardContent>}
        </React.Fragment>
    )
}

export { DealHistory }