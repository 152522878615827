import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { connect, useDispatch, useSelector } from 'react-redux';

import themeLight from './_theme/theme-light'
import { ThemeProvider } from '@mui/system';
import { CssBaseline } from '@mui/material';
import { PostSignIn } from './_layouts/post-signin';
import { Toast } from './_components/toast';
import { PageLoader, InitialLoader } from './_components/loader';
import { NotFound } from './_components/layout';
import { PreAuthMainComponents, PrivateRoute } from './routes';

import { AppNameContext } from './_contexts';
import { APP_NAME } from './_helpers';
import { GlobalAction } from './redux/actions';
import './App.scss'

function App() {

  const menuRoutes = Object.values(PreAuthMainComponents());


  const dispatch = useDispatch();
  const { pageLoader, initialLoader, toastMessage: toast } = useSelector((state) => state.GlobalReducer);

  useEffect(() => {
    dispatch(GlobalAction.initialLoader(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <AppNameContext.Provider value={APP_NAME}>
      <HelmetProvider>
        <ThemeProvider theme={themeLight}>
          <CssBaseline />
          <Switch>
            {menuRoutes.map((route, index) => (
              <Route exact key={index} path={route.path} component={route.component} />
            ))}
            <PrivateRoute path="/" component={PostSignIn}></PrivateRoute>
            <Route component={NotFound} />
          </Switch>
          <PageLoader loader={pageLoader} />
          <InitialLoader loader={initialLoader} />
          <Toast message={toast} />
        </ThemeProvider>
      </HelmetProvider>
    </AppNameContext.Provider>
  );
}

function mapStateToProps(state) {
  return {
    pageLoader: state.GlobalReducer.pageLoader,
    initialLoader: state.GlobalReducer.initialLoader,
    toast: state.GlobalReducer.toastMessage,
  };
}

function mapDispatchToProps(dispatch) {

  return {
    hideInitialLoader: () => dispatch(GlobalAction.hideInitialLoader())
  }
};



const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export { connectedApp as App };
