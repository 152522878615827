import React, { useEffect, useState } from "react"
import { Card, CardContent, CardHeader } from "../../../../../../_components/card"
import { Grid } from "@mui/material";
import { FormCancelBtn, FormInput, FormSaveBtn, FormSelect } from "../../../../../../_components/form";
import { PostAuthRoutes } from "../../../../../../routes";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { handleInputChange, validateForm } from "../../../../../../_helpers";
import validate from "./validate";
import { CouponAction } from "../../../../../../redux/actions";

const inputs = { campaign: '', amount: '' }

function CreateSingleUseCodes() {

    /** Initialize plugins and variables */
    let { search } = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const params = new URLSearchParams(search);
    const brandId = params.get('bId'); // brand id from url

    /** Redux actions and state */
    const { custom_coupon_campaigns_loading: isCampaignLoading, custom_coupon_campaigns: campaigns } = useSelector((state) => state.CouponReducer);
    const getCustomCouponCampaigns = (params) => dispatch(CouponAction.getCustomCouponCampaigns(params));
    const createSingleUseCodes = (params) => dispatch(CouponAction.createSingleUseCodes(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs, brand_id: brandId });
    const [campaignSelect, setCampaignSelect] = useState([]);
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    useEffect(() => {
        getCustomCouponCampaigns({brand_id: brandId});
    }, [brandId])

    useEffect(() => {
        if (!isCampaignLoading && campaigns && campaigns.length > 0) {
            
            const campaignArray = campaigns.map(item => {
                return {
                  value: item.ID,
                  label: item.name
                };
            });
            setCampaignSelect(campaignArray);
        }
    }, [campaigns])  


    const handleChange = async (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(await validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = await validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }

        dispatchCreateCouponAction(data);
    }

    const dispatchCreateCouponAction = (formData) => {
        let dispatchAction = createSingleUseCodes(formData);
        dispatchAction.then((response) => {
            if (response.status === 1) {
                setAction({ ...action, isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
                let redirectPath = `${PostAuthRoutes('create_coupons').path}?bId=${brandId}`;
                history.push(redirectPath);

            } else {
                setErrors({ ...response.errors })
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }
        });
    }

    const returnToCustomCoupons = () => {
        let redirectPath = `${PostAuthRoutes('create_coupons').path}?bId=${brandId}`;
        history.push(redirectPath);
    }
    
    return (
        <React.Fragment>
            <CardHeader title={`Select Coupons`} />
            <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
                    <Card>
                        <CardContent style={{ paddingTop: `24px` }}>
                            <form onSubmit={handleSubmit} noValidate >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormSelect
                                            label={`Campaign`}
                                            name={`campaign`}
                                            placeholder={`Select campaign`}
                                            onChange={handleChange}
                                            data={campaignSelect}
                                            value={data.campaign}
                                            error={(action.isSubmitted && (errors.campaign)) ? (errors.campaign) : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            label="Quantity"
                                            placeholder="Enter quantity"
                                            name="amount"
                                            maskType={'digit-only'}
                                            tabIndex={2}
                                            value={data.amount}
                                            onChange={handleChange}
                                            error={(action.isSubmitted && (errors.amount)) ? (errors.amount) : ''}
                                            maxLength={100}
                                        />
                                    </Grid>
                                    
                                    <Grid item >
                                        <FormSaveBtn isProcessing={action.isProcessing} text='Create' style={{ marginRight: `5px` }} />
                                        <FormCancelBtn text='Cancel' onClick={returnToCustomCoupons} />
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export { CreateSingleUseCodes }