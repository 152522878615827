import React, { useState } from 'react'
import { Delete } from '@mui/icons-material';
import { GeneralButton } from '../../../../_components/controls';
import { useDispatch, useSelector } from 'react-redux';
import { BrandAction, CouponAction } from '../../../../redux/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { DeleteCouponModal } from './DeleteCouponModal';
import { PostAuthRoutes } from '../../../../routes';

function BrandCoupons() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    const history = useHistory();
    
    const params = new URLSearchParams(search);
    const brandId = params.get('bId'); // plan promo from url

    /** Redux actions and state */
    const { brands_coupon_counts_loading, brands_coupon_counts } = useSelector((state) => state.BrandReducer);
    const deleteActiveCoupons = (params) => dispatch(CouponAction.deleteActiveCoupons(params));
    const getBrandCouponCounts = (params) => dispatch(BrandAction.getBrandCouponCounts(params));

    /** Initialize and declare state */
    const [showDeleteCouponModal, setShowDeleteCouponModal] = useState(false);
    
    /**
     * function to open/close delete coupon model
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const deleteCouponModal = () => {
        setShowDeleteCouponModal(!showDeleteCouponModal);
    }

    const deleteCouponsActionAction = (id) => {
        deleteActiveCoupons({brand_id: id}).then(() => {
            getBrandCouponCounts({ brand_id: id });
            setShowDeleteCouponModal(!showDeleteCouponModal);
        })
    }

    const selectCoupons = () => {
        let redirectPath = `${PostAuthRoutes('select_coupons').path}?bId=${brandId}`;
        history.push(redirectPath);
    }

    return (
        <React.Fragment>

            {!brands_coupon_counts_loading && <GeneralButton color="secondary" disabled={(!brands_coupon_counts) || (brands_coupon_counts && brands_coupon_counts.balance_coupons === 0)} size={`small`} label={<React.Fragment>Delete<br/>Unused Coupons</React.Fragment>} onClick={() => deleteCouponModal()} />}
            <GeneralButton size={`small`} label={<React.Fragment>Select<br/>Coupons</React.Fragment>} onClick={() => selectCoupons()} />

            <DeleteCouponModal show={showDeleteCouponModal} closeModal={deleteCouponModal} brandId={brandId} deleteCoupons={deleteCouponsActionAction} />
        </React.Fragment>
    )
}

export { BrandCoupons }