import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InvoiceAction } from '../../../redux/actions';
import { PostAuthRoutes } from "../../../routes";
import { DataTable } from '../../../_components/data-table/DataTable';
import { useHistory } from "react-router-dom";
import queryString from 'query-string';


function InvoicesDataTable({ filterAction, filter, search, limit, page, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { total, per_page, invoices_loading: isLoading, invoices } = useSelector((state) => state.InvoiceReducer);
    const getInvoiceList = (params) => dispatch(InvoiceAction.getInvoiceList(params));

    /** Initialize and declare state */
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [payload, setPayload] = useState({ filter, search, page, limit });

    const columns = [
        { name: "No", center: true, width: '5%', cell: (row, i) => ((per_page * (page - 1)) + (i + 1)) },
        { name: "Brand", selector: (row, i) => (row.brand_name), sortField: 'brand_name', sortable: true, center: false, width: '12%' },
        { name: "Statement Number", selector: (row, i) => (row.invoice_number), sortField: 'invoice_number', sortable: true, center: true, width: '12%' },
        { name: "Statement Date", selector: (row, i) => (row.created_at), sortField: 'created_at', sortable: true, center: true, width: '15%' },
        { name: "Coupons Date Range", selector: (row, i) => (row.invoice_start_date), sortField: 'invoice_start_date', sortable: 'true', center: true, width: '14%', cell: row => (<CouponsDateRangeRow row={row} />) },
        { name: "Number Of Coupons", selector: (row, i) => (row.total_coupons), sortField: 'total_coupons', sortable: true, center: true, width: '12%' },
        { name: "Amount ($)", selector: (row, i) => (`$ ${row.total_amount}`), sortField: 'total_amount', sortable: true, center: true, width: '10%' },
        { name: "Payment Status", selector: (row, i) => (row.status), sortField: 'status', sortable: true, center: true, width: '10%' },
        { name: "Paid On", selector: (row, i) => (row.created_at), sortField: 'created_at', sortable: true, center: true, width: '10%' },
    ];

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author 
     * @created_at 14 June 2022 
     */
    const CouponsDateRangeRow = ({ row }) => {
        return <React.Fragment>{row.invoice_start_date} -  {row.invoice_end_date} </React.Fragment>
    }

    useEffect(() => {
        setPayload({ filter, search, page, limit, sort });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, search, page, limit])

    useEffect(() => {

        const queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q;
        // if (filter.status) queryParam.status = filter.status; else delete queryParam.status;
        history.push({
            pathname: PostAuthRoutes('invoices').path,
            search: `?${queryString.stringify(queryParam)}`
        });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

    /**
    * function to fetch permission list
    * @param {Object} payload - The params which are used for the api
    * @param {string} payload.page - Page number to load
    * @param {string} payload.limit -  Count of the documents per page
    * @param {string} payload.filter - Filter data
    * @param {string} payload.search - Search key
    * @return json 
    * @created_at 14 June 2022
    */
    const getData = async () => {
        await getInvoiceList(payload);
    }

    /**
     * function to sort columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * function to select columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSelect = (data) => {
        // console.log(data.selectedRows)
    }

    /**
     * function to handle page
     * @param {String} page - Page number to load 
     * @return view
     * @created_at 14 June 2022
     */

    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={invoices}
            total={total}
            per_page={per_page}
            setPage={page => handlePage(page)}
            handleSelect={handleSelect}
            handleSort={handleSort}
        />

    )
}
export { InvoicesDataTable }