import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";

import { Grid, Button } from '@mui/material'
import { Card, CardContent } from './../../../_components/card'
import { FormPassword } from '../../../_components/form'
import { validateForm, handleInputChange } from '../../../_helpers';
import validateChangePassword from './validateChangePassword';
import { UserAction } from '../../../redux/actions';


// Initialize form input values to null
const inputs = { current_password: '', new_password: '', confirm_password: '' };

function ChangePassword({ changePassword }) {

    let history = useHistory();
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    // const [snackBar, setSnackBar] = useState({ type: '', message: '' });

    /**
    * function to handle input changes and alter the value
    * @param object e input object with name and value
    * @author Akshay N
    */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateChangePassword(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }


    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateChangePassword(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchUpdatePassword(data);
    }

    const dispatchUpdatePassword = (data) => {
        changePassword({

            currentPassword: data.current_password,
            newPassword: data.new_password,

        }).then((response) => {
            if (response.status === 1) {
                if (response.errors) {
                    // setSnackBar({type:'error', message:response.message})
                } else {
                    history.push({
                        pathname: '/profile/details',
                        openSnackbar: true,
                        snackbarType: 'success',
                        snackbarMessage: response.message
                    });
                }
                setAction({ ...action, isSubmitted: false, isProcessing: false });
            } else {
                setErrors({ ...response.errors })
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }

        }).catch(() => {
            // setSnackBar({type:'error', message:'Something Went Wrong CLIENT'})setSnackBar
        })
    }

    return (
        <React.Fragment>
            {/* { snackBar.message && <SnackBarAlert message={snackBar.message} type={snackBar.type}/> } */}
            <Card >
                <CardContent >
                    <form onSubmit={handleSubmit} noValidate >
                        <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <FormPassword
                                    tabIndex={1}
                                    label='Current password'
                                    name='current_password'
                                    value={data.current_password}
                                    error={action.isSubmitted && errors.current_password ? errors.current_password : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <FormPassword
                                    tabIndex={1}
                                    label='New password'
                                    name='new_password'
                                    value={data.new_password}
                                    error={action.isSubmitted && errors.new_password ? errors.new_password : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <FormPassword
                                    tabIndex={1}
                                    label='Confirm password'
                                    name='confirm_password'
                                    value={data.confirm_password}
                                    error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Button variant="contained" color="primary" type="submit">Change Password</Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </React.Fragment>

    )
}

const mapStateToProps = (state) => ({
    user: state.UserReducer.user,
})

function mapDispatchToProps(dispatch) {
    return {
        changePassword: (params) => dispatch(UserAction.changePassword(params)),
    }
};

const connectedChangePassword = connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
export { connectedChangePassword as ChangePassword };

