import { Chip, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormSelect } from "../../../../_components/form";
import { Modal, ModalBody, ModalFooterCloseBtn, ModalFooterSaveBtn, ModalHeader } from "../../../../_components/modal";
import { handleInputChange } from "../../../../_helpers";

const inputs = { filter_status: '' };

function PricingModelFilter({ ...props }) {
    /** Initialize and declare state */
    const { show, closeModal, applyFilter, PricingModelFilter } = props;
    const [data, setData] = useState({ ...inputs });
    const [filteredData, setFilteredData] = useState({});

    // Declare filter status select options
    const filterStatus = [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' }
    ]

    /**
     * function to close PricingModel filter modal
     * @param {string} status Entered status on PricingModel filter modal
     * @param {string} month Entered month on PricingModel filter modal 
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const onClose = () => {
        setData({ filter_status: '' });
        closeModal(false);
    }

    /**
     * function to clear PricingModel filter modal
     * @param {string} status Entered status on PricingModel filter modal
     * @param {string} month Entered month on PricingModel filter modal 
     * @return view
     * @author  
     * @created_at 14 June 2022 
     */
    const onClear = () => {
        setData({ filter_status: '' });
        applyFilter({ filter_status: '' });
        closeModal(false);
    }

    useEffect(() => {
        if (show === true) {
            setData({ filter_status: PricingModelFilter.filter_status })
        }

        let filtered = {};
        Object.keys(PricingModelFilter).forEach(function (item, i) {
            if (PricingModelFilter[item])
                filtered[item] = PricingModelFilter[item];
        });
        setFilteredData({ ...filtered })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, PricingModelFilter])

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Naveen
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author  
     * @created_at 14 June 2022 
     */
    function handleSubmit(e) {
        e.preventDefault();
        applyFilter(data);
        closeModal(false);
    }

    /**
    * function to clear all data entered on PricingModel filter modal
    * @return view
    * @author  
    * @created_at 14 June 2022 
    */
    const clearFilterItem = (item) => {
        const clearItem = { ...data };
        clearItem[item] = '';
        setData({ ...clearItem });
        applyFilter({ ...clearItem })
    }

    return (
        <React.Fragment>
            {
                Object.keys(filteredData).length > 0 &&
                (
                    <Grid container mb={2}>
                        <Grid item xs={12} sm={12} lg={12} xl={12} >
                            <Stack direction='row' spacing={1} >
                                {
                                    Object.keys(filteredData).map((item, i) => {
                                        if (item === 'status') {
                                            const status = filterStatus.find(function (status) { return status.value === filteredData[item]; });
                                            return <Chip key={i} label={status.label} onDelete={() => clearFilterItem(item)} />
                                        } else {
                                            return <Chip keys={i} label={filteredData[item]} onDelete={() => clearFilterItem(item)} style={{ textTransform: `capitalize` }} />
                                        }
                                    })
                                }

                            </Stack>
                        </Grid>
                    </Grid>
                )
            }

            <Modal isDialogOpen={show} onClose={onClose} maxWidth='xs'>
                <form onSubmit={handleSubmit} noValidate >
                    <ModalHeader id="PricingModelFilter" onClose={onClose} title='Filter' />
                    <ModalBody>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormSelect
                                    tabIndex={1}
                                    label={`Status`}
                                    name={`filter_status`}
                                    placeholder={`Select status`}
                                    onChange={handleChange}
                                    data={filterStatus}
                                    className="col-md-6"
                                    value={data.filter_status}
                                />
                            </Grid>

                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                                    <ModalFooterSaveBtn tabIndex={3} text='Filter' />
                                    <ModalFooterCloseBtn tabIndex={2} onClose={onClear} text='Clear' />
                                </Stack>
                            </Grid>
                        </Grid>
                    </ModalBody>
                </form>
            </Modal>
        </React.Fragment>
    );
}

export { PricingModelFilter }