import { Home, Password, Person, Business, PersonOutline, Visibility, ManageAccounts, Receipt, Key, Email, ShoppingBag, LocalOffer, AdminPanelSettings, AttachMoney } from "@mui/icons-material";
import { Dashboard } from "../app/dashboard";
import { Brands, BrandDetails, BrandDealUpdate } from "../app/brands";
import { ChangePassword, Profile, ProfileDetails, ProfileUpdate } from "../app/profile";
import { Invoices } from "../app/invoices";
import { Users, CreateUpdateUser, Permission, CreatePermission, Role, CreateRole, AssignPermission } from "../app/user-management";
import { EmailLogs, EmailLogsDetails } from "../app/email-logs";
import { Shoppers, ShoppersBrands } from "../app/shoppers";
import { DealHistory } from "../app/brands/brands-details/deal-history";
import { CreateSingleUseCodes, CustomCoupons, SelectCoupons } from "../app/brands/brands-details/brand-coupons.js";
import { PricingModel } from "../app/pricing-model/PricingModel";
import { CreatePricingModel, CreateTrialCouponCodes, TrialCouponCodes } from "../app/pricing-model";

const allRoutes = {
    'home': {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Home,
        module: 'dashboard',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'dashboards'
    },
    'brands': {
        path: "/brands",
        name: "Brands",
        component: Brands,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Business,
        module: 'brand',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'brands'
    },
    'brand_details': {
        path: "/brands/view",
        name: "Brand Details",
        component: BrandDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Visibility,
        module: 'brand',
        isMainComponent: true,
        menuPermission: 'brands_detail'
    },
    'deals': {
        path: "/deals",
        name: "Deal",
        component: Dashboard,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalOffer,
        module: 'deals',
        isModuleMenuItem: false,
        isMainComponent: true
    },
    'deal_history': {
        path: "/deals/history",
        name: "Deal History",
        component: DealHistory,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalOffer,
        module: 'deals',
        isModuleMenuItem: false,
        isMainComponent: true
    },
    'create_deal': {
        path: "/brands/deal/create",
        name: "Create Brand Deal",
        component: BrandDealUpdate,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Visibility,
        module: 'brand',
        isMainComponent: true,
    },
    'update_deal': {
        path: "/brands/deal/update",
        name: "Update Brand Deal",
        component: BrandDealUpdate,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Visibility,
        module: 'brand',
        isMainComponent: true,
    },
    'select_coupons': {
        path: "/brands/coupons/select",
        name: "Coupons",
        component: SelectCoupons,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalOffer,
        module: 'deals',
        isMainComponent: true,
    },
    'create_coupons': {
        path: "/brands/coupons/create",
        name: "Custom Coupons",
        component: CustomCoupons,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalOffer,
        module: 'deals',
        isMainComponent: true,
    },
    'create_single_use_codes': {
        path: "/brands/coupons/create/single-use-codes",
        name: "Select Coupons",
        component: CreateSingleUseCodes,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalOffer,
        module: 'deals',
        isMainComponent: true,
    },
    'invoices': {
        path: "/invoices",
        name: "Invoices",
        component: Invoices,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Receipt,
        module: 'invoices',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'invoices'
    },

    'email_logs': {
        path: "/email-logs",
        name: "Email Logs",
        component: EmailLogs,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Email,
        module: 'email-logs',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'email_logs'
    },
    'email_logs_details': {
        path: "/email-logs/details",
        name: "Email Logs Detail",
        component: EmailLogsDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Email,
        module: 'email-logs',
        isModuleMenuItem: false,
        isMainComponent: true,
        menuPermission: 'email_logs_details'
    },
    'shoppers': {
        path: "/shoppers",
        name: "Shoppers",
        component: Shoppers,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: ShoppingBag,
        module: 'shopper',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'shoppers'
    },
    'shoppers_brands': {
        path: "/shoppers/brands",
        name: "Shopper's Brands",
        component: ShoppersBrands,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: ShoppingBag,
        module: 'shopper_brands_list',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'shoppers_brands'
    },
    'users': {
        path: "/users",
        name: "Users",
        component: Users,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: PersonOutline,
        module: 'users',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'users',
        hasSeparation: false,
    },
    'create_user': {
        path: "/users/create",
        name: "Create User",
        component: CreateUpdateUser,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Key,
        module: 'users',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'add_user'
    },
    'update_user': {
        path: "/users/update",
        name: "Update User",
        component: CreateUpdateUser,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Key,
        module: 'users',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'update_user'
    },
    /** begin::role module */
    'role': {
        path: "/role",
        name: "Role",
        component: Role,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: AdminPanelSettings,
        module: 'role',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'roles'
    },
    'create_role': {
        path: "/role/create",
        name: "Create Role",
        component: CreateRole,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AdminPanelSettings,
        module: 'role',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'add_role'
    },
    'update_role': {
        path: "/role/update",
        name: "Update Role",
        component: CreateRole,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AdminPanelSettings,
        module: 'role',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'update_role'
    },
    'assign_permission': {
        path: "/role/assign-permission",
        name: "Assign Permission",
        component: AssignPermission,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AdminPanelSettings,
        module: 'role',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'assign_permission'
    },
    /** end::role module */
    /** begin::permission module */
    'permissions': {
        path: "/permissions",
        name: "Permissions",
        component: Permission,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Key,
        module: 'permissions',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'permissions'
    },
    'create_permission': {
        path: "/permissions/create",
        name: "Create Permission",
        component: CreatePermission,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Key,
        module: 'permissions',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'add_permission'
    },
    'update_permission': {
        path: "/permissions/update",
        name: "Update Permission",
        component: CreatePermission,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Key,
        module: 'permissions',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'update_permission'
    },
    /** end::permission module */
    'profile': {
        path: "/profile/:type?",
        name: "Profile",
        component: Profile,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Person,
        module: 'profile',
        isModuleMenu: true,
        isModuleMenuItem: false,
        isMainComponent: true

    },
    'profile_details': {
        path: "/profile/details",
        name: "Profile",
        component: ProfileDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Person,
        module: 'profile',
        isModuleMenuItem: true,

    },
    'profile_update': {
        path: "/profile/update",
        name: "Profile Update",
        component: ProfileUpdate,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: ManageAccounts,
        module: 'profile',
        isModuleMenuItem: true,

    },
    'change_password': {
        path: "/profile/change-password",
        name: "Change Password",
        component: ChangePassword,
        layout: "/post-auth",
        menuIcon: Password,
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },

    /** begin::pricing model module */
    'pricing_model': {
        path: "/pricing-model",
        name: "Pricing Model",
        component: PricingModel,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: AttachMoney,
        module: 'pricing_model',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'pricing_model'
    },

    'create_pricing_model': {
        path: "/pricing-model/create",
        name: "Create Pricing Model",
        component: CreatePricingModel,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AttachMoney,
        module: 'pricing_model',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'add_pricing_model'
    },

    'update_pricing_model': {
        path: "/pricing-model/update",
        name: "Update Pricing Model",
        component: CreatePricingModel,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AttachMoney,
        module: 'pricing_model',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'update_pricing_model'
    },

    'trial_coupon_codes': {
        path: "/pricing-model/trial-coupon-codes",
        name: "Trial Coupon Codes",
        component: TrialCouponCodes,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AttachMoney,
        module: 'pricing_model',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'trial_coupon_codes'
    },

    'create_trial_coupon_codes': {
        path: "/pricing-model/trial-coupon-codes/create",
        name: "Create Trial Coupon Codes",
        component: CreateTrialCouponCodes,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: AttachMoney,
        module: 'pricing_model',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'create_trial_coupon_codes'
    },
};

function PostAuthRoutes(keyType = null) {
    if (keyType) {
        return allRoutes[keyType];
    }
}

function PostAuthMenuRoutes() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMenuItem === true;
    });
}

function PostAuthMainComponents() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMainComponent === true ? (
            { ...item, path: (item.isModuleMenu) ? (item.path.replace(':type?', '/*')) : (item.path) }
        ) : null;
    });
}

function PostAuthModuleMenuRoutes(module) {
    return Object.values(allRoutes).filter(function (item) {
        return (item.isModuleMenuItem === true && item.module === module) ? ({
            ...item, path: item.path.split("/").pop()
        }) : null;
    });
}

function getPostComponentNameByPath(path) {
    if (path) {
        let componentItem = Object.values(allRoutes).filter(function (item) {
            return item.path === path;
        });
        return componentItem.length > 0 ? componentItem[0]['name'] : '';
    }
}


export { PostAuthRoutes, PostAuthMenuRoutes, PostAuthModuleMenuRoutes, PostAuthMainComponents, getPostComponentNameByPath };
