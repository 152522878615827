import { CouponServices } from "../services"
import { GlobalAction } from "./GlobalAction";

export const DELETE_ACTIVE_COUPONS_REQUEST = 'DELETE_ACTIVE_COUPONS_REQUEST';
export const DELETE_ACTIVE_COUPONS_SUCCESS = 'DELETE_ACTIVE_COUPONS_SUCCESS';
export const DELETE_ACTIVE_COUPONS_FAILURE = 'DELETE_ACTIVE_COUPONS_FAILURE';

export const IMPORT_COUPONS_REQUEST = 'IMPORT_COUPONS_REQUEST';
export const IMPORT_COUPONS_SUCCESS = 'IMPORT_COUPONS_SUCCESS';
export const IMPORT_COUPONS_FAILURE = 'IMPORT_COUPONS_FAILURE';

export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_FAILURE = 'GET_COUPONS_FAILURE';

export const GET_CUSTOM_COUPONS_REQUEST = 'GET_CUSTOM_COUPONS_REQUEST';
export const GET_CUSTOM_COUPONS_SUCCESS = 'GET_CUSTOM_COUPONS_SUCCESS';
export const GET_CUSTOM_COUPONS_FAILURE = 'GET_CUSTOM_COUPONS_FAILURE';

export const CREATE_COUPONS_REQUEST = 'CREATE_COUPONS_REQUEST';
export const CREATE_COUPONS_SUCCESS = 'CREATE_COUPONS_SUCCESS';
export const CREATE_COUPONS_FAILURE = 'CREATE_COUPONS_FAILURE';

export const CREATE_SINGLE_USE_CODES_REQUEST = 'CREATE_SINGLE_USE_CODES_REQUEST';
export const CREATE_SINGLE_USE_CODES_SUCCESS = 'CREATE_SINGLE_USE_CODES_SUCCESS';
export const CREATE_SINGLE_USE_CODES_FAILURE = 'CREATE_SINGLE_USE_CODES_FAILURE';

export const GET_CUSTOM_COUPON_CAMPAIGNS_REQUEST = 'GET_CUSTOM_COUPON_CAMPAIGNS_REQUEST';
export const GET_CUSTOM_COUPON_CAMPAIGNS_SUCCESS = 'GET_CUSTOM_COUPON_CAMPAIGNS_SUCCESS';
export const GET_CUSTOM_COUPON_CAMPAIGNS_FAILURE = 'GET_CUSTOM_COUPON_CAMPAIGNS_FAILURE';

export const GET_CUSTOM_CODES_REQUEST = 'GET_CUSTOM_CODES_REQUEST';
export const GET_CUSTOM_CODES_SUCCESS = 'GET_CUSTOM_CODES_SUCCESS';
export const GET_CUSTOM_CODES_FAILURE = 'GET_CUSTOM_CODES_FAILURE';


export const CouponAction = {
    deleteActiveCoupons: (params) => {
        return dispatch => {
            dispatch(request(params));
            return CouponServices.deleteActiveCoupons(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request(request) { return { type: DELETE_ACTIVE_COUPONS_REQUEST, request: request } }
        function success(request, response) { return { type: DELETE_ACTIVE_COUPONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: DELETE_ACTIVE_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    importCoupons: (params) => {
        return dispatch => {
            dispatch(request(params));
            return CouponServices.importCoupons(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request(request) { return { type: IMPORT_COUPONS_REQUEST, request: request } }
        function success(request, response) { return { type: IMPORT_COUPONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: IMPORT_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    couponsList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return CouponServices.couponsList(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request(request) { return { type: GET_COUPONS_REQUEST, request: request } }
        function success(request, response) { return { type: GET_COUPONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: GET_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    getCustomCoupons: (params) => {
        return dispatch => {
            dispatch(request(params));
            return CouponServices.getCustomCoupons(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request(request) { return { type: GET_CUSTOM_COUPONS_REQUEST, request: request } }
        function success(request, response) { return { type: GET_CUSTOM_COUPONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: GET_CUSTOM_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    createCoupons: (params) => {
        return dispatch => {
            dispatch(request(params));
            return CouponServices.createCoupons(params)
                .then(
                    response => {
                        dispatch(GlobalAction.showToastMessage(response.message));
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(error)); }
                );
        };
    
        function request(request) { return { type: CREATE_COUPONS_REQUEST, request: request } }
        function success(request, response) { return { type: CREATE_COUPONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: CREATE_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    
    
    /**
     * Action to create single use codes
     *
     * @author Jasin 
     */
    createSingleUseCodes: (params) => {
        return dispatch => {
            dispatch(request(params));
            return CouponServices.createSingleUseCodes(params)
                .then(
                    response => {
                        dispatch(GlobalAction.showToastMessage(response.message));
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(error)); }
                );
        };
    
        function request(request) { return { type: CREATE_SINGLE_USE_CODES_REQUEST, request: request } }
        function success(request, response) { return { type: CREATE_SINGLE_USE_CODES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: CREATE_SINGLE_USE_CODES_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    
    /**
     * Action get custom coupons
     *
     * @author Akshay 
     */
    getCustomCouponCampaigns: (params) => {
        return dispatch => {
            dispatch(request());
            return CouponServices.getCustomCouponCampaigns(params)
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); });
        };
    
        function request(params) { return { type: GET_CUSTOM_COUPON_CAMPAIGNS_REQUEST, request: params } }
        function success(response) { return { type: GET_CUSTOM_COUPON_CAMPAIGNS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: GET_CUSTOM_COUPON_CAMPAIGNS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    
    /**
     * Action get coupon
     *
     * @author Akshay 
     */
    getAllCustomCodes: (params) => {
        return dispatch => {
            dispatch(request());
            return CouponServices.getAllCustomCodes(params)
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); });
        };
    
        function request(params) { return { type: GET_CUSTOM_CODES_REQUEST, request: params } }
        function success(response) { return { type: GET_CUSTOM_CODES_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: GET_CUSTOM_CODES_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    }
}