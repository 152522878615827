import {
    GET_INVOICE_LIST_REQUEST, GET_INVOICE_LIST_SUCCESS, GET_INVOICE_LIST_FAILURE,
} from '../actions'

const initialState = {
    page: 1, per_page: 18, total: 0, total_pages: 0, invoices_loading: false, invoices: [], invoices_errors: {},
}


export function InvoiceReducer(state = initialState, action) {
    switch (action.type) {

        //  begin::Get invoice list redux 
        case GET_INVOICE_LIST_REQUEST: {
            return {
                ...state,
                invoices_loading: true,
                invoices_errors: {}
            }
        }
        case GET_INVOICE_LIST_SUCCESS: {

            let newArray = [...state.invoices]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.invoices.forEach((payload) => {
                const invoiceIndex = newArray.findIndex(invoices => invoices._id === payload._id);
                if (invoiceIndex !== -1) {
                    newArray[invoiceIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                invoices_loading: false,
                invoices: newArray,
                invoices_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };

        }
        case GET_INVOICE_LIST_FAILURE: {
            return {
                ...state,
                invoices_loading: false,
                invoices_errors: action.errors
            }
        }
        // begin::Get invoice list redux 

        default:
            return state
    }

}
