
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Route, Switch } from "react-router-dom";

import { cookieServices } from '../../_helpers';
import { PostAuthMainComponents } from '../../routes';
import ability from '../../_helpers/permission/UserAbility';

import { Box } from '@mui/material';

import { Header, SideBar, StyledPostSignIn } from '.'

const PostSignIn = () => {

    let history = useHistory();
    const menuRoutes = Object.values(PostAuthMainComponents());

    const { isDrawerOpen: open } = useSelector((state) => state.GlobalReducer);
    const { user } = useSelector((state) => state.UserReducer);

    useEffect(() => {

        if (!cookieServices.get('accessToken'))
            history.push('/login');

    }, [history])

    return (
        <StyledPostSignIn>
            <Header />
            <SideBar />
            <Box component="main" className={`${open ? 'drawer-open' : ``}`}>
                <Switch>
                    {menuRoutes.map((route, index) => (
                        <Route exact key={index} path={route.path} component={((!route.menuPermission) || (user.role_slug === 'developer') || (ability.can(route.menuPermission, 'all'))) ? route.component : null} />
                    ))}
                </Switch>
            </Box>
        </StyledPostSignIn>
    )
}

export { PostSignIn };

