import { Box, Card, CardContent, CircularProgress, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DealAction } from '../../../redux/actions/DealAction';
import { PostAuthRoutes } from '../../../routes';
import { RouteLink } from '../../../_components/controls'

function DealsCountWidget() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { deals_count_loading: isLoading, deals_count } = useSelector((state) => state.DealReducer);
    const getDealsCount = () => dispatch(DealAction.getDealsCount());

    useEffect(() => {
        getDealsCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Card>
            <CardContent>
                <Stack direction="row" alignItems="center" spacing={2}  >
                    <Box>
                        <Typography variant="h1">{isLoading ? <CircularProgress /> : deals_count}</Typography>
                        <RouteLink to={PostAuthRoutes('deals').path} label={PostAuthRoutes('deals').name} ></RouteLink>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )
}

export { DealsCountWidget }