import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Helmet } from "react-helmet-async";

import { APP_NAME, handleInputChange, validateForm } from '../../../_helpers';
import { PreSignIn } from '../../../_layouts/pre-signin';
import { PreAuthRoutes } from '../../../routes';

import { FormInput } from '../../../_components/form';
import { SubmitButton } from '../../../_components/controls';

import { Grid } from '@mui/material';
import { SignInAction } from '../../../redux/actions';
import { validate } from '.';

// Initialize form input values to null
const inputs = { email: '' };

function SignIn() {

    /** Initialize plugins and variables */
    const history = useHistory();
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { loading: isLoading, insta_signin_loading: isInstaSigninLoading, errors: signinErrors } = useSelector((state) => state.SignInReducer);
    const signIn = (params) => dispatch(SignInAction.signIn(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...signinErrors });
    }, [signinErrors])

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchSignInAction();
    }

    /**
     * function to dispatch sign in
     * @author Akshay N
     * @created_at 30 May 2022
     */
    async function dispatchSignInAction() {
        const isSignin = await signIn(data);
        if (isSignin.status === 1) {
            let redirectPath = `/login/pw?e=${encodeURIComponent(data.email)}`;
            history.push(redirectPath);
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PreAuthRoutes('login').name}`}</title>
            </Helmet>
            <PreSignIn>
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormInput
                                tabIndex={1}
                                label='Email'
                                name='email'
                                value={data.email}
                                error={action.isSubmitted && errors.email ? errors.email : ''}
                                onChange={handleChange}
                                autoFocus={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} display={`flex`} justifyContent={`right`}>
                            <SubmitButton label={'Next'} loading={isLoading || isInstaSigninLoading}  fullWidth/>
                        </Grid>
                    </Grid>
                </form>
            </PreSignIn>
        </React.Fragment>
    );
}

export { SignIn };