import {
    GET_TRIAL_COUPONS_REQUEST, GET_TRIAL_COUPONS_SUCCESS, GET_TRIAL_COUPONS_FAILURE,
    CREATE_TRIAL_COUPONS_REQUEST, CREATE_TRIAL_COUPONS_SUCCESS, CREATE_TRIAL_COUPONS_FAILURE,


} from '../actions'

const initialState = {
    page: 1, per_page: 18, total: 0, total_pages: 0, trial_coupon_list_loading: false, trial_coupon_list: [], trial_coupon_list_errors: {},
    create_trial_coupon_code_loading: false, create_trial_coupon_codes_errors: {},

}

export function TrialCouponReducer(state = initialState, action) {
    switch (action.type) {

    //  begin::Get busineee list redux 
        case GET_TRIAL_COUPONS_REQUEST: {
            return {
                ...state,
                trial_coupon_list_loading: true,
                trial_coupon_list_errors: {}
            };
        }
        case GET_TRIAL_COUPONS_SUCCESS: {
            let newArray = [...state.trial_coupon_list]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            } 
            newArray = action.data.coupon_codes;

            return {
                ...state,
                trial_coupon_list_loading: false,
                trial_coupon_list: newArray,
                trial_coupon_list_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };
        }

        case GET_TRIAL_COUPONS_FAILURE: {
            return {
                ...state,
                trial_coupon_list_loading: false,
                trial_coupon_list_errors: action.errors,
                trial_coupon_list: [],

            };
        }
        // begin::Get busineee details redux 

         /** begin::Create trial coupon redux */
        case CREATE_TRIAL_COUPONS_REQUEST: {
            return {
                ...state,
                create_trial_coupon_code_loading: true,
                create_trial_coupon_code_errors: {}
            };
        }
        case CREATE_TRIAL_COUPONS_SUCCESS: {
            return {
                ...state,
                create_trial_coupon_code_loading: false,
                create_trial_coupon_code_errors: {}
            };
        }
        case CREATE_TRIAL_COUPONS_FAILURE: {
            return {
                ...state,
                create_trial_coupon_code_loading: false,
                create_trial_coupon_code_errors: action.errors
            };
        }
        /** end::Create trial coupon redux */
        default:
            return state

    }
}