import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { APP_NAME, handleInputChange, validateForm } from "../../../../_helpers";
import { validate } from ".";
import { CancelButton, SubmitButton } from "../../../../_components/controls";
import { FormInput, FormSelect } from "../../../../_components/form";
import { Box, Grid, Stack } from "@mui/material";
import { PermissionAction } from "../../../../redux/actions";
import { PostAuthRoutes } from "../../../../routes";
import { Card, CardHeader, CardContent } from "../../../../_components/card";

// Initialize form input values to null
const inputs = { permission_id: '', name: '', parent_id: '' };

function CreatePermission() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Permission id and details on update page */
    const location = useLocation();
    const { permission } = location.state ?? {};

    /** Redux actions and state */
    const { add_permission_loading: isLoading, add_permission_errors: createPermissionErrors, permissions_select_list_loading: isPermissionsLoading, permissions_select_list } = useSelector((state) => state.PermissionReducer);
    const addPermission = (params) => dispatch(PermissionAction.addPermission(params));
    const updatePermission = (params) => dispatch(PermissionAction.updatePermission(params));
    const permissionSelectList = (params) => dispatch(PermissionAction.permissionSelectList(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });
    const [isSetPermissionLoading, setPermissionLoading] = useState(true);

    useEffect(() => {
        permissionSelectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setErrors({ ...createPermissionErrors });
    }, [createPermissionErrors])

    useEffect(() => {
        if (permission && permission._id) {
            setData({
                permission_id: permission._id,
                name: permission.name,
                parent_id: permission.parent_id,
            });
            setPermissionLoading(false);
        } else {
            setPermissionLoading(false);
        }
    }, [permission])


    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle update brand form submit action
     * @param {object} e form object
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchUpdatePermissionAction(data);
    }

    /**
     * function to dispatch update brand api
     * @author Jasin
     * @created_at 14 June 2022
     */
    async function dispatchUpdatePermissionAction(formData) {
        const isCreated = permission && permission._id ? await updatePermission(formData) : await addPermission(formData);

        if (isCreated && isCreated.status === 1) {
            let redirectPath = `${PostAuthRoutes('permissions').path}`;
            history.push(redirectPath);
        }
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 14 June 2022 
     */
    function cancelPermission() {
        let redirectPath = `${PostAuthRoutes('permissions').path}`;
        history.push(redirectPath);
    }


    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${permission && permission._id ? PostAuthRoutes('update_permission').name : PostAuthRoutes('create_permission').name}`}</title>
            </Helmet>
            <Box>
                <CardHeader title={permission && permission._id ? PostAuthRoutes('update_permission').name : PostAuthRoutes('create_permission').name} />
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" display={`flex`} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Card >
                                {!isSetPermissionLoading && !isPermissionsLoading && <CardContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={1}
                                                label='Permission'
                                                name='name'
                                                value={data.name}
                                                error={action.isSubmitted && errors.name ? errors.name : ''}
                                                onChange={handleChange}
                                                autoFocus={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormSelect
                                                tabIndex={2}
                                                label={`Parent Permission`}
                                                name={`parent_id`}
                                                placeholder={`Select parent permission`}
                                                onChange={handleChange}
                                                data={permissions_select_list}
                                                error={action.isSubmitted && errors.parent_id ? errors.parent_id : ''}
                                                className="col-md-6"
                                                value={data.parent_id}
                                            />
                                        </Grid>

                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label={permission && permission._id ? 'Update' : 'Create'} loading={isLoading} tabIndex={4} />
                                                <CancelButton onClick={cancelPermission} tabIndex={7} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>}
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    )
}

export { CreatePermission };
