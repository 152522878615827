import { apiServices, cookieServices } from '../../_helpers';


export const UserServices = {

    /**
     * Service for get user details
     * @author Akshay 
     */
    getUserDetails: () => {
        return apiServices.get('/super-admins')
            .then((response) => { return response; })
    },

    /**
     * Service for get user details
     * @author Akshay 
     */
    getUserPermission: () => {
        return apiServices.get('/super-admins/permissions')
            .then((response) => { return response; })
    },

    /**
     * function get users list
     * @param params
     * @return view
     * @author Jasin
     * @created_at 10/12/2021
     */
    getUsersList: (params) => {
        return apiServices.post(`/super-admins/list`, params)
            .then((response) => { return response; })
    },

    /**
     * function add user
     * @param params
     * @return view
     * @author Jasin
     * @created_at 10/12/2021
     */
    addUser: (params) => {
        return apiServices.post('/super-admins/add', params)
            .then((response) => { return response; })
    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    updateUser: (params) => {
        return apiServices.put('/super-admins/update', params)
            .then((response) => { return response; })
    },

    /**
     * Service to update admin detail
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 30 Mar 2023
     */
    updateUserDetails: (params) => {
        return apiServices.put('/super-admins/detail/update', params)
            .then((response) => { return response; })
    },

    /**
     * function update user status
     * @param params
     * @return view
     * @author Jasin
     * @created_at 30/12/2021
     */
    changeStatus: (params) => {
        return apiServices.put('/super-admins/status/update', params)
            .then((response) => { return response; })
    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    logout: () => {
        return apiServices.get('/logout')
            .then((response) => {
                cookieServices.remove('accessToken');
                return response;
            })
    },

    /**
       * Service for Changing Password
       * @author 
    */
    changePassword: (params) => {

        return apiServices.put(`/profile/change_password`, { params: params })
            .then((response) => {
                return response;
            })
    }
};
