import {
    GET_DEALS_COUNT_REQUEST, GET_DEALS_COUNT_SUCCESS, GET_DEALS_COUNT_FAILURE,
    GET_DEAL_DETAILS_REQUEST, GET_DEAL_DETAILS_SUCCESS, GET_DEAL_DETAILS_FAILURE,
    DEAL_HISTORY_REQUEST, DEAL_HISTORY_SUCCESS, DEAL_HISTORY_FAILURE
} from '../actions'

const initialState = {
    deals_count_loading: true, deals_count: 0, deals_count_error: {},
    deal_history_loading: true, deal_history: [], deal_history_error: {}
}

export function DealReducer(state = initialState, action) {
    switch (action.type) {

        //  begin::Get deals list redux 
        case GET_DEALS_COUNT_REQUEST: {
            return {
                ...state,
                deals_count_loading: true,
                deals_count_error: {}
            };
        }
        case GET_DEALS_COUNT_SUCCESS: {

            return {
                ...state,
                deals_count_loading: false,
                deals_count: action.data,
                deals_count_error: {},
            };
        }

        case GET_DEALS_COUNT_FAILURE: {
            return {
                ...state,
                deals_count_loading: false,
                deals_count: 0,
                deals_count_error: action.errors
            };
        }
        //  begin::Get deals list redux 
        case GET_DEAL_DETAILS_REQUEST: {
            return {
                ...state,
                deal_loading: true,
                deal_error: {}
            };
        }
        case GET_DEAL_DETAILS_SUCCESS: {

            return {
                ...state,
                deal_loading: false,
                deal: action.data,
                deal_error: {},
            };
        }

        case GET_DEAL_DETAILS_FAILURE: {
            return {
                ...state,
                deal_loading: false,
                deal: 0,
                deal_error: action.errors
            };
        }
        // begin::Get busineee details redux
        //  begin::Get deals list redux 
        case DEAL_HISTORY_REQUEST: {
            return {
                ...state,
                deal_history_loading: true,
                deal_history_error: {}
            };
        }
        case DEAL_HISTORY_SUCCESS: {

            return {
                ...state,
                deal_history_loading: false,
                deal_history: action.data,
                deal_history_error: {},
            };
        }

        case DEAL_HISTORY_FAILURE: {
            return {
                ...state,
                deal_history_loading: false,
                deal_history: 0,
                deal_history_error: action.errors
            };
        }
        // begin::Get busineee details redux

        default:
            return state
    }

}
