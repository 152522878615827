import { apiServices } from "../../_helpers";

export const BrandServices = {
    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getBrandsCount: (params) => {
        return apiServices.get(`/brands/count`)
            .then((response) => { return response; })
    },
    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getBrandList: (params) => {
        return apiServices.post(`/brands/list`, params)
            .then((response) => { return response; })
    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getBrandDetails: (params) => {
        return apiServices.post(`/brands/detail`, params)
            .then((response) => { return response; })
    },
    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getDealDetailsOfBrand: (params) => {
        return apiServices.post(`/brands/deal/detail`, params)
            .then((response) => { return response; })
    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getBrandCouponCounts: (params) => {
        return apiServices.post(`/brands/coupons/count`, params)
            .then((response) => { return response; })
    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getDailyCouponsIssued: (params) => {
        return apiServices.post(`/brands/coupons/issued`, params)
            .then((response) => { return response; })
    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getBrandsSelectList: () => {
        return apiServices.get(`/brands/list/select`)
            .then((response) => { return response; })
    },
}
