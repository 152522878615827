import React, { useEffect, useState } from "react";
import { handleInputChange, validateForm } from "../../../../../../_helpers";
import validate from "./validate";
import { CouponAction } from "../../../../../../redux/actions";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { PostAuthRoutes } from "../../../../../../routes";
import { Grid } from "@mui/material";
import { Card, CardContent } from "../../../../../../_components/card";
import { FormCancelBtn, FormInput, FormSaveBtn } from "../../../../../../_components/form";

const inputs = {
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
}

function CreateCoupons({ account }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();
    let { search } = useLocation();

    const searchParams = new URLSearchParams(search);
    const brandId = searchParams.get('bId'); // brand id from url
    
    /** Redux actions and state */
    const { create_coupons_loading: isLoading } = useSelector((state) => state.CouponReducer);
    const getCustomCoupons = (params) => dispatch(CouponAction.getCustomCoupons(params))
    const createCoupons = (params) => dispatch(CouponAction.createCoupons(params))

    const [data, setData] = useState({ ...inputs, brand_id: brandId });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [subAccount, setSubAccount] = useState('');

    useEffect(() => {
        if (account && Object.keys(account).length > 0) {
            setData({
                first_name: account.first_name,
                last_name: account.last_name,
                email: account.email,
                company_name: account.company_name,
                brand_id: brandId
            });
            setSubAccount(account.subaccount ?? '')
        }
            
    }, [account])

    useEffect(() => {
        getCustomCoupons();
    }, [brandId])


    const handleChange = async (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(await validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = await validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }

        dispatchCreateCouponAction(data);
    }

    const dispatchCreateCouponAction = (formData) => {
        let dispatchAction = createCoupons(formData);
        dispatchAction.then((response) => {
            if (response.status === 1) {
                setAction({ ...action, isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
                window.location.reload();
            } else {
                setErrors({ ...response.errors })
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }
        });
    }

    const returnToCoupons = () => {
        let redirectPath = `${PostAuthRoutes('select_coupons').path}?bId=${brandId}`;
        history.push(redirectPath);
    }

    return (
        <React.Fragment>
            <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
                    <Card>
                        <CardContent style={{ paddingTop: `24px` }}>
                            <form onSubmit={handleSubmit} noValidate className='ns-form' >

                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            label="First Name"
                                            placeholder="Enter first name"
                                            name="first_name"
                                            tabIndex={1}
                                            value={data.first_name}
                                            onChange={handleChange}
                                            error={(action.isSubmitted && (errors.first_name)) ? (errors.first_name) : ''}
                                            maxLength={100}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            label="Last Name"
                                            placeholder="Enter last name"
                                            name="last_name"
                                            tabIndex={2}
                                            value={data.last_name}
                                            onChange={handleChange}
                                            error={(action.isSubmitted && (errors.last_name)) ? (errors.last_name) : ''}
                                            maxLength={100}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            label="Email"
                                            placeholder="Enter email"
                                            name="email"
                                            type="email"
                                            tabIndex={3}
                                            value={data.email}
                                            onChange={handleChange}
                                            error={(action.isSubmitted && (errors.email)) ? (errors.email) : ''}
                                            maxLength={100}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            label="Company Name"
                                            placeholder="Enter company name"
                                            name="company_name"
                                            tabIndex={4}
                                            value={data.company_name}
                                            onChange={handleChange}
                                            error={(action.isSubmitted && (errors.company_name)) ? (errors.company_name) : ''}
                                            maxLength={100}
                                        />
                                    </Grid>

                                    <Grid item >
                                        <FormSaveBtn disabled={(subAccount || isLoading) ? true : false} isProcessing={action.isProcessing || isLoading} text='Create Coupons' style={{ marginRight: `5px` }} />
                                        <FormCancelBtn text='Cancel' onClick={returnToCoupons} />
                                    </Grid>
                                    
                                </Grid>

                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment >
    )
}

export { CreateCoupons }