import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalFooterCloseBtn, ModalFooterSaveBtn } from "../../../../_components/modal";
import { Card, CardContent } from "../../../../_components/card";
import { CardMedia, Stack, Typography } from "@mui/material";
import questinMarkSvg from './question-mark.svg'

function DeleteCouponModal({ show, showLoader, brandId, closeModal, deleteCoupons }) {

    const [action] = useState({ isProcessing: false, isSubmitted: false });

    const onClose = () => {
        closeModal();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        deleteCoupons(brandId);
    }

    return (
        <Modal isDialogOpen={show} onClose={onClose} loader={showLoader} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
            <Card style={{ boxShadow: `none`, padding: 30 }}>
                <CardMedia component="img" image={questinMarkSvg} style={{ position: "absolute", padding: 0, bottom: 0, right: 0, top: 0, width: "60%", opacity: '0.1' }} />
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <ModalBody style={{ padding: 0 }}>
                        <CardContent style={{ display: `flex`, alignItems: `center`, padding: `2rem 1rem 0 1rem` }}>
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `165px` }}>
                                <Typography variant='h3'>Are You Sure?</Typography>
                                <Typography variant='body2'>Do you want to remove all active coupons?</Typography>
                            </Stack>
                        </CardContent>
                    </ModalBody>
                    <ModalFooter style={{ padding: 0 }}>
                        <ModalFooterSaveBtn tabIndex={3} text={`Confirm`} isProcessing={action.isProcessing} />
                        <ModalFooterCloseBtn tabIndex={4} onClose={onClose} />
                    </ModalFooter>
                </form>
            </Card>
        </Modal>
    )
}

export { DeleteCouponModal }
