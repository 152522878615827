
import { useEffect } from 'react';
import { cookieServices } from '../../_helpers';
import { useHistory } from 'react-router';

import { Box, Card, CardContent, Grid } from '@mui/material';
import { Header, StyledPreSignIn } from '.'
import { PostAuthRoutes } from '../../routes';

const PreSignIn = ({ children }) => {

    let history = useHistory();

    useEffect(() => {

        if (cookieServices.get('accessToken')) {
            history.push({
                pathname: PostAuthRoutes('brands').path
            });
        }

    }, [history])

    return (
        <StyledPreSignIn justifyContent="center" alignItems="center">
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className='grid-wrapper'>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Box display="flex" alignItems="center">
                        <Card className='grid-wrapper-inner'>
                            <CardContent>
                                <Header />
                                {children}
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </StyledPreSignIn>
    )
}


export { PreSignIn }
