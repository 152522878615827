import { FilterAlt } from '@mui/icons-material';
import { Box, Grid, Stack } from '@mui/material';
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { PostAuthRoutes } from '../../../routes';
import { GeneralButton, RouteLink } from '../../../_components/controls';
import { FormSearchInput } from '../../../_components/form';
import { UsersFilter, UsersDataTable } from './users-list';
import { CardHeader } from '../../../_components/card';
import { UpdateStatusModal } from '../../../_components/modal';
import { useDispatch, useSelector } from 'react-redux';
import { UserAction } from '../../../redux/actions';
import { AbilityCan } from '../../../_helpers/permission/AbilityCan';

function Users() {

    const dispatch = useDispatch();
    let { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);

    /** Initialize and declare state */
    const changeStatus = (params) => dispatch(UserAction.changeStatus(params));
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });

    const [showUserFilterModal, setShowUserFilterModal] = useState(false);
    const [payload, setPayload] = useState({
        limit: 100, page: 1,
        filter: { status: searchParams.get('status') ?? '' },
        search: searchParams.get('q') ?? ''
    });

    /**
     * function to open/close user filter modal
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const usersFilterModal = () => {
        setShowUserFilterModal(!showUserFilterModal);
    }

    /**
     * function to apply the selected filter options on the user list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the user filter modal
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData })
    }

    /**
     * function to apply the search key words on the user list
     * @param {String} text Search keyword used
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    /**
     * function to open/close update super admin Filter model
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const statusUpdateModal = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to update super admin 
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'super_admin_id': id });
    }


    return (

        <React.Fragment>
            <Box>
                <CardHeader
                    title={PostAuthRoutes('users').name}
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`}>
                            <AbilityCan I='add_user' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <RouteLink to={PostAuthRoutes('create_user').path} label={<GeneralButton label={PostAuthRoutes('create_user').name} />} />
                            </AbilityCan>
                            <AbilityCan I='users_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                            </AbilityCan>
                            <AbilityCan I='users_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <GeneralButton label={<FilterAlt />} onClick={() => usersFilterModal()} />
                            </AbilityCan>
                        </Stack>
                    }
                />
                <UsersFilter show={showUserFilterModal} applyFilter={applyFilter} closeModal={usersFilterModal} usersFilter={payload.filter} />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <UsersDataTable statusUpdateModal={statusUpdateModal} filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} />
                    </Grid>
                </Grid>
                <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
            </Box>
        </React.Fragment>
    )
}

export { Users }