import { apiServices } from '../../_helpers';

export const RoleServices = {

    /**
     * Service to get all Roles
     * @param {Object} params - The params which are used for the api
     * @author Naveen 
     * @created_at 15 June 2022
    */
    listRole: (params) => {
        return apiServices.post('roles/list', params)
            .then((response) => { return response; })
    },

    /**
     * Service for Creating Roles
     * @param {Object} params - The params which are used for the api
     * @author  
     * @created_at 15 June 2022
     */
    createRole: (params) => {
        return apiServices.post('/roles/add', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })

    },

    /**
     * Service for update role
     * @param {Object} params - The params which are used for the api
     * @author  
     * @created_at 15 June 2022
    */
    updateRole: (params) => {
        return apiServices.post('roles/update', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })
    },

    /**
     * Service for change Role status
     * @param {Object} params - The params which are used for the api
     * @author  
     * @created_at 15 June 2022
    */
    changeStatus: (params) => {
        return apiServices.post('/roles/change-status', params)
            .then((response) => { return response; })

    },

    /**
     * Service for role select list
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
    */
    getRoleSelectList: (params) => {
        return apiServices.post('roles/list/select', params)
            .then((response) => { return response; })
    },


    /**
     * Service to assign permission
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
    */
    assignPermission: (params) => {
        return apiServices.post('roles/assign-permission', params)
            .then((response) => { return response; })
    },


    /**
     * Service to get assigned permissions
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
    */
    getAssignedPermissions: (params) => {
        return apiServices.post('roles/get-assigned-permissions', params)
            .then((response) => { return response; })
    },
}