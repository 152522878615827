import { Box, Button, Grid, Stack } from '@mui/material'
import React, { useState } from 'react'
import { CardHeader } from '../../../../../_components/card'
import { PostAuthRoutes } from '../../../../../routes'
import { Import, SampleCsv } from './actions'
import { useHistory, useLocation } from 'react-router-dom'
import { CouponsDataTable } from '.'
import { ArrowBack } from '@mui/icons-material'


function SelectCoupons() {
    let { search } = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(search);
    const brandId = searchParams.get('bId'); // plan promo from ur

    const [payload, setPayload] = useState({
        limit: 18, page: 1,
        filter: {},
        search: searchParams.get('q') ?? '',
        reset: false
    });

    const resetTable = () => {
        setPayload({...payload, page:1, reset: !(payload.reset) })
    };

    const returnToBrandDetails = () => {
        let redirectPath = `/brands/view?bId=${brandId}`;
        history.push(redirectPath);
    }

    const createCouponsOnClick = () => {
        let redirectPath = `${PostAuthRoutes('create_coupons').path}?bId=${brandId}`;
        history.push(redirectPath);
    }


    return (
        <React.Fragment>
            <Box>
                <CardHeader
                    title={PostAuthRoutes('select_coupons').name}
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`}>
                            <SampleCsv />
                            <Import resetTable={resetTable}/>
                            <Button variant="contained" color="primary" onClick={createCouponsOnClick}>
                                Create Coupons
                            </Button>
                            <Button variant="contained" color="primary" startIcon={<ArrowBack />} onClick={returnToBrandDetails}>
                                Back
                            </Button>
                        </Stack>
                    }
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CouponsDataTable filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} reset={payload.reset} brandId={brandId}/>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}

export { SelectCoupons }