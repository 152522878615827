import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CouponAction } from "../../../../../redux/actions";
import { PostAuthRoutes } from "../../../../../routes";
import queryString from 'query-string';
import { DataTable } from "../../../../../_components/data-table";
import { Chip } from "@mui/material";

function CouponsDataTable({ filterAction, filter, search, limit, page, reset, brandId, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { total, per_page, coupon_list_loading: isLoading, coupon_list } = useSelector((state) => state.CouponReducer);
    const couponsList = (params) => dispatch(CouponAction.couponsList(params));

    /** Initialize and declare state */
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [payload, setPayload] = useState({ filter, search, page, limit, reset });

    const columns = [
        { name: "No", center: true, width: '10%', cell: (row, i) => ((per_page * (payload.page - 1)) + (i + 1)) },
        { name: "Coupon Code", selector: (row, i) => (row.coupon_code), sortField: 'name', sortable: true, center: false, width: '75%' },
        { name: 'Status', selector: (row, i) => (row.status), sortable: true, center: true, width: '15%', cell: row => (<StatusRow row={row} />) },
    ];

    const StatusRow = ({ row }) => {
        let status = <Chip label={row.status} color="error" className="capitalize" />
        if (row.status === 'active')
            status = <Chip label={row.status} color="success" className="capitalize" />
    
        return <React.Fragment>{status}</React.Fragment>;

    }

    useEffect(() => {
        setPayload({ filter, search, page, limit, sort, reset });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, search, page, limit, reset])

    useEffect(() => {
        const queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        queryParam.bId = brandId;

        if (search) queryParam.q = search; else delete queryParam.q;
        // if (filter.status) queryParam.status = filter.status; else delete queryParam.status;
        history.push({
            pathname: PostAuthRoutes('select_coupons').path,
            search: `?${queryString.stringify(queryParam)}`
        });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search, payload.page, payload.reset])

    /**
    * function to fetch permission list
    * @param {Object} payload - The params which are used for the api
    * @param {string} payload.page - Page number to load
    * @param {string} payload.limit -  Count of the documents per page
    * @param {string} payload.filter - Filter data
    * @param {string} payload.search - Search key
    * @return json 
    * @created_at 14 June 2022
    */
    const getData = async () => {
        await couponsList({ ...payload, brand_id: brandId});
    }

    /**
     * function to sort columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * function to select columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSelect = (data) => {
        // console.log(data.selectedRows)
    }

    /**
     * function to handle page
     * @param {String} page - Page number to load 
     * @return view
     * @created_at 14 June 2022
     */

    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={coupon_list}
            total={total}
            per_page={per_page}
            setPage={page => handlePage(page)}
            handleSelect={handleSelect}
            handleSort={handleSort}
        />

    )
}
export { CouponsDataTable }