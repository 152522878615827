import { PricingModelServices } from "../services";
import { GlobalAction } from "./GlobalAction";

export const PRICING_MODEL_REQUEST = 'PRICING_MODEL_REQUEST';
export const PRICING_MODEL_SUCCESS = 'PRICING_MODEL_SUCCESS';
export const PRICING_MODEL_FAILURE = 'PRICING_MODEL_FAILURE';

export const PRICING_MODEL_CREATE_REQUEST = 'PRICING_MODEL_CREATE_REQUEST';
export const PRICING_MODEL_CREATE_SUCCESS = 'PRICING_MODEL_CREATE_SUCCESS';
export const PRICING_MODEL_CREATE_FAILURE = 'PRICING_MODEL_CREATE_FAILURE';

export const UPDATE_PRICING_MODEL_REQUEST = 'UPDATE_PRICING_MODEL_REQUEST';
export const UPDATE_PRICING_MODEL_SUCCESS = 'UPDATE_PRICING_MODEL_SUCCESS';
export const UPDATE_PRICING_MODEL_FAILURE = 'UPDATE_PRICING_MODEL_FAILURE';

export const GET_PRICING_MODEL_DETAILS_REQUEST = 'GET_PRICING_MODEL_DETAILS_REQUEST'
export const GET_PRICING_MODEL_DETAILS_SUCCESS = 'GET_PRICING_MODEL_DETAILS_SUCCESS'
export const GET_PRICING_MODEL_DETAILS_FAILURE = 'GET_PRICING_MODEL_DETAILS_FAILURE'

export const CHANGE_PRICING_MODEL_STATUS_REQUEST = 'CHANGE_PRICING_MODEL_STATUS_REQUEST';
export const CHANGE_PRICING_MODEL_STATUS_SUCCESS = 'CHANGE_PRICING_MODEL_STATUS_SUCCESS';
export const CHANGE_PRICING_MODEL_STATUS_FAILURE = 'CHANGE_PRICING_MODEL_STATUS_FAILURE';

export const PricingModelAction = {

    /**
     * Action list all pricing model
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 08 Aug 2023
     */
    listPricingModel: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PricingModelServices.listPricingModel(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: PRICING_MODEL_REQUEST, request: request } }
        function success(request, response) { return { type: PRICING_MODEL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PRICING_MODEL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for Creating pricing model
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 08 Aug 2023
     */
    createPricingModel: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PricingModelServices.createPricingModel(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        }

        function request(request) { return { type: PRICING_MODEL_CREATE_REQUEST, request: request } }
        function success(request, response) { return { type: PRICING_MODEL_CREATE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PRICING_MODEL_CREATE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for update pricing model
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 08 Aug 2023
    */
    updatePricingModel: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PricingModelServices.updatePricingModel(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_PRICING_MODEL_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_PRICING_MODEL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_PRICING_MODEL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    getPricingModelDetails: (params) => {
        return dispatch => {
            dispatch(request(params))
            return PricingModelServices.getPricingModelDetails(params)
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        }

        function request() { return { type: GET_PRICING_MODEL_DETAILS_REQUEST } }
        function success(response) { return { type: GET_PRICING_MODEL_DETAILS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status.code } }
        function failure(error) { return { type: GET_PRICING_MODEL_DETAILS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }

    },

    /**
     * Action for change pricing model status
     * @param {Object} params - The params which are used for the api
     * @author
     * @created_at 15 June 2022
     */
    changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PricingModelServices.changeStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_PRICING_MODEL_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_PRICING_MODEL_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_PRICING_MODEL_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    
}