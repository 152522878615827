import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { handleInputChange, validateForm, APP_NAME } from "../../../../_helpers";
import { validate } from ".";
import { PostAuthRoutes } from "../../../../routes";
import { Card, CardContent, CardHeader } from "../../../../_components/card";
import { Box, Grid, Stack } from "@mui/material";
import { FormInput } from "../../../../_components/form";
import { CancelButton, SubmitButton } from "../../../../_components/controls";
import { TrialCouponAction } from "../../../../redux/actions";


// Initialize form input values to null
const inputs = { coupon_code: '' };

function CreateTrialCouponCodes() {

    /** Initialize plugins and variables */
    let { search } = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const createTrialCoupons = (params) => dispatch(TrialCouponAction.createTrialCoupons(params));
    const { create_trial_coupon_code_loading: isLoading, create_trial_coupon_code_errors: createTrialCouponCodeErrors } = useSelector((state) => state.TrialCouponReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });


    useEffect(() => {
        setErrors({ ...createTrialCouponCodeErrors });
    }, [createTrialCouponCodeErrors])

    /**
     * function to handle input changes and modify the value
     * @author 
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }


    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author  
     * @created_at 14 June 2022 
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        const formData = {
            "coupon_code": data.coupon_code,
        }

        addCouponCode(formData);
    }

    /**
     * function to create trial coupon
     * @author  
     * @created_at 14 June 2022 
     */
    async function addCouponCode(formData) {
        const isCreated =await createTrialCoupons(formData)
        if (isCreated && isCreated.status === 1) {
            let redirectPath = PostAuthRoutes('trial_coupon_codes').path;
            history.push(redirectPath);
        }

    }

    /**
     * function to cancel create trial coupon
     * @author  
     * @created_at 14 June 2022 
     */
    function cancelTrialCoupon() {
        let redirectPath = PostAuthRoutes('trial_coupon_codes').path;
        history.push(redirectPath);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${ PostAuthRoutes('create_trial_coupon_codes').name}`}</title>
            </Helmet>
            <CardHeader title={ PostAuthRoutes('create_trial_coupon_codes').name} />
            <Box py={3} paddingLeft='350px'>
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ width: '70%' }}>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Card >
                                <CardContent >
                                    <Grid container spacing={2} justifyContent="flex-start" alignItems="center" >
                                        <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                            <Grid container spacing={3}>

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormInput
                                                        label="Trial Coupon Code"
                                                        placeholder="Enter trial coupon code"
                                                        name="coupon_code"
                                                        tabIndex={1}
                                                        value={data.coupon_code}
                                                        onChange={handleChange}
                                                        error={(action.isSubmitted && (errors.coupon_code)) ? (errors.coupon_code) : ''}
                                                        maxLength={100}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label={'Create'} loading={isLoading} tabIndex={2} />
                                                <CancelButton onClick={cancelTrialCoupon} tabIndex={3} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    )

}

export { CreateTrialCouponCodes };