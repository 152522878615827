import { CloudDownload } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react'

function SampleCsv() {

    const onDownload = () => {
        const link = document.createElement("a");
        link.download = `${process.env.PUBLIC_URL}/csv/coupon_sample_file.csv`;
        link.href = `${process.env.PUBLIC_URL}/csv/coupon_sample_file.csv`;
        link.click();
    };



    return (
        <Button variant="contained" color="primary" startIcon={<CloudDownload>send</CloudDownload>} onClick={onDownload}>
            Download sample coupons CSV file
        </Button>
    )
}

export { SampleCsv }

