import React from 'react'

import { TableLoader } from '../../_components/loader';
import { NoData } from '../layout/no-data';
import PropTypes from 'prop-types'
import { StyledDataTable } from '.';

function DataTable({ columns, data, dense, loading, total, per_page, setPage, handleSort, handleSelect, paginationDefaultPage, ...props }) {

    return (
        <StyledDataTable
            columns={columns}
            data={data}
            progressPending={loading}
            progressComponent={<TableLoader />}
            pointerOnHover={true}
            paginationTotalRows={total}
            paginationPerPage={per_page}
            paginationComponentOptions={{ noRowsPerPage: true }}
            onChangePage={page => setPage(page)}
            onSort={handleSort}
            striped
            dense={dense}
            noHeader
            highlightOnHover
            onSelectedRowsChange={handleSelect}
            pagination
            paginationServer
            noDataComponent={<NoData content1={``} content2={"Data Not Found"} description={"If using a custom view, try adjusting the filters,Otherwise, create some data."} minHeight={`75vh`} />}
            paginationDefaultPage={1}
            paginationResetDefaultPage={paginationDefaultPage}
            {...props}
        />
    )
}

DataTable.defaultProps = {
    dense: false,
}

DataTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    loading: PropTypes.bool,
    total: PropTypes.number,
    per_page: PropTypes.number,
    setPage: PropTypes.func.isRequired,
    handleSort: PropTypes.func.isRequired
}

export { DataTable };
