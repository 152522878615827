import { apiServices } from "../../_helpers";

export const InvoiceServices = {

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getInvoiceList: (params) => {
        return apiServices.post(`/invoices/list`, params)
            .then((response) => {
                return response;
            })
    },
}

