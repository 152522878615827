import { Box, Button, Grid, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Card, CardContent, CardHeader } from '../../../../../_components/card'
import { PostAuthRoutes } from '../../../../../routes'
import { useHistory, useLocation } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import { CouponAction } from '../../../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { CreateCoupons } from './create-coupons'
import { SubaccountCard } from './subaccount-card'
import { CustomCouponsDataTable } from './CustomCouponsDataTable'


function CustomCoupons() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();
    let { search } = useLocation();

    const searchParams = new URLSearchParams(search);
    const brandId = searchParams.get('bId'); // brand id from url

    /** Redux actions and state */
    // const { coupon_loading: isLoading } = useSelector((state) => state.CouponReducer);
    const getCustomCoupons = (params) => dispatch(CouponAction.getCustomCoupons(params));

    /** Initialize and declare state */
    const [data, setData] = useState({});
    const [subaccount, setSubAccount] = useState('');
    const [showLoader, setShowLoader] = useState(true);

    const [payload, setPayload] = useState({
        limit: 18, page: 1,
        filter: {},
        search: searchParams.get('q') ?? '',
        reset: false
    });

    const returnToCoupons = () => {
        let redirectPath = `${PostAuthRoutes('select_coupons').path}?bId=${brandId}`;
        history.push(redirectPath);
    }

    const createSingleUseCodes = () => {
        if (subaccount) {
            let redirectPath = `${PostAuthRoutes('create_single_use_codes').path}?bId=${brandId}`;
            history.push(redirectPath);
        }
    }
    

    useEffect(() => {
        getCustomCoupons({brand_id: brandId }).then((response) => {
            if (response.status === 1) {
                setData({
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    email: response.data.email,
                    company_name: response.data.company_name,
                    subaccount: response.data.subaccount ?? ''
                });
                setSubAccount( response.data.subaccount ?? '')
                setShowLoader(false);
            } else {
                setShowLoader(false);
            }
        });
    }, [brandId])

    
    return (
        <React.Fragment>
            <Box>
                <CardHeader
                    title={(showLoader) ? '' : !subaccount ? 'Please enter brand details for creating coupons' : PostAuthRoutes('create_coupons').name} 
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`}>
                            {((!showLoader) && subaccount ) && <Button variant="contained" color="primary" onClick={createSingleUseCodes}>Select Coupons</Button>}
                            {((!showLoader) && subaccount ) && <Button target="_blank" variant="contained" color="primary" href="https://login.coupontools.com/">Create Coupons</Button>}
                            <Button variant="contained" color="primary" onClick={returnToCoupons} startIcon={<ArrowBack />} >Back</Button>
                        </Stack>
                    }
                />
            </Box>

            {!showLoader && (
                <React.Fragment>
                    {(!subaccount) && 
                        <CreateCoupons account={data} brandId={brandId}/>
                    }
                    {(subaccount) &&
                        <React.Fragment>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <Card>
                                        <CardContent>
                                            <SubaccountCard account={data} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <CardHeader
                                            title="Coupon Links"
                                    />
                                    <Card>
                                        <CardContent>
                                            <CustomCouponsDataTable filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} reset={payload.reset} brandId={brandId}/>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>

                        </React.Fragment>
                    }
                    
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export { CustomCoupons }