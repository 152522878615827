import { apiServices } from '../../_helpers';

export const PricingModelServices = {

    /**
     * Service to get all pricing model
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 08 Aug 2023
    */
    listPricingModel: (params) => {
        return apiServices.post('pricing-model/list', params)
            .then((response) => { return response; })
    },

    /**
     * Service for Creating pricing model
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 08 Aug 2023
     */
    createPricingModel: (params) => {
        return apiServices.post('/pricing-model/add', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })

    },

    /**
     * Service for update pricing model
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 08 Aug 2023
    */
    updatePricingModel: (params) => {
        return apiServices.post('pricing-model/update', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })
    },

    /**
     * Service to get pricing model
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 08 Aug 2023
    */
    getPricingModelDetails: (params) => {
        return apiServices.post('pricing-model/details', params)
            .then((response) => { return response; })
    },

    /**
     * Service for change pricing_model status
     * @param {Object} params - The params which are used for the api
     * @author  
     * @created_at 15 June 2022
    */
    changeStatus: (params) => {
        return apiServices.post('pricing-model/change-status', params)
            .then((response) => { return response; })

    },
}