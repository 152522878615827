import React from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'

import { PostAuthMenuRoutes } from '../../../routes';
import { AbilityCan } from '../../../_helpers/permission/AbilityCan';

import { StyledSideBar } from '.'
import { Menu } from '@mui/icons-material';
import { GlobalAction } from '../../../redux/actions';

const menuRoutes = Object.values(PostAuthMenuRoutes());

function SideBar() {


    const location = useLocation();
    const dispatch = useDispatch();

    const { isDrawerOpen: open } = useSelector((state) => state.GlobalReducer);
    const { user } = useSelector((state) => state.UserReducer);

    const setDrawer = (params) => dispatch(GlobalAction.setDrawer(params));

    const toggleDrawer = () => {
        setDrawer(!open);
    };

    return (
        <StyledSideBar variant="permanent" open={open} sx={{ position: "relative", zIndex: 9 }}>
            <Box className={`${open ? `drawer-open` : ``}`}>
                <List>
                    <ListItem onClick={toggleDrawer}>
                        <ListItemIcon>
                            <Menu />
                        </ListItemIcon>
                    </ListItem>
                    {menuRoutes.map((route, index) => {

                        var splitPathName = location.pathname.split('/');
                        var parentPathName = splitPathName.slice(0, 2).join('/');

                        return (
                            <React.Fragment key={index}>
                                <AbilityCan key={index} I={route.menuPermission} passThrough={((!route.menuPermission) || (user.role_slug === 'developer')) ? true : false}>
                                    {route.hasSeparation && <Divider sx={{ margin: `5px 0` }} />}
                                    <ListItem button component={Link} to={route.path} className={(route.path === location.pathname) ? `active` : ((parentPathName === route.path) ? 'active' : '')}>
                                        <ListItemIcon>
                                            <route.menuIcon />
                                        </ListItemIcon>
                                        <ListItemText className={`menu-name ${open ? `show-menu-name` : `hide-menu-name`}`} >{route.name}</ListItemText>
                                    </ListItem>
                                </AbilityCan>
                            </React.Fragment>
                        )
                    })}
                </List>
            </Box>
        </StyledSideBar>
    )
}

export { SideBar };

