import {
    ROLE_REQUEST, ROLE_SUCCESS, ROLE_FAILURE,
    ROLE_CREATE_REQUEST, ROLE_CREATE_SUCCESS, ROLE_CREATE_FAILURE,
    CHANGE_ROLE_STATUS_REQUEST, CHANGE_ROLE_STATUS_SUCCESS, CHANGE_ROLE_STATUS_FAILURE,
    UPDATE_ROLE_REQUEST, UPDATE_ROLE_SUCCESS, UPDATE_ROLE_FAILURE,
    ROLES_SELECT_LIST_REQUEST, ROLES_SELECT_LIST_SUCCESS, ROLES_SELECT_LIST_FAILURE,
    ASSIGN_PERMISSION_REQUEST, ASSIGN_PERMISSION_SUCCESS, ASSIGN_PERMISSION_FAILURE,
    GET_ASSIGNED_PERMISSIONS_REQUEST, GET_ASSIGNED_PERMISSIONS_SUCCESS, GET_ASSIGNED_PERMISSIONS_FAILURE,
} from "../actions";

const initialState = {
    role_loading: false, role: [], role_errors: {},
    create_role_loading: false, create_role_errors: {},
    change_role_status_loading: false, change_role_status_errors: {},
    page: 1, per_page: 18, total: 0, total_pages: 0,
    roles_select_list_loading: false, roles_select_list: [], roles_select_list_errors: {},
    assign_permission_loading: false, assign_permission_errors: {},
    assigned_permissions_loading: false, assigned_permissions: {}, all_permissions: [], assigned_permissions_errors: {},
};

export function RoleReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Role list redux */
        case ROLE_REQUEST: {
            return {
                ...state,
                role_loading: true,
                role_errors: {}
            };
        }
        case ROLE_SUCCESS: {

            let newArray = [...state.role]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.role.forEach((payload) => {
                const roleIndex = newArray.findIndex(roles => roles._id === payload._id);
                if (roleIndex !== -1) {
                    newArray[roleIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                role_loading: false,
                role: newArray,
                role_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };

        }
        case ROLE_FAILURE: {
            return {
                ...state,
                role_loading: false,
                role_errors: action.errors,
                role: [],
            };
        }
        /** end::Role list redux */

        /** begin::Create admin Role redux */
        case ROLE_CREATE_REQUEST: {
            return {
                ...state,
                create_role_loading: true,
                create_role_errors: {}
            };
        }
        case ROLE_CREATE_SUCCESS: {

            let roleArray = [...state.role]; //making a new array
            const roleIndex = roleArray.findIndex(roles => roles._id === action.data._id);

            if (roleIndex !== -1) {
                roleArray[roleIndex].status = action.data.status;
            }

            return {
                ...state,
                create_role_loading: false,
                role: roleArray,
                create_role_errors: {}
            };

        }
        case ROLE_CREATE_FAILURE: {
            return {
                ...state,
                create_role_loading: false,
                create_role_errors: action.errors
            };
        }

        /** end::Create role redux */

        /** begin::Update role redux */
        case UPDATE_ROLE_REQUEST: {
            return {
                ...state,
                update_role_loading: true,
                update_role_errors: {}
            };
        }
        case UPDATE_ROLE_SUCCESS: {
            let roleArray = [...state.role];
            const roleIndex = roleArray.findIndex(role => role._id === action.data._id);
            if (roleIndex !== -1) {
                roleArray[roleIndex] = action.data;
            }

            return {
                ...state,
                role: roleArray,
                update_role_loading: false,
                update_role: action.data,
                update_role_errors: {}
            };
        }
        case UPDATE_ROLE_FAILURE: {
            return {
                ...state,
                update_role_loading: false,
                update_role_errors: action.errors
            };
        }
        /** end::Update role redux */

        /** begin::Change role status redux */
        case CHANGE_ROLE_STATUS_REQUEST: {
            return {
                ...state,
                change_role_status_loading: true,
                change_role_status_errors: {}
            };
        }
        case CHANGE_ROLE_STATUS_SUCCESS: {

            let roleArray = [...state.role]; //making a new array
            if (action.data.page === 1) {
                roleArray = []
            }

            const roleIndex = roleArray.findIndex(role => role._id === action.data._id);
            if (roleIndex !== -1) {
                roleArray[roleIndex] = action.data;
            } else {
                roleArray = roleArray.concat(action.data);
            }

            return {
                ...state,
                change_role_status_loading: false,
                role: roleArray,
                change_role_status_errors: {}
            };
        }
        case CHANGE_ROLE_STATUS_FAILURE: {
            return {
                ...state,
                change_role_status_loading: false,
                change_role_status_errors: action.errors
            };
        }
        /** end::Change role status redux */

        /** begin::Roles select list redux */
        case ROLES_SELECT_LIST_REQUEST: {
            return {
                ...state,
                roles_select_list_loading: true,
                roles_select_list_errors: {}
            };
        }
        case ROLES_SELECT_LIST_SUCCESS: {

            return {
                ...state,
                roles_select_list_loading: false,
                roles_select_list_errors: {},
                roles_select_list: action.data

            };

        }
        case ROLES_SELECT_LIST_FAILURE: {
            return {
                ...state,
                roles_select_list_loading: false,
                roles_select_list_errors: action.errors,
                roles_select_list: []
            };
        }
        /** end::Roles select list redux */

        /** begin::Assign permissions redux */
        case ASSIGN_PERMISSION_REQUEST: {
            return {
                ...state,
                assign_permission_loading: true,
                assigned_permissions_errors: {}
            };
        }
        case ASSIGN_PERMISSION_SUCCESS: {

            return {
                ...state,
                roles_select_list_loading: false,
                assigned_permissions_errors: {},

            };

        }
        case ASSIGN_PERMISSION_FAILURE: {
            return {
                ...state,
                roles_select_list_loading: false,
                assigned_permissions_errors: action.errors,
            };
        }
        /** end::Assign permissions redux */

        /** begin::Get assigned permissions redux */
        case GET_ASSIGNED_PERMISSIONS_REQUEST: {
            return {
                ...state,
                assigned_permissions_loading: true,
                assigned_permissions_errors: {}
            };
        }
        case GET_ASSIGNED_PERMISSIONS_SUCCESS: {

            return {
                ...state,
                assigned_permissions_loading: false,
                assigned_permissions: action.data.roleArray,
                all_permissions: action.data.permissionArray,
                assigned_permissions_errors: action.data.roleArray

            };

        }
        case GET_ASSIGNED_PERMISSIONS_FAILURE: {
            return {
                ...state,
                assigned_permissions_loading: false,
                assigned_permissions_errors: action.errors,
                assigned_permissions: {},
                all_permissions: []
            };
        }
        /** end::Get assigned permissions redux */
        default:
            return state;

    }
}