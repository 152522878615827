import {
    GET_BRANDS_COUNT_REQUEST, GET_BRANDS_COUNT_SUCCESS, GET_BRANDS_COUNT_FAILURE,
    GET_BRAND_LIST_REQUEST, GET_BRAND_LIST_SUCCESS, GET_BRAND_LIST_FAILURE,
    GET_BRAND_DETAILS_REQUEST, GET_BRAND_DETAILS_SUCCESS, GET_BRAND_DETAILS_FAILURE,
    GET_DEAL_DETAILS_REQUEST, GET_DEAL_DETAILS_SUCCESS, GET_DEAL_DETAILS_FAILURE,
    GET_BRANDS_COUPON_COUNT_REQUEST, GET_BRANDS_COUPON_COUNT_SUCCESS, GET_BRANDS_COUPON_COUNT_FAILURE,
    GET_BRANDS_DAILY_COUPONS_REQUEST, GET_BRANDS_DAILY_COUPONS_SUCCESS, GET_BRANDS_DAILY_COUPONS_FAILURE,
    BRAND_SELECT_LIST_REQUEST, BRAND_SELECT_LIST_SUCCESS, BRAND_SELECT_LIST_FAILURE
} from '../actions'

const initialState = {
    brands_count_loading: true, brands_count: 0, brands_count_errors: {},
    page: 1, per_page: 18, total: 0, total_pages: 0, brands_loading: false, brands: [], brands_errors: {},
    brand_loading: false, brand: {}, brand_errors: {},
    deal_loading: false, deal: {}, deal_errors: {},
    brands_coupon_counts_loading: false, brands_coupon_counts: { total_coupons: 0, balance_coupons: 0 }, brands_coupon_counts_errors: {},
    daily_coupons_issued_loading: false, daily_coupons_issued: {}, daily_coupons_issued_errors: {},
    brand_select_list_loading: true, brand_select_list: [], brand_select_list_errors: {}
}

export function BrandReducer(state = initialState, action) {
    switch (action.type) {

        //  begin::Get busineee list redux 
        case GET_BRANDS_COUNT_REQUEST: {
            return {
                ...state,
                brands_count_loading: true,
                brands_count_errors: {}
            };
        }
        case GET_BRANDS_COUNT_SUCCESS: {

            return {
                ...state,
                brands_count_loading: false,
                brands_count: action.data,
                brands_count_errors: {},
            };
        }

        case GET_BRANDS_COUNT_FAILURE: {
            return {
                ...state,
                brands_count_loading: false,
                brands_count: 0,
                brands_count_errors: action.errors
            };
        }
        // begin::Get busineee details redux 

        //  begin::Get busineee list redux 
        case GET_BRAND_LIST_REQUEST: {
            return {
                ...state,
                brands_loading: true,
                brands_errors: {}
            };
        }
        case GET_BRAND_LIST_SUCCESS: {
            let newArray = [...state.brands]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.brands.forEach((payload) => {
                const planIndex = newArray.findIndex(brands => brands._id === payload._id);
                if (planIndex !== -1) {
                    newArray[planIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                brands_loading: false,
                brands: newArray,
                brands_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };
        }

        case GET_BRAND_LIST_FAILURE: {
            return {
                ...state,
                brands_loading: false,
                brands_errors: action.errors,
            };
        }
        // begin::Get busineee details redux 

        //  begin::Get busineee list redux 
        case GET_BRAND_DETAILS_REQUEST: {
            return {
                ...state,
                brand_loading: true,
                brand: {},
                brand_errors: {}

            }
        }
        case GET_BRAND_DETAILS_SUCCESS: {
            return {
                ...state,
                brand_loading: false,
                brand: action.data,
                brand_errors: {}
            }
        }

        case GET_BRAND_DETAILS_FAILURE: {
            return {
                ...state,
                brand_loading: false,
                brand: {},
                brand_errors: action.errors
            }
        }
        // begin::Get busineee details redux 

        //  begin::Get busineee list redux 
        case GET_DEAL_DETAILS_REQUEST: {
            return {
                ...state,
                deal_loading: true,
                deal: {},
                deal_errors: {}

            }
        }
        case GET_DEAL_DETAILS_SUCCESS: {
            return {
                ...state,
                deal_loading: false,
                deal: action.data,
                deal_errors: {}
            }
        }

        case GET_DEAL_DETAILS_FAILURE: {
            return {
                ...state,
                deal_loading: false,
                deal: {},
                deal_errors: action.errors
            }
        }
        // begin::Get busineee details redux 

        //  begin::Get daily coupons redux 
        case GET_BRANDS_COUPON_COUNT_REQUEST: {
            return {
                ...state,
                brands_coupon_counts_loading: true,
                brands_coupon_counts: {},
                brands_coupon_counts_errors: {}
            }
        }
        case GET_BRANDS_COUPON_COUNT_SUCCESS: {
            return {
                ...state,
                brands_coupon_counts_loading: false,
                brands_coupon_counts: action.data,
            }
        }

        case GET_BRANDS_COUPON_COUNT_FAILURE: {
            return {
                ...state,
                brands_coupon_counts_loading: false,
                brands_coupon_counts: { total_coupons: 0, balance_coupons: 0 },
                brands_coupon_counts_errors: action.errors
            }
        }
        // begin::Get daily coupons redux 

        //  begin::Get daily coupons redux 
        case GET_BRANDS_DAILY_COUPONS_REQUEST: {
            return {
                ...state,
                daily_coupons_issued_loading: true,

            }
        }
        case GET_BRANDS_DAILY_COUPONS_SUCCESS: {
            return {
                ...state,
                daily_coupons_issued_loading: false,
                daily_coupons_issued: action.data
            }
        }

        case GET_BRANDS_DAILY_COUPONS_FAILURE: {
            return {
                ...state,
                daily_coupons_issued_loading: false,
                daily_coupons_issued_errors: action.errors
            }
        }
        // begin::Get daily coupons redux 



        //  begin::Get brand list select redux 
        case BRAND_SELECT_LIST_REQUEST: {
            return {
                ...state,
                brand_select_list_loading: true
            }
        }
        case BRAND_SELECT_LIST_SUCCESS: {
            return {
                ...state,
                brand_select_list_loading: false,
                brand_select_list: action.data,
            }
        }
        case BRAND_SELECT_LIST_FAILURE: {
            return {
                ...state,
                brand_select_list_loading: false,
                brand_select_list_errors: action.errors,
            }
        }
        // begin::Get brand list select redux 
        default:
            return state
    }

}
