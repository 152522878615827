import React from 'react'
import { Drawer, styled } from '@mui/material';
import { DRAWER_WIDTH } from '../../../_helpers';

const StyledSideBar = styled(({ ...props }) => (<Drawer {...props} />))`
        z-index:1100 !important;
        & .MuiPaper-root {
            border: none;
            background-color: #1242DE;
            ${'' /* background-color: ${props => ((props.theme.palette.mode === 'light') ? (props.theme.palette.grey[100]) : (props.theme.palette.grey[900]))}; */}
            &:hover {
                & .menu-name {
                    display: block;
                }
                & .MuiBox-root {
                    height: 100%;
                }
                & .MuiList-root {
                    ${'' /* background-color: ${props => ((props.theme.palette.mode === 'light') ? (props.theme.palette.grey[100]) : (props.theme.palette.grey[900]))}; */}
                    width: ${DRAWER_WIDTH}px;
                    height: 100%;
                }
         }
        }
        & .MuiListItemIcon-root {
            min-width: 25px;
            padding: ${props => props.theme.spacing(2)};
            font-size: 20px;
            color: #fff;
        }
        & .MuiListItemText-root {
            margin-top: 0;
            margin-bottom: 0;
            color: #fff;
        }
        & .MuiTypography-root {
            font-size: 14px;
            padding-left: 10px;
            padding-right: 15px;
        }
        & .show-menu-name {
            display: block;
        }
        & .hide-menu-name {
            display: none;
        }
        & .drawer-open{
            background-color: #1242DE;
            ${'' /* background-color: ${props => ((props.theme.palette.mode === 'light') ? (props.theme.palette.grey[100]) : (props.theme.palette.grey[900]))}; */}
            width: ${DRAWER_WIDTH}px;
            height: 100%;
        }
        & .MuiBox-root {
            background-color: #1242DE;
            ${'' /* padding-top: ${props => props.theme.spacing(7)}; */}
        },
        & .MuiListItemIcon-root {
            font-size: 22px;
        }

`;

export { StyledSideBar }


