import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
// import { makeStyles } from '@material-ui/styles';


// const useStyles = makeStyles((theme) => ({
//     'root': {
//         marginLeft: theme.spacing(1)
//     }
// }));

function FormSaveBtn({ text, className, tabIndex, isProcessing, ...props }) {

    // const classes = useStyles();

    return (
        <Button type="submit" variant="contained" color={"primary"} tabIndex={tabIndex} disabled={isProcessing} loadingPosition="start" {...props}>
            {text}
        </Button>
    )
}

FormSaveBtn.defaultProps = {
    className: "",
    text: "Save"
}


FormSaveBtn.propTypes = {
    className: PropTypes.string,
    tabIndex: PropTypes.string,
    text: PropTypes.string.isRequired,
    isProcessing: PropTypes.bool,
}

export { FormSaveBtn }
