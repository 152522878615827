import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { handleInputChange, validateForm } from '../../../../_helpers';
import validate from './validate';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { FormBrowseFile, FormCancelBtn, FormCheckbox, FormColorInput, FormDateTimePicker, FormRichTextEditor, FormSaveBtn, FormSelect, FormSwitch, FormTextArea } from '../../../../_components/form';
import { CardHeader } from '../../../../_components/card';
import { ArrowBack } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { DealAction } from '../../../../redux/actions';
import moment from 'moment'
import { useHistory } from 'react-router';

const timeBetweenPostOptions = [
    { value: 'One Time', label: 'One Time' },
    { value: '1 Day', label: 'Once a day' },
    { value: '1 Week', label: 'Once a week' },
    { value: '1 Month', label: 'Once a month' }
]


const inputs = {
    deal_id: null,
    insta_handle: '',
    // logo: '',
    // primary_color: { r: 18, g: 66, b: 222, a: 1 },
    // secondary_color: { r: 255, g: 255, b: 255, a: 1 },
    // link_color: { r: 0, g: 0, b: 0, a: 1 },
    // font: '',
    // reward: '',
    // reward_description: 'Tag @yourbrand in an Instagram story for *Insert Coupon Amount*',
    // headline: '',
    // offer_description: 'Create your own content or repost ours. Account must be public at the time your story is posted.<br/><br/> *Add any additional terms of your Coupon*',
    // sample_story: '',
    // hashtag: '',
    // giveaway_date: '',
    // coupon_redemption_url: '',
    // is_giveaway: false,
    // story: false,
    // like: false,
    // email_or_text: false,
    // can_send_followup_msg: false,
    // brand_id: ''
    direct_message: '',
    times_between_post: '',

}


function BrandDealUpdate() {

    /** Initialize plugins and variables */
    let { search } = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const params = new URLSearchParams(search);
    const brandId = params.get('bId'); // plan promo from url

    /** Redux actions and state */
    const getBrandDealDetails = (params) => dispatch(DealAction.getBrandDealDetails(params));
    const editDeals = (dealId, params) => dispatch(DealAction.editDeals(dealId, params));
    const addDeals = (params) => dispatch(DealAction.addDeals(params));

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, reward_description: '', offer_description: '', direct_message: '' });
    const [showLoader, setShowLoader] = useState(true);
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [isGiveAway, setIsGiveAway] = useState(false);

    /** Initialize and declare state */

    useEffect(() => {

        getBrandDealDetails({ brand_id: brandId }).then(result => {

            let deal = result.data;

            if (deal && deal._id ) {
                setData({
                    deal_id: deal._id,
                    insta_handle: deal.insta_handle,
                    // logo: deal.logo,
                    // primary_color: ((deal.primary_color) ? (deal.primary_color) : (inputs.primary_color)),
                    // secondary_color: ((deal.secondary_color) ? (deal.secondary_color) : (inputs.secondary_color)),
                    // link_color: ((deal.link_color) ? (deal.link_color) : (inputs.link_color)),
                    // reward_description: deal.reward_description,
                    // offer_description: deal.offer_description,
                    // sample_story: deal.sample_story,
                    // giveaway_date: ((deal.giveaway_date) ? moment.utc(deal.giveaway_date).toDate() : (inputs.giveaway_date)),
                    // is_giveaway: deal.is_giveaway ?? false,
                    // story: deal.story ?? false,
                    // like: deal.like ?? false,
                    // email_or_text: deal.email_or_text ?? false,
                    // can_send_followup_msg: deal.can_send_followup_msg ?? false,
                    times_between_post: deal.times_between_post,
                    direct_message: deal.direct_message ? deal.direct_message : '',
                    brand_id: brandId
                });
                // setIsGiveAway(deal.is_giveaway ?? false);
            } else {
                setData({ ...inputs });
            }                
            setShowLoader(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandId])

    const handleChange = async (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(await validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
        // checkRequired(name, value);
    }

    const checkRequired = async (name, value) => {

        if (name === "is_giveaway") {
            if (value === true) {
                setIsGiveAway(true)
                data['email_or_text'] = true;
            } else {
                setIsGiveAway(false)
                data['giveaway_date'] = '';
            }
        } else if (name === "email_or_text") {
            if (isGiveAway === true) {
                data['email_or_text'] = true;
            }
        }
        setData({ ...data });
        setErrors({ ...errors, ...(await validate(name, data[name], data)) });
        return;
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = await validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }


        formData.append("id", data.deal_id);
        formData.append("insta_handle", data.insta_handle);
        // formData.append('logo', data.logo);
        // formData.append("primary_color", JSON.stringify(data.primary_color));
        // formData.append("secondary_color", JSON.stringify(data.secondary_color));
        // formData.append("link_color", JSON.stringify(data.link_color));
        // formData.append("reward_description", data.reward_description);
        // formData.append("offer_description", data.offer_description);
        // formData.append('sample_story', data.sample_story);
        // formData.append('giveaway_date', data.giveaway_date);
        // formData.append('is_giveaway', data.is_giveaway);
        // formData.append('story', data.story);
        // formData.append('like', data.like);
        // formData.append('email_or_text', data.email_or_text);
        // formData.append('can_send_followup_msg', data.can_send_followup_msg);
        formData.append('times_between_post', data.times_between_post);
        formData.append('direct_message', data.direct_message);
        formData.append('brand_id', brandId);
        dispatchDealAction(formData);
    }

    const dispatchDealAction = (formData) => {
        let dispatchAction = (data.deal_id) ? editDeals(data.deal_id, formData) : addDeals(formData);
        dispatchAction.then((response) => {
            if (response.status === 1) {
                setAction({ ...action, isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
                let redirectPath = `/brands/view?bId=${brandId}`;
                history.push(redirectPath);
            } else {
                setErrors({ ...response.errors })
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }
        });
    }

    const returnToDeals = () => {
        let redirectPath = `/brands/view?bId=${brandId}`;
        history.push(redirectPath);
    }

    if (showLoader) {
        return 'Loading';
    }

    return (
        <React.Fragment>
            <CardHeader title={`${(data && data.deal_id) ? 'Update' : 'Create'} Offer`} action={<Button variant="contained" color="primary" onClick={returnToDeals} startIcon={<ArrowBack />} >Back</Button>} />
            <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
                    <Card>
                        <CardContent style={{ paddingTop: `24px` }}>
                            <form onSubmit={handleSubmit} noValidate >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormTextArea
                                            label="Add a direct message to accompany the coupon."
                                            name="direct_message"
                                            error={(action.isSubmitted && (errors.direct_message)) ? (errors.direct_message) : ''}
                                            placeholder="Enter direct message"
                                            value={data.direct_message}
                                            onChange={handleChange}
                                            multiline={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography>When someone mentions the brand in an Instagram story, they will automatically receive a coupon</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormSelect
                                            label={`How Often`}
                                            name={`times_between_post`}
                                            placeholder={`Select time between post`}
                                            onChange={handleChange}
                                            data={timeBetweenPostOptions}
                                            value={data.times_between_post}
                                            error={(action.isSubmitted && (errors.times_between_post)) ? (errors.times_between_post) : ''}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <FormBrowseFile
                                            label="Choose Logo"
                                            name="logo"
                                            error={(action.isSubmitted && (errors.logo)) ? (errors.logo) : ''}
                                            placeholder="Choose your logo"
                                            value={data.logo}
                                            onChange={handleChange}
                                            // acceptType=".png,.jpg,.jpeg,.svg,.gif,.apng,.bmp,.ico"
                                            acceptType={{ 'image/*': ['.png', '.jpg', '.jpeg', '.svg', '.gif', '.apng', '.bmp', '.ico'] }}
                                            info="Allowed Format: png,jpg,jpeg,svg,gif,apng,bmp,ico , Allowed Maximum Size: 2 MB"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                        <FormColorInput
                                            label="Background Color"
                                            name="primary_color"
                                            value={data.primary_color}
                                            onChange={handleChange}
                                        />
                                        <FormColorInput
                                            label="Text Color"
                                            name="secondary_color"
                                            value={data.secondary_color}
                                            onChange={handleChange}
                                        />
                                        <FormColorInput
                                            label="Button Color"
                                            name="link_color"
                                            value={data.link_color}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <FormSwitch
                                            id="test"
                                            label="Giveaway Countdown"
                                            name="is_giveaway"
                                            error={(action.isSubmitted && (errors.is_giveaway)) ? (errors.is_giveaway) : ''}
                                            value={data.is_giveaway}
                                            onChange={handleChange}
                                            className={`mb-0 mt-3`}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <FormDateTimePicker
                                            label={`End Date`}
                                            name="giveaway_date"
                                            error={(action.isSubmitted && (errors.giveaway_date)) ? (errors.giveaway_date) : ''}
                                            placeholder="Enter end date"
                                            value={data.giveaway_date}
                                            onChange={handleChange}
                                            isReadOnly={!isGiveAway}
                                        />
                                    </Grid> */}
                                    
                                    {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <FormRichTextEditor
                                            label="Description"
                                            name="reward_description"
                                            error={(action.isSubmitted && (errors.reward_description)) ? (errors.reward_description) : ''}
                                            placeholder="Enter description"
                                            value={data.reward_description}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <FormRichTextEditor
                                            label="Fine Print"
                                            name="offer_description"
                                            error={(action.isSubmitted && (errors.offer_description)) ? (errors.offer_description) : ''}
                                            placeholder="Enter fine print"
                                            value={`${data.offer_description}`}
                                            onChange={handleChange}
                                        />
                                    </Grid> */}
                                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormCheckbox
                                            label="Send follow up message to user when they can post again"
                                            name="can_send_followup_msg"
                                            error={(action.isSubmitted && (errors.can_send_followup_msg)) ? (errors.can_send_followup_msg) : ''}
                                            onChange={handleChange}
                                            value={data.can_send_followup_msg}
                                            checked={data.can_send_followup_msg}
                                            className='p-2'
                                        />
                                    </Grid> */}
                                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormBrowseFile
                                            label="Sample Story (Optional)"
                                            name="sample_story"
                                            error={(action.isSubmitted && (errors.sample_story)) ? (errors.sample_story) : ''}
                                            placeholder="Choose your sample story"
                                            value={data.sample_story}
                                            onChange={handleChange}
                                            acceptType={{ 'image/*': ['.png', '.jpg', '.jpeg', '.svg', '.gif', '.apng', '.bmp', '.ico'] }}
                                            // acceptType=".png,.jpg,.jpeg,.svg,.gif,.apng,.bmp,.ico"
                                            info="Allowed Format: png,jpg,jpeg,svg,gif,apng,bmp,ico , Allowed Maximum Size: 2 MB , Recommended Aspect Ratio: 1080x1920"
                                            isDisabled={(data.like && (!data.story) ? true : false)}
                                        />
                                    </Grid> */}
                                    <Grid item >
                                        <FormSaveBtn isProcessing={action.isProcessing} text={`${data && data.deal_id ? 'Update' : 'create'} Offer`} style={{ marginRight: `5px` }} />
                                        <FormCancelBtn text='Cancel' onClick={returnToDeals} />
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export { BrandDealUpdate }