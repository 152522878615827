import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandAction } from '../../../redux/actions';
import { PostAuthRoutes } from "../../../routes";
import { ButtonGroup, Box, Tooltip, IconButton } from '@mui/material';
import { DataTable } from '../../../_components/data-table/DataTable';
import { useHistory } from "react-router-dom";
import { CancelRounded, CheckCircleRounded, Visibility } from "@mui/icons-material";
import queryString from 'query-string';
import { AbilityCan } from "../../../_helpers/permission/AbilityCan";


function BrandsDataTable({ filterAction, filter, search, limit, page, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { total, per_page, brands_loading: isLoading, brands } = useSelector((state) => state.BrandReducer);
    const getBrandList = (params) => dispatch(BrandAction.getBrandList(params));

    /** Initialize and declare state */
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [payload, setPayload] = useState({ filter, search, page, limit });

    const columns = [
        { name: "No", center: true, width: '5%', cell: (row, i) => ((per_page * (page - 1)) + (i + 1)) },
        { name: "Name", selector: (row, i) => (row.name), sortField: 'name', sortable: true, center: false, width: '14%' },
        { name: "Email", selector: (row, i) => (row.email), sortField: 'description', sortable: true, center: false, width: '15%' },
        { name: "Company", selector: (row, i) => (row.company_name), sortField: 'subject', sortable: true, center: false, width: '15%' },
        { name: "Deal Available", selector: (row, i) => (row.deal_available), sortField: 'deal_available', sortable: 'true', center: true, width: '10%', cell: row => (<DealAvailableRow row={row} />) },
        { name: "Offer Instagram handle", selector: (row, i) => (row.deal_insta_handle_with_prefix), sortField: 'deal_insta_handle', sortable: true, center: false, width: '14%', cell: row => (<OfferInstagramHandleRow row={row} />) },
        { name: "Subdomain", selector: (row, i) => (row.brand_sub_domain_url), sortField: 'brand_sub_domain', sortable: true, center: false, width: '16%', cell: row => (<SubdomainRow row={row} />) },
        { name: "Created At", selector: (row, i) => (row.created_at), sortField: 'created_at', sortable: true, center: true, width: '11%', cell: row => (<CreatedAtRow row={row} />) },
    ];

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author 
     * @created_at 14 June 2022 
     */
    const DealAvailableRow = ({ row }) => {
        let deal_available = <CancelRounded color={'error'} />
        if (row.deal_available)
            deal_available = <CheckCircleRounded color={'success'} />
        return <React.Fragment>{deal_available} </React.Fragment>
    }
    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author 
     * @created_at 14 June 2022 
     */
    const SubdomainRow = ({ row }) => {
        return <a href={row.brand_sub_domain_url} target="_blank" rel="noreferrer">{row.brand_sub_domain_url}</a>
    }
    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author 
     * @created_at 14 June 2022 
     */
    const OfferInstagramHandleRow = ({ row }) => {
        return <a href={row.deal_insta_handle_url} target="_blank" rel="noreferrer">{row.deal_insta_handle_with_prefix}</a>
    }

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author 
     * @created_at 14 June 2022 
     */
    const CreatedAtRow = ({ row }) => {
        function handleDetailsClick(row) {
            let redirectPath = `${PostAuthRoutes('brand_details').path}?bId=${row._id}`;
            history.push(redirectPath);
        }
        let createdAt = <Box className="badge-status">{row.created_at}</Box>;
        let statusChange = (
            <ButtonGroup variant="text" size="small" className='row-action'>
                <AbilityCan I='brands_detail' passThrough={(user.role_slug === 'developer') ? true : false}>
                    <Tooltip title="View Details">
                        <IconButton onClick={() => handleDetailsClick(row)}>
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                </AbilityCan>
            </ButtonGroup>
        )
        return <React.Fragment>{createdAt}{statusChange} </React.Fragment>
    }

    useEffect(() => {
        setPayload({ filter, search, page, limit, sort });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, search, page, limit])

    useEffect(() => {

        const queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q;
        // if (filter.status) queryParam.status = filter.status; else delete queryParam.status;
        history.push({
            pathname: PostAuthRoutes('brands').path,
            search: `?${queryString.stringify(queryParam)}`
        });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

    /**
    * function to fetch permission list
    * @param {Object} payload - The params which are used for the api
    * @param {string} payload.page - Page number to load
    * @param {string} payload.limit -  Count of the documents per page
    * @param {string} payload.filter - Filter data
    * @param {string} payload.search - Search key
    * @return json 
    * @created_at 14 June 2022
    */
    const getData = async () => {
        await getBrandList(payload);
    }

    /**
     * function to sort columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * function to select columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSelect = (data) => {
        // console.log(data.selectedRows)
    }

    /**
     * function to handle page
     * @param {String} page - Page number to load 
     * @return view
     * @created_at 14 June 2022
     */

    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={brands}
            total={total}
            per_page={per_page}
            setPage={page => handlePage(page)}
            handleSelect={handleSelect}
            handleSort={handleSort}
        />

    )
}
export { BrandsDataTable }