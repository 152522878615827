import React, { useEffect, useState } from 'react'
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { Chip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { PostAuthRoutes } from '../../../../routes';
import { DataTable } from '../../../../_components/data-table';
import { TrialCouponAction } from '../../../../redux/actions';

function TrialCouponCodesDataTable({ filterAction, filter, search, limit, page, reset, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { per_page, total, trial_coupon_list_loading: isLoading, trial_coupon_list } = useSelector((state) => state.TrialCouponReducer);
    const trialCouponsList = (params) => dispatch(TrialCouponAction.trialCouponsList(params));

    /** Initialize and declare state */
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [payload, setPayload] = useState({ filter, search, page, limit, reset });

    const columns = [
        { name: '#', selector: (row, i) => (row._id), sortField: '_id', center: true, width: '10%', cell: (row, i) => ((per_page * (payload.page - 1)) + (i + 1)) },
        { name: 'Coupon Code', selector: (row, i) => (row.coupon_code), sortField: 'name', sortable: true, width: '70%' },
        { name: 'Status', selector: (row, i) => (row.status), sortField: 'status', center: true, sortable: true, width: '20%', cell: row => (<StatusRow row={row} />) },
    ];

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author 
     * @created_at 14 June 2022 
     */
    const StatusRow = ({ row }) => {

        const [statusData] = useState({ id: row._id, status: row.status });


        let status = <Chip label={row.status} className="badge-status capitalize" />;
        if (row.status === 'active')
            status = <Chip label={row.status[0].toUpperCase() + row.status.substring(1)} color="success" className="badge-status capitalize" />;
        else if (row.status === 'inactive')
            status = <Chip label={row.status[0].toUpperCase() + row.status.substring(1)} color="error" className="badge-status capitalize" />;


        return <React.Fragment>{status}</React.Fragment>;

    }

    useEffect(() => {
        setPayload({ filter, search, page, limit, sort, reset });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, search, page, limit, reset])

    useEffect(() => {

        const queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q;
        if (filter.status) queryParam.status = filter.status; else delete queryParam.status;
        history.push({
            pathname: PostAuthRoutes('trial_coupon_codes').path,
            search: `?${queryString.stringify(queryParam)}`
        });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search, payload.page, payload.reset])

    /**
     * function to fetch pricing model list
     * @param {Object} payload - The params which are used for the api
     * @param {string} payload.page - Page number to load
     * @param {string} payload.limit -  Count of the documents per page
     * @param {string} payload.filter - Filter data
     * @param {string} payload.search - Search key
     * @return json 
     * @author 
     * @created_at 14 June 2022
     */
    const getData = async () => {
        await trialCouponsList(payload);
    }

    /**
     * function to sort columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * function to handle page
     * @param {String} page - Page number to load 
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={trial_coupon_list}
            total={total}
            per_page={per_page}
            setPage={page => handlePage(page)}
            handleSort={handleSort}
        />
    )

}

export { TrialCouponCodesDataTable };