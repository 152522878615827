import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Helmet } from "react-helmet-async";

import { UserAction } from '../../../redux/actions';

import { AppBar, Toolbar } from '@mui/material';
import { Box } from '@mui/system';

import { getPostComponentNameByPath } from '../../../routes';
import { AccountMenu } from '.'
import { useContext } from 'react';
import { AppNameContext } from '../../../_contexts';


function Header() {

    const dispatch = useDispatch();
    const location = useLocation();
    const appName = useContext(AppNameContext);

    const [pageTitle, setPageTitle] = useState('Loading')

    useEffect(() => {
        setPageTitle(getPostComponentNameByPath(location.pathname));
    }, [location])

    useEffect(() => {
        dispatch(UserAction.getUserDetails())
        dispatch(UserAction.getUserPermission())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <React.Fragment>
            <Helmet>
                <title>{`${appName} | ${pageTitle}`}</title>
            </Helmet>
            <AppBar color="default" elevation={0} position="sticky" >
                <Toolbar>
                    {/* <ShortLogo width="40" height="40" style={{ marginLeft: '10px' }} /> */}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <AccountMenu />
                    </Box>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    )
}

export { Header };

