import { TrialCouponServices } from "../services"
import { GlobalAction } from "./GlobalAction";


export const IMPORT_TRIAL_COUPONS_REQUEST = 'IMPORT_TRIAL_COUPONS_REQUEST';
export const IMPORT_TRIAL_COUPONS_SUCCESS = 'IMPORT_TRIAL_COUPONS_SUCCESS';
export const IMPORT_TRIAL_COUPONS_FAILURE = 'IMPORT_TRIAL_COUPONS_FAILURE';

export const GET_TRIAL_COUPONS_REQUEST = 'GET_TRIAL_COUPONS_REQUEST';
export const GET_TRIAL_COUPONS_SUCCESS = 'GET_TRIAL_COUPONS_SUCCESS';
export const GET_TRIAL_COUPONS_FAILURE = 'GET_TRIAL_COUPONS_FAILURE';

export const CREATE_TRIAL_COUPONS_REQUEST = 'CREATE_TRIAL_COUPONS_REQUEST';
export const CREATE_TRIAL_COUPONS_SUCCESS = 'CREATE_TRIAL_COUPONS_SUCCESS';
export const CREATE_TRIAL_COUPONS_FAILURE = 'CREATE_TRIAL_COUPONS_FAILURE';

export const TrialCouponAction = {

    importTrialCoupons: (params) => {
        return dispatch => {
            dispatch(request(params));
            return TrialCouponServices.importTrialCoupons(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request(request) { return { type: IMPORT_TRIAL_COUPONS_REQUEST, request: request } }
        function success(request, response) { return { type: IMPORT_TRIAL_COUPONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: IMPORT_TRIAL_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    trialCouponsList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return TrialCouponServices.trialCouponsList(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request(request) { return { type: GET_TRIAL_COUPONS_REQUEST, request: request } }
        function success(request, response) { return { type: GET_TRIAL_COUPONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: GET_TRIAL_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    
    createTrialCoupons: (params) => {
        return dispatch => {
            dispatch(request(params));
            return TrialCouponServices.createTrialCoupons(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request(request) { return { type: CREATE_TRIAL_COUPONS_REQUEST, request: request } }
        function success(request, response) { return { type: CREATE_TRIAL_COUPONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: CREATE_TRIAL_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

}