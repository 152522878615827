import React, { useState } from 'react'
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, Box, Stack } from '@mui/material';

import { FormSearchInput } from '../../_components/form';
import { PostAuthRoutes } from '../../routes'
import { CardHeader } from '../../_components/card';
import { ShoppersDataTable, ShoppersFilter } from '.';
import { GeneralButton } from '../../_components/controls';
import { FilterAlt } from '@mui/icons-material';
import { AbilityCan } from '../../_helpers/permission/AbilityCan';

function Shoppers() {

    let { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);

    const [showShopperFilterModal, setShowShopperFilterModal] = useState(false);
    const [payload, setPayload] = useState({
        limit: 100, page: 1,
        filter: { brand_id: searchParams.get('brand_id') ?? '' },
        search: searchParams.get('q') ?? ''
    });

    /**
     * function to open/close shopper filter modal
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const shoppersFilterModal = () => {
        setShowShopperFilterModal(!showShopperFilterModal);
    }

    /**
     * function to apply the selected filter options on the shopper list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the shopper filter modal
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData })
    }

    /**
     * function to apply the search key words on the shopper list
     * @param {String} text Search keyword used
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (

        <React.Fragment>
            <Box>
                <CardHeader
                    title={PostAuthRoutes('shoppers').name}
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`}>
                            <AbilityCan I='shoppers_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                            </AbilityCan>
                            <AbilityCan I='shoppers_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                                <GeneralButton label={<FilterAlt />} onClick={() => shoppersFilterModal()} />
                            </AbilityCan>
                        </Stack>
                    }
                />
                <ShoppersFilter show={showShopperFilterModal} applyFilter={applyFilter} closeModal={shoppersFilterModal} shoppersFilter={payload.filter} />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ShoppersDataTable filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} />
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}

export { Shoppers };