import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserAction } from '../../../../redux/actions';
import { PostAuthRoutes } from "../../../../routes";
import { ButtonGroup, Chip, Tooltip, IconButton } from '@mui/material';
import { DataTable } from '../../../../_components/data-table/DataTable';
import { useHistory } from "react-router-dom";
import { ChangeCircle, Edit } from "@mui/icons-material";
import queryString from 'query-string';
import { AbilityCan } from "../../../../_helpers/permission/AbilityCan";


function UsersDataTable({ statusUpdateModal, filterAction, filter, search, limit, page, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { total, per_page, users_loading: isLoading, users } = useSelector((state) => state.UserReducer);
    const getUserList = (params) => dispatch(UserAction.getUserList(params));

    /** Initialize and declare state */
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [payload, setPayload] = useState({ filter, search, page, limit });

    const columns = [
        { name: "No", center: true, width: '5%', cell: (row, i) => ((per_page * (page - 1)) + (i + 1)) },
        { name: "Name", selector: (row, i) => (row.name), sortField: 'name', sortable: true, center: false, width: '30%' },
        { name: "Email", selector: (row, i) => (row.email), sortField: 'email', sortable: true, center: false, width: '30%' },
        { name: "Role", selector: (row, i) => (row.role), sortField: 'role_id', sortable: true, center: false, width: '15%' },
        { name: "Created At", selector: (row, i) => (row.created_at), sortField: 'created_at', sortable: true, center: true, width: '10%' },
        { name: "Status", selector: (row, i) => (row.status), sortField: 'status', sortable: true, center: true, width: '10%', cell: row => (<StatusRow row={row} />) },
    ];

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author 
     * @created_at 14 June 2022 
     */
    const StatusRow = ({ row }) => {

        /**
         * function to update data of the row
         * @param {object} row
         * @return json
         * @author 
         * @created_at 14 June 2022
         */
        function handleUpdateClick(row) {
            history.push({
                pathname: PostAuthRoutes('update_user').path,
                state: { user: row }
            });
        }

        /**
         * function to change status of data on the row
         * @param {object} row
         * @param {object} row._id - id of the row
         * @param {object} row.status - status of the row
         * @return json
         * @author 
         * @created_at 14 June 2022
         */
        function handleChangeStatusClick(row) {
            statusUpdateModal({ id: row._id, status: row.status, item: '' });
        }

        let status = <Chip label={row.status} color="error" className="badge-status capitalize" />
        let updateButtonText = 'Activate'
        if (row.status === 'active') {
            updateButtonText = 'Inactivate'
            status = <Chip label={row.status} color="success" className="badge-status capitalize" />
        }

        let statusChange = (
            <ButtonGroup variant="text" size="small" className='row-action'>
                <AbilityCan I='update_user' passThrough={(user.role_slug === 'developer') ? true : false}>
                    <Tooltip title="Update User">
                        <IconButton onClick={() => handleUpdateClick(row)}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </AbilityCan>
                <AbilityCan I='users_change_status' passThrough={(user.role_slug === 'developer') ? true : false}>
                    <Tooltip title={updateButtonText}>
                        <IconButton onClick={() => handleChangeStatusClick(row)}>
                            <ChangeCircle />
                        </IconButton>
                    </Tooltip>
                </AbilityCan>
            </ButtonGroup>
        )
        return <React.Fragment>{status}{statusChange} </React.Fragment>
    }

    useEffect(() => {
        setPayload({ filter, search, page, limit, sort });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, search, page, limit])

    useEffect(() => {

        const queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q;
        // if (filter.status) queryParam.status = filter.status; else delete queryParam.status;
        history.push({
            pathname: PostAuthRoutes('users').path,
            search: `?${queryString.stringify(queryParam)}`
        });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

    /**
    * function to fetch permission list
    * @param {Object} payload - The params which are used for the api
    * @param {string} payload.page - Page number to load
    * @param {string} payload.limit -  Count of the documents per page
    * @param {string} payload.filter - Filter data
    * @param {string} payload.search - Search key
    * @return json 
    * @created_at 14 June 2022
    */
    const getData = async () => {
        await getUserList(payload);
    }

    /**
     * function to sort columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * function to select columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSelect = (data) => {
        // console.log(data.selectedRows)
    }

    /**
     * function to handle page
     * @param {String} page - Page number to load 
     * @return view
     * @created_at 14 June 2022
     */

    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={users}
            total={total}
            per_page={per_page}
            setPage={page => handlePage(page)}
            handleSelect={handleSelect}
            handleSort={handleSort}
        />

    )
}
export { UsersDataTable }