import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShopperAction } from '../../../../redux/actions';
import { PostAuthRoutes } from "../../../../routes";
import { Chip } from '@mui/material';
import { DataTable } from '../../../../_components/data-table/DataTable';
import { useHistory } from "react-router-dom";
import queryString from 'query-string';


function ShoppersBrandsDataTable({ shopperId, filterAction, filter, search, limit, page, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { total, per_page, shoppers_brands_loading: isLoading, shoppers_brands } = useSelector((state) => state.ShopperReducer);
    const getShoppersBrandsList = (params) => dispatch(ShopperAction.getShoppersBrandsList(params));

    /** Initialize and declare state */
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [payload, setPayload] = useState({ shopper_id: shopperId, filter, search, page, limit });

    const columns = [
        { name: "No", center: true, width: '5%', cell: (row, i) => ((per_page * (page - 1)) + (i + 1)) },
        { name: "Name", selector: (row, i) => (row.name), sortField: 'name', sortable: true, center: false, width: '15%' },
        { name: "Email", selector: (row, i) => (row.email), sortField: 'email', sortable: true, center: false, width: '15%' },
        { name: "Instagram Handel", selector: (row, i) => (row.insta_handle), sortField: 'insta_handle', sortable: true, center: false, width: '15%', cell: row => (<OfferInstagramHandleRow row={row} />) },
        { name: "Company Name", selector: (row, i) => (row.company_name), sortField: 'company_name', sortable: true, center: false, width: '15%' },
        { name: "Company Url", selector: (row, i) => (row.company_url), sortField: 'company_url', sortable: true, center: false, width: '17%' },
        { name: "Earned Coupons", selector: (row, i) => (row.earned_coupons), sortField: 'earned_coupons', sortable: 'true', center: true, width: '11%' },
        { name: "Status", selector: (row, i) => (row.status), sortField: 'status', sortable: true, center: true, width: '7%', cell: row => (<StatusRow row={row} />) },
    ];


    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author 
     * @created_at 14 June 2022 
     */
    const OfferInstagramHandleRow = ({ row }) => {
        return <a href={row.insta_handle_url} target="_blank" rel="noreferrer">{row.insta_handle_with_prefix}</a>
    }
    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author 
     * @created_at 14 June 2022 
     */
    const StatusRow = ({ row }) => {

        let status = <Chip label={row.status} color="error" className="capitalize" />
        if (row.status === 'active')
            status = <Chip label={row.status} color="success" className="capitalize" />

        return <React.Fragment>{status}</React.Fragment>
    }
    useEffect(() => {
        setPayload({ filter, search, page, limit, sort });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, search, page, limit])

    useEffect(() => {

        const queryParam = { sId: shopperId, ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q;
        // if (filter.status) queryParam.status = filter.status; else delete queryParam.status;
        history.push({
            pathname: PostAuthRoutes('shoppers_brands').path,
            search: `?${queryString.stringify(queryParam)}`
        });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopperId, payload.filter, payload.search])

    /**
    * function to fetch permission list
    * @param {Object} payload - The params which are used for the api
    * @param {string} payload.page - Page number to load
    * @param {string} payload.limit -  Count of the documents per page
    * @param {string} payload.filter - Filter data
    * @param {string} payload.search - Search key
    * @return json 
    * @created_at 14 June 2022
    */
    const getData = async () => {
        await getShoppersBrandsList(payload);
    }

    /**
     * function to sort columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * function to select columns
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const handleSelect = (data) => {
        // console.log(data.selectedRows)
    }

    /**
     * function to handle page
     * @param {String} page - Page number to load 
     * @return view
     * @created_at 14 June 2022
     */

    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={shoppers_brands}
            total={total}
            per_page={per_page}
            setPage={page => handlePage(page)}
            handleSelect={handleSelect}
            handleSort={handleSort}
        />

    )
}
export { ShoppersBrandsDataTable }