import { DealServices } from "../services"
import { GlobalAction } from "./GlobalAction"

export const GET_DEALS_COUNT_REQUEST = 'GET_DEALS_COUNT_REQUEST'
export const GET_DEALS_COUNT_SUCCESS = 'GET_DEALS_COUNT_SUCCESS'
export const GET_DEALS_COUNT_FAILURE = 'GET_DEALS_COUNT_FAILURE'

export const GET_DEAL_DETAILS_REQUEST = 'GET_DEAL_DETAILS_REQUEST'
export const GET_DEAL_DETAILS_SUCCESS = 'GET_DEAL_DETAILS_SUCCESS'
export const GET_DEAL_DETAILS_FAILURE = 'GET_DEAL_DETAILS_FAILURE'


export const ADD_DEALS_REQUEST = 'ADD_DEALS_REQUEST';
export const ADD_DEALS_SUCCESS = 'ADD_DEALS_SUCCESS';
export const ADD_DEALS_FAILURE = 'ADD_DEALS_FAILURE';

export const EDIT_DEALS_REQUEST = 'EDIT_DEALS_REQUEST';
export const EDIT_DEALS_SUCCESS = 'EDIT_DEALS_SUCCESS';
export const EDIT_DEALS_FAILURE = 'EDIT_DEALS_FAILURE';

export const DEAL_HISTORY_REQUEST = 'DEAL_HISTORY_REQUEST';
export const DEAL_HISTORY_SUCCESS = 'DEAL_HISTORY_SUCCESS';
export const DEAL_HISTORY_FAILURE = 'DEAL_HISTORY_FAILURE';

export const UPDATE_DEAL_MODE_REQUEST = 'UPDATE_DEAL_MODE_REQUEST';
export const UPDATE_DEAL_MODE_SUCCESS = 'UPDATE_DEAL_MODE_SUCCESS';
export const UPDATE_DEAL_MODE_FAILURE = 'UPDATE_DEAL_MODE_FAILURE';

export const DealAction = {

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getDealsCount: () => {
        return dispatch => {
            dispatch(request())
            return DealServices.getDealsCount()
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        }

        function request() { return { type: GET_DEALS_COUNT_REQUEST } }
        function success(response) { return { type: GET_DEALS_COUNT_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status.code } }
        function failure(error) { return { type: GET_DEALS_COUNT_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }

    },

    getBrandDealDetails: (params) => {
        return dispatch => {
            dispatch(request(params))
            return DealServices.getBrandDealDetails(params)
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        }

        function request() { return { type: GET_DEAL_DETAILS_REQUEST } }
        function success(response) { return { type: GET_DEAL_DETAILS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status.code } }
        function failure(error) { return { type: GET_DEAL_DETAILS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }

    },


    addDeals: (params) => {
        return dispatch => {
            dispatch(request(params));
            return DealServices.addDeals(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request(request) { return { type: ADD_DEALS_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_DEALS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: ADD_DEALS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    editDeals: (id, params) => {
        return dispatch => {
            dispatch(request(id, params));
            return DealServices.editDeals(id, params)
                .then(
                    response => {
                        dispatch(GlobalAction.showToastMessage(response.message));
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request(id, request) { return { type: EDIT_DEALS_REQUEST, request: request } }
        function success(request, response) { return { type: EDIT_DEALS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: EDIT_DEALS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    getDealUpdateHistory: (params) => {
        return dispatch => {
            dispatch(request(params));
            return DealServices.getDealUpdateHistory(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request(id, request) { return { type: DEAL_HISTORY_REQUEST, request: request } }
        function success(request, response) { return { type: DEAL_HISTORY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: DEAL_HISTORY_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    changeDealMode: (params) => {
        return dispatch => {
            dispatch(request(params));
            return DealServices.changeDealMode(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(error)); }
                );
        };
    
        function request(request) { return { type: UPDATE_DEAL_MODE_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_DEAL_MODE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: UPDATE_DEAL_MODE_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    }

}