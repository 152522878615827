import { apiServices } from "../../_helpers";

export const TrialCouponServices = {


    importTrialCoupons: (params) => {
        return apiServices.post(`/trial-coupon-codes/import-csv`, params, {
            headers: {
                'form-data': true,
            }
        }).then((response) => { return response; })
    },

    
    trialCouponsList: (params) => {
        return apiServices.post(`/trial-coupon-codes/list`, params)
            .then((response) => { return response; })
    },

    createTrialCoupons: (params) => {
        return apiServices.post(`/trial-coupon-codes/create`, params, {
            headers: {
                'form-data': true,
            }
        }).then((response) => { return response; })
    },

}

