import React from 'react'
import { Box, styled } from '@mui/material';

const StyledPreSignIn = styled(({ ...props }) => (<Box {...props} />))`

    & .grid-wrapper {
        height:100vh;
        & .grid-wrapper-inner {
            min-width: 430px;
            max-width: 630px;
            min-height:400px;
            padding:  ${props => props.theme.spacing(5)};
            padding-bottom:  ${props => props.theme.spacing(6)};
        }
    }

`;

export { StyledPreSignIn }


