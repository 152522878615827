import React, { useState } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { useSelector } from "react-redux";
import { CardHeader } from '../../../_components/card'

import { useLocation, useHistory } from 'react-router'
import { AbilityCan } from '../../../_helpers/permission/AbilityCan';
import { PostAuthRoutes } from '../../../routes';

import { ImportTrialCouponCodes } from './import-trial-coupon-codes';
import { TrialCouponCodesDataTable, TrialCouponCodesFilter } from './trial-coupon-codes-list';
import { GeneralButton } from '../../../_components/controls';
import { FilterAlt } from '@mui/icons-material';
import { FormSearchInput } from '../../../_components/form';

function TrialCouponCodes() {

    /** Initialize plugins and variables */
    let history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const [showTrialCouponsFilterModal, setShowTrialCouponsFilterModal] = useState(false);

    const [payload, setPayload] = useState({
        limit: 18, page: 1,
        filter: {},
        search: searchParams.get('q') ?? '',
        reset: false
    });

    const resetTable = () => {
        setPayload({...payload, page:1, reset: !(payload.reset) })
    };


    /**
     * function to open/close PricingModel filter modal
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const trialCouponsFilterModal = () => {
        setShowTrialCouponsFilterModal(!showTrialCouponsFilterModal);
    }

    /**
     * function to apply the selected filter options on the PricingModel list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the PricingModel filter modal
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData })
    }

    /**
     * function to apply the search key words on the PricingModel list
     * @param {String} text Search keyword used
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }


    /**
     * function to create trial coupon
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    function CreateTrialCouponCodes() {
        history.push({
            pathname: PostAuthRoutes('create_trial_coupon_codes').path,

        });
    }
    return (
        <React.Fragment>
            <Box>
                <CardHeader title={PostAuthRoutes('trial_coupon_codes').name} action={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I='upload_trial_coupon_codes' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <ImportTrialCouponCodes resetTable={resetTable} />
                        </AbilityCan>
                        <AbilityCan I='create_trial_coupon_codes' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<React.Fragment>Create Trial Coupon Code</React.Fragment>} onClick={() => CreateTrialCouponCodes()} />
                        </AbilityCan>
                        <AbilityCan I='trial_coupon_codes_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        </AbilityCan>
                        <AbilityCan I='trial_coupon_codes_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<FilterAlt />} onClick={() => trialCouponsFilterModal()} />
                        </AbilityCan>
                    </Stack>
                } />
            </Box>
            <TrialCouponCodesFilter show={showTrialCouponsFilterModal} closeModal={trialCouponsFilterModal} applyFilter={applyFilter} trialCouponCodesFilter={payload.filter} />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <TrialCouponCodesDataTable filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} reset={payload.reset} />
                </Grid>
            </Grid>
        </React.Fragment>

    )

}

export { TrialCouponCodes }