import {
    GET_COUPONS_REQUEST, GET_COUPONS_SUCCESS, GET_COUPONS_FAILURE,
    GET_CUSTOM_COUPONS_REQUEST, GET_CUSTOM_COUPONS_SUCCESS, GET_CUSTOM_COUPONS_FAILURE,
    CREATE_COUPONS_REQUEST, CREATE_COUPONS_SUCCESS, CREATE_COUPONS_FAILURE,
    CREATE_SINGLE_USE_CODES_REQUEST, CREATE_SINGLE_USE_CODES_SUCCESS, CREATE_SINGLE_USE_CODES_FAILURE,
    GET_CUSTOM_COUPON_CAMPAIGNS_REQUEST, GET_CUSTOM_COUPON_CAMPAIGNS_SUCCESS, GET_CUSTOM_COUPON_CAMPAIGNS_FAILURE,
    GET_CUSTOM_CODES_REQUEST, GET_CUSTOM_CODES_SUCCESS, GET_CUSTOM_CODES_FAILURE,

} from '../actions'

const initialState = {
    page: 1, per_page: 18, total: 0, total_pages: 0, coupon_list_loading: false, coupon_list: [], coupon_list_errors: {},
    custom_coupons_loading: false, custom_coupons: [], custom_coupons_error: [],
    create_coupons_loading: false, create_coupons: [], create_coupons_error: [],
    create_single_use_codes_loading: false, create_single_use_codes: [], create_single_use_codes_error: [],
    custom_coupon_campaigns_loading: false, custom_coupon_campaigns: [], custom_coupon_campaigns_error: [],
    custom_codes_loading: false, custom_codes: [], custom_codes_error: [],

}

export function CouponReducer(state = initialState, action) {
    switch (action.type) {

    //  begin::Get busineee list redux 
        case GET_COUPONS_REQUEST: {
            return {
                ...state,
                coupon_list_loading: true,
                coupon_list_errors: {}
            };
        }
        case GET_COUPONS_SUCCESS: {
            let newArray = [...state.coupon_list]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            } 
            newArray = action.data.coupons ? action.data.coupons : [];
            
            return {
                ...state,
                coupon_list_loading: false,
                coupon_list: newArray,
                coupon_list_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };
        }

        case GET_COUPONS_FAILURE: {
            return {
                ...state,
                coupon_list_loading: false,
                coupon_list_errors: action.errors,
            };
        }
        // begin::Get busineee details redux 

        /** begin::Custom coupons redux */
        case GET_CUSTOM_COUPONS_REQUEST: {
            return {
                ...state,
                custom_coupons_error: [],
                custom_coupons_loading: true,
            };
        } case GET_CUSTOM_COUPONS_SUCCESS: {
            return {
                ...state,
                custom_Coupons_deal: action.data,
                custom_coupons_loading: false,
            };
        } case GET_CUSTOM_COUPONS_FAILURE: {
            return {
                ...state,
                custom_coupons_loading: false,
                custom_coupons_error: action.error
            };
        }
        /** end::Custom coupons redux */

                /** begin::Create coupons redux */
        case CREATE_COUPONS_REQUEST: {
            return {
                ...state,
                create_coupons_loading: true,
                create_coupons_error: {}
            };
        } case CREATE_COUPONS_SUCCESS: {
            return {
                ...state,
                create_coupons_loading: false,
                create_coupons: action.data,
                create_coupons_error: {}
            };
        } case CREATE_COUPONS_FAILURE: {
            return {
                ...state,
                create_coupons_loading: false,
                create_coupons_error: action.error
            };
        }
        /** end::Create coupons redux */

        /** begin::Create single use codes redux */
        case CREATE_SINGLE_USE_CODES_REQUEST: {
            return {
                ...state,
                create_single_use_codes_loading: true,
                create_single_use_codes_error: {}
            };
        } case CREATE_SINGLE_USE_CODES_SUCCESS: {
            return {
                ...state,
                create_single_use_codes_loading: false,
                create_single_use_codes: action.data,
                create_single_use_codes_error: {}
            };
        } case CREATE_SINGLE_USE_CODES_FAILURE: {
            return {
                ...state,
                create_single_use_codes_loading: false,
                create_single_use_codes_error: action.error
            };
        }
        /** end::Create single use codes redux */
        
        /** begin::Custom coupons redux */
        case GET_CUSTOM_COUPON_CAMPAIGNS_REQUEST: {
            return {
                ...state,
                custom_coupon_campaigns_error: [],
                custom_coupon_campaigns_loading: true,
            };
        } case GET_CUSTOM_COUPON_CAMPAIGNS_SUCCESS: {
            return {
                ...state,
                custom_coupon_campaigns: action.data.campaigns,
                custom_coupon_campaigns_loading: false,
            };
        } case GET_CUSTOM_COUPON_CAMPAIGNS_FAILURE: {
            return {
                ...state,
                custom_coupon_campaigns_loading: false,
                custom_coupons_error: action.error,
                custom_coupon_campaigns: []
            };
        }
        /** end::Custom coupons redux */

        /** begin::Coupon redux */
        case GET_CUSTOM_CODES_REQUEST: {
            return {
                ...state,
                custom_codes_loading: true,
                custom_codes: [],
                custom_codes_error: []
            };
        } case GET_CUSTOM_CODES_SUCCESS: {
            return {
                ...state,
                custom_codes_loading: false,
                custom_codes: action.data.coupons,
                custom_codes_error: [],
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };
        } case GET_CUSTOM_CODES_FAILURE: {
            return {
                ...state,
                custom_codes_loading: false,
                custom_codes: [],
                custom_codes_error: []
            };
        }
        default:
            return state

    }
}