import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl, FormHelperText } from '@mui/material';
import moment from 'moment';



/**
 * Declare default value of input properties 
 */
const defaultProps = {
    className: "",
    isReadOnly: false,
    maxLength: 255,
    maskType: ''
};



function FormDatePicker({ label, name, placeholder, className, onChange, tabIndex, id, error, isReadOnly, pattern, maxLength, maskType, minDate = null, value, info, views, inputFormat, ...props }) {

    const [val, setVal] = React.useState((value && value !== '') ? moment(value, 'DD-MM-yyyy').format('MM-DD-YYYY') : null);

    const handleChange = (e) => {

        setVal(e);
        onChange({ target: { type: `date_time`, name: name, value: moment(e).format('DD/MM/yyyy') } });
    }

    return (

        <FormControl fullWidth className={`${className}`} >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    views={views}  //['year', 'month']
                    value={val}
                    onChange={handleChange}
                    label={label}
                    // onError={console.log}
                    minDate={minDate}
                    inputFormat={inputFormat ?? 'dd/MM/yyyy'}
                    readOnly={isReadOnly}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
            {(info) && <FormHelperText id={`helper_${name}`}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error>{error}</FormHelperText>}
        </FormControl>

    )
}


FormDatePicker.defaultProps = defaultProps;

export { FormDatePicker }