import React from 'react';
import { PostAuthRoutes } from '../../../routes';
import { Helmet } from "react-helmet-async";
import { APP_NAME } from '../../../_helpers';
import { Box, Grid, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { EmailLogDetailCard, EmailLogRequestCard } from '.';
import { CardHeader } from '../../../_components/card';
import { GeneralButton } from '../../../_components/controls';
import { Send } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { EmailLogAction } from '../../../redux/actions';


function EmailLogsDetails() {


    /** Initialize plugins and variables */

    let { state } = useLocation();

    const { email_log } = state ?? {};

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const resendMail = (params) => dispatch(EmailLogAction.resendMail(params));

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PostAuthRoutes('email_logs_details').name}`}</title>
            </Helmet>

            <Box>
                <CardHeader title={PostAuthRoutes('email_logs_details').name} action={
                    <Stack spacing={2} direction="row">
                        <GeneralButton label={<React.Fragment><Send /> Resend Mail</React.Fragment>} onClick={() => resendMail(email_log._id)} />
                    </Stack>
                } />
            </Box>
            <Grid container spacing={2} justify="center">
                <Grid item xs={12} md={12} >
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} md={12} >
                            <EmailLogDetailCard emailLog={email_log} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} >
                    <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                        <EmailLogRequestCard emailLog={email_log} />
                    </Grid>
                </Grid>
            </Grid>


        </React.Fragment>
    );
}

export { EmailLogsDetails };
