import React, { useState } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { useDispatch, useSelector } from "react-redux";
import { CardHeader } from '../../_components/card'
import { GeneralButton } from '../../_components/controls'
import { PricingModelAction } from '../../redux/actions'
import { useHistory, useLocation } from 'react-router'
import { AbilityCan } from '../../_helpers/permission/AbilityCan';
import { PostAuthRoutes } from '../../routes';
import { PricingModelDataTable, PricingModelFilter } from '.';
import { UpdateStatusModal } from '../../_components/modal';
import { FormSearchInput } from '../../_components/form';
import { FilterAlt } from '@mui/icons-material';

function PricingModel() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const changeStatus = (params) => dispatch(PricingModelAction.changeStatus(params));

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '' });
    const [showPricingModelFilterModal, setShowPricingModelFilterModal] = useState(false);
    const [payload, setPayload] = useState({
        limit: 18, page: 1,
        filter: { status: searchParams.get('status') ?? '' },
        search: searchParams.get('q') ?? ''
    });

    /**
     * function to open/close status update modal
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const statusUpdateModal = ({ id, status }) => {
        setStatusData({ id: id, status: status });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to update PricingModel status
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'pricing_model_id': id });
    }

    /**
     * function to create PricingModel
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    function createPricingModel() {
        history.push({
            pathname: PostAuthRoutes('create_pricing_model').path,

        });
    }

    function trialCouponCodes() {
        history.push({
            pathname: PostAuthRoutes('trial_coupon_codes').path,

        });
    }

    /**
     * function to open/close PricingModel filter modal
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const PricingModelFilterModal = () => {
        setShowPricingModelFilterModal(!showPricingModelFilterModal);
    }

    /**
     * function to apply the selected filter options on the PricingModel list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the PricingModel filter modal
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData })
    }

    /**
     * function to apply the search key words on the PricingModel list
     * @param {String} text Search keyword used
     * @return view
     * @author 
     * @created_at 14 June 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader title={PostAuthRoutes('pricing_model').name} action={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I='trial_coupon_codes' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<React.Fragment>Trial Coupon Codes</React.Fragment>} onClick={() => trialCouponCodes()} />
                        </AbilityCan>
                        <AbilityCan I='add_pricing_model' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<React.Fragment>Create Pricing Model</React.Fragment>} onClick={() => createPricingModel()} />
                        </AbilityCan>
                        <AbilityCan I='pricing_model_search' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        </AbilityCan>
                        <AbilityCan I='pricing_model_filter' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton label={<FilterAlt />} onClick={() => PricingModelFilterModal()} />
                        </AbilityCan>
                        
                    </Stack>
                } />
            </Box>
            <PricingModelFilter show={showPricingModelFilterModal} closeModal={PricingModelFilterModal} applyFilter={applyFilter} PricingModelFilter={payload.filter} />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <PricingModelDataTable statusUpdateModal={statusUpdateModal} filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} />
                </Grid>
            </Grid>
            <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
        </React.Fragment>

    )

}

export { PricingModel }