import { InvoiceServices } from "../services";

export const GET_INVOICE_LIST_REQUEST = 'GET_INVOICE_LIST_REQUEST';
export const GET_INVOICE_LIST_SUCCESS = 'GET_INVOICE_LIST_SUCCESS';
export const GET_INVOICE_LIST_FAILURE = 'GET_INVOICE_LIST_FAILURE';

export const InvoiceAction = {

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getInvoiceList: (params) => {
        return dispatch => {
            dispatch(request(params))
            return InvoiceServices.getInvoiceList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        }

        function request(request) { return { type: GET_INVOICE_LIST_REQUEST, request } }
        function success(request, response) { return { type: GET_INVOICE_LIST_SUCCESS, request, message: response.message, data: response.data, totalCount: response.count, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_INVOICE_LIST_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },
}


