import { apiServices } from "../../_helpers";

export const ShopperServices = {

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getShoppersCount: () => {
        return apiServices.get(`/shoppers/count`)
            .then((response) => { return response; })

    },
    /**
     * function get Shopper list
     * @param params
     * @return view
     * @author Naveen
     * @created_at 30/12/2022
     */
    getShoppersList: (params) => {
        return apiServices.post(`/shoppers/list`, params)
            .then((response) => { return response; })
    },

    /**
     * function to get Shopper brand details
     * @param params
     * @return view
     * @author Naveen
     * @created_at 02/01/2023
     */
    getShoppersBrandsList: (params) => {
        return apiServices.post(`/shoppers/brands/list`, params)
            .then((response) => { return response; })
    },

    /**
    * function shopper select list
    * @param params
    * @return view
    * @author Naveen
    * @created_at 03/01/2022
    */
    shopperSelectList: () => {
        return apiServices.get(`/shopper/shopper_select_list`)
            .then((response) => {
                return response;
            })
    },
}