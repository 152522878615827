import { Box, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { BrandAction } from '../../../../redux/actions';

function BrandDetail({ brand_id, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { brand_loading, brand } = useSelector((state) => state.BrandReducer);
    const getBrandDetails = (params) => dispatch(BrandAction.getBrandDetails(params));

    /** Initialize and declare state */

    useEffect(() => {
        getBrandDetails({ brand_id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand_id])


    if (brand_loading)
        return ''

    return (
        <Card style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
            <CardHeader title="Brand Details" />
            <CardContent>
                <Stack direction="column" spacing={1}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2"> {`Company Name : `}</Typography>
                        <Typography variant="h6">{brand.company_name}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2"> {`Public Instagram Handle : `}</Typography>
                        <Typography variant="h6">
                            <a href={brand.insta_handle_url} target="_blank" rel="noreferrer" >{brand.insta_handle_with_prefix}</a>
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2"> {`Company Representative Name : `}</Typography>
                        <Typography variant="h6">{brand.name}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2"> {`Company Representative Email : `}</Typography>
                        <Typography variant="h6">{brand.email}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2"> {`Company URL : `}</Typography>
                        <Typography variant="h6"><a rel="noreferrer" target="_blank" href={`${brand.company_url}`}>{brand.company_url}</a></Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2"> {`Company Phone Number : `}</Typography>
                        <Typography variant="h6">{brand.company_phone}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2"> {`Subdomain : `}</Typography>
                        <Typography variant="h6">  <a href={brand.sub_domain_url} target="_blank" rel="noreferrer">{brand.sub_domain_url}</a></Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2"> {`Deal Available : `}</Typography>
                        <Typography variant="h6" sx={{ color: brand.deal_available ? 'success' : 'error' }}>{(brand.deal_available ? 'Yes' : 'No')} </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2"> {`Created On : `}</Typography>
                        <Typography variant="h6">{brand.created_at}</Typography>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )
}

export { BrandDetail }