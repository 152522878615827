import { Box, Card, CardContent, CardHeader, CardMedia, Divider, Grid, Link, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { BrandAction } from '../../../../redux/actions';
import { GeneralButton } from '../../../../_components/controls';
import { Edit, History } from '@mui/icons-material';
import { PostAuthRoutes } from '../../../../routes';
import { useHistory } from 'react-router-dom';
import { AbilityCan } from '../../../../_helpers/permission/AbilityCan';

function DealDetail({ brand_id, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);
    const { deal_loading, deal, brand_loading, brand } = useSelector((state) => state.BrandReducer);
    const getDealDetailsOfBrand = (params) => dispatch(BrandAction.getDealDetailsOfBrand(params));

    /** Initialize and declare state */

    useEffect(() => {
        getDealDetailsOfBrand({ brand_id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand_id])


    const updateBrandDeal = () => {

        let redirectPath = `${PostAuthRoutes('create_deal').path}?bId=${brand_id}`;

        if (deal && deal._id) {
            redirectPath = `${PostAuthRoutes('update_deal').path}?bId=${brand_id}`;
        }
        history.push(redirectPath);
    }

    const getDealHistory = () => {
        let redirectPath = `${PostAuthRoutes('deal_history').path}?dId=${deal._id}`;
        history.push(redirectPath);
    }

    if (deal_loading)
        return '';


    return (
        <Card style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
            <CardHeader title="Deal Details"
                action={
                    <Stack spacing={1} direction="row" alignItems={`center`}>
                        <AbilityCan I='deal_update' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton size={`small`} label={<React.Fragment><Edit />&nbsp;{deal && deal._id ? 'Update' : 'Create'} Deal</React.Fragment>} onClick={() => updateBrandDeal()} />
                        </AbilityCan>
                        <AbilityCan I='deal_history' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <GeneralButton size={`small`} label={<React.Fragment><History />&nbsp;History</React.Fragment>} onClick={() => getDealHistory()} />
                        </AbilityCan>
                    </Stack>
                }
            />
            {Object.keys(deal).length > 0 &&
                <CardContent>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={12}>
                            <Stack direction="column" spacing={1}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body2">Offer Instagram Handle : </Typography>
                                    <Link href={deal.insta_handle_url} variant="body2" target="_blank" rel="noreferrer">{deal.insta_handle_with_prefix}</Link>
                                </Box>
                                {/* {deal.logo &&
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body2">Logo : </Typography>
                                        <CardMedia src={deal.logo} component="img" height="100" alt={deal.logo} />
                                    </Box>
                                } */}
                                <Divider />
                                {/* <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body2">Background Color : </Typography>
                                    <Box style={{ width: '25px', height: '25px', border: '1px solid #cfd8dc', backgroundColor: `${(deal.primary_color_hex) ? (deal.primary_color_hex) : ''}` }}> </Box>
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body2">Text Color : </Typography>
                                    <Box style={{ width: '25px', height: '25px', border: '1px solid #cfd8dc', backgroundColor: `${(deal.secondary_color_hex) ? (deal.secondary_color_hex) : ''}` }}> </Box>
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body2"> {`Button Color : `}</Typography>
                                    <Box style={{ width: '25px', height: '25px', border: '1px solid #cfd8dc', backgroundColor: `${(deal.link_color_hex) ? (deal.link_color_hex) : ''}` }}> </Box>
                                </Box>
                                <Divider /> */}
                                {/* <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body2"> {`Giveaway countdown: `}</Typography>
                                    <Typography variant="h6">{deal.is_giveaway} </Typography>
                                </Box> */}
                                {
                                    deal.times_between_post &&
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body2"> {`How Often : `}</Typography>
                                        <Typography variant="h6">{deal.times_between_post} </Typography>
                                    </Box>
                                }
                                {/* <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body2" style={{ minWidth: `250px` }}> {`Description : `}</Typography>
                                    <Typography variant="h6" component={'span'} dangerouslySetInnerHTML={{ __html: deal.reward_description }} />
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body2" style={{ minWidth: `250px` }}> {`Fine Print : `}</Typography>
                                    <Typography variant="h6" component={'span'} dangerouslySetInnerHTML={{ __html: deal.offer_description }} />
                                </Box>
                                {
                                    deal.giveaway_date &&
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body2"> {`Sweepstakes ends in : `}</Typography>
                                        <Typography variant="h6" >{deal.giveaway_date}</Typography>
                                    </Box>
                                }
                                <Divider />
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body2" component={'span'} dangerouslySetInnerHTML={{ __html: `Send follow up message to user <br/>when they can post again : ` }} />
                                    <Typography variant="h6" component={'span'} dangerouslySetInnerHTML={{ __html: deal.can_send_followup_msg }} />
                                </Box> */}
                                {/* <Divider /> */}
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body2" component={'span'} dangerouslySetInnerHTML={{ __html: `Direct message to accompany the <br/> coupon code :` }} />
                                    <Typography variant="h6" component={'span'} dangerouslySetInnerHTML={{ __html: deal.direct_message }} />
                                </Box>
                                {/* {deal.sample_story &&
                                    <React.Fragment>
                                        <Divider />
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography variant="body2">Sample Story : </Typography>
                                            <CardMedia src={deal.sample_story} component="img" height="150" alt={deal.sample_story} />
                                        </Box>
                                    </React.Fragment>
                                } */}
                            </Stack>
                        </Grid>

                        {/* {brand_loading && ''}
                        {!brand_loading &&
                            <Grid item xs={12} md={12} display="flex" alignItems="center" justifyContent="center">
                                <iframe title="insta story" width="400" height={650} src={brand.sub_domain_url} frameBorder="0"></iframe>
                            </Grid>
                        } */}

                    </Grid>
                </CardContent>
            }
            {Object.keys(deal).length === 0 && <CardContent>Not found</CardContent>}
        </Card>
    )
}

export { DealDetail }