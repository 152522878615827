import { Box, Card, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { BrandAction } from '../../../../redux/actions';
import { DailyCouponsIssued } from './DailyCouponsIssued';
import { BrandCoupons } from '../brand-coupons.js';

function CouponDetails({ brand_id }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { brands_coupon_counts_loading, brands_coupon_counts } = useSelector((state) => state.BrandReducer);
    const getBrandCouponCounts = (params) => dispatch(BrandAction.getBrandCouponCounts(params));

    /** Initialize and declare state */

    useEffect(() => {
        getBrandCouponCounts({ brand_id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand_id])

    if (brands_coupon_counts_loading)
        return '';

    return (
        <Card>
            <CardHeader title="Coupon Details" action={
                    <Stack spacing={1} direction="row" alignItems={`center`}>
                        <BrandCoupons/>
                    </Stack>
                } />
            <CardContent>
                <Box display="flex" justifyContent="space-around" alignItems="center">
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Typography variant="body2"> {`Total Coupons : `}</Typography>
                        <Typography variant="h6">{brands_coupon_counts.total_coupons}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Typography variant="body2"> {`Balance Coupons : `}</Typography>
                        <Typography variant="h6">{brands_coupon_counts.balance_coupons}</Typography>
                    </Box>
                </Box>
                <Grid item xs={12} md container style={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                    <DailyCouponsIssued brand_id={brand_id} />
                </Grid>
            </CardContent>
        </Card>
    )
}

export { CouponDetails }