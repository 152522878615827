import { Box, Chip, Stack, Typography } from '@mui/material'
import { Card, CardContent } from '../../../../_components/card';

function EmailLogDetailCard({ emailLog, ...props }) {

  /** Initialize and declare variables */
  const { brand, created_at, mail_type, receiver, status } = emailLog

  console.log(emailLog);

  return (
    <Card>
      <CardContent>
        <Stack spacing={1} direction="row" justifyContent="space-between">
          <Box display="flex" justifyContent="space-between" alignItems="center" >
            <Typography variant="body2"> {`Receiver :`}</Typography>
            <Typography variant="h6">{receiver}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" >
            <Typography variant="body2"> {`Mail Type :`}</Typography>
            <Typography variant="h6">{mail_type}</Typography>
          </Box>
          {brand &&
            <Box display="flex" justifyContent="space-between" alignItems="center" >
              <Typography variant="body2"> {`Brand :`}</Typography>
              <Typography variant="h6">{brand}</Typography>
            </Box>
          }
          <Box display="flex" justifyContent="space-between" alignItems="center" >
            <Typography variant="body2"> {`Created At :`}</Typography>
            <Typography variant="h6">{created_at}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" >
            <Typography variant="body2">{`Status : `}</Typography>
            <Chip size='small' label={status === 'success' ? 'Success' : 'Failed'} color={status === 'success' ? 'success' : 'error'} />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}

export { EmailLogDetailCard }