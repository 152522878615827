import { apiServices } from "../../_helpers";

export const CouponServices = {

    deleteActiveCoupons: (params) => {
        return apiServices.post(`/ns/coupons/delete-brand-active-coupons`, params)
            .then((response) => { return response; })
    },

    importCoupons: (params) => {
        return apiServices.post(`/ns/coupons/import-csv`, params, {
            headers: {
                'form-data': true,
            }
        }).then((response) => { return response; })
    },

    
    couponsList: (params) => {
        return apiServices.post(`/ns/coupons/list`, params)
            .then((response) => { return response; })
    },

    getCustomCoupons: (params) => {
        return apiServices.post(`/ns/coupons/custom/details`, params)
            .then((response) => { return response; })
    },

    createCoupons: (params) => {
        return apiServices.post(`/ns/coupons/custom/create`, params, {
            headers: {
                'form-data': true,
            }
        }).then((response) => { return response; })
            
    },

    createSingleUseCodes: (params) => {
        return apiServices.post(`/ns/coupons/custom/multiple-code-url/create`, params, {
            headers: {
                'form-data': true,
            }
        }).then((response) => { return response; })
    },

    getCustomCouponCampaigns: (params) => {
        return apiServices.post(`/ns/coupons/custom/campaigns/list`, params)
            .then((response) => { return response; })
    },

    getAllCustomCodes: (params) => {
        return apiServices.post(`/ns/coupons/custom/multiple-code-url/list`, params)
            .then((response) => { return response; })
    },

}

