import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, FormGroup, Grid, Switch, Typography } from '@mui/material';

import { withStyles } from "@mui/styles";
import { BrandAction, DealAction, GlobalAction } from '../../../../redux/actions';


const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

function DealMode({brand_id}) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();        
    const [latestDeal, setLatestDeal] = useState(false);

    /** Redux actions and state */
    const { deal_loading, deal } = useSelector((state) => state.BrandReducer);
    
    const { brands_coupon_counts_loading, brands_coupon_counts } = useSelector((state) => state.BrandReducer);

    const changeDealMode = (params) => dispatch(DealAction.changeDealMode(params));
    const getBrandCouponCounts = (params) => dispatch(BrandAction.getBrandCouponCounts(params));
    

    useEffect(() => {
        if (!deal_loading && deal) {
            setLatestDeal(deal.is_deal_live ?? false);
        }
        getBrandCouponCounts({ brand_id: brand_id });
    }, [deal])

    const setDealMode = (e) => {
        if (!deal || (deal && !deal.direct_message) || (!brands_coupon_counts) || (brands_coupon_counts && brands_coupon_counts.balance_coupons === 0)) {
            
            let message = `${((!brands_coupon_counts) || (brands_coupon_counts && brands_coupon_counts.balance_coupons === 0)) ? 'Please add coupons' : ''}${((!brands_coupon_counts) || (brands_coupon_counts && brands_coupon_counts.balance_coupons === 0)) && !deal.direct_message ? ' and ' : ''}${!deal || (deal && !deal.direct_message) ? 'Enter direct message' : ''}`;

            message = message || 'Please add coupons and Enter direct message';
            
            dispatch(GlobalAction.showToastMessage(capitalizeFirstLetter(message)))
            
         } else {
            changeDealMode({ deal_mode: e.target.checked, deal_id: deal._id }).then((response) => {
                if (response.status === 1) {
                    setLatestDeal(response.data.is_deal_live ?? false);
                }
            })
        }

        
    }

    const capitalizeFirstLetter = (string) => {
        if (string.length === 0) {
          return '';
        }
        const firstLetter = string.charAt(0).toUpperCase();
        const restOfTheString = string.slice(1).toLowerCase();
        return firstLetter + restOfTheString;
    };
    

    return (
        <React.Fragment>
        <Card style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
            <CardContent style={{ padding: `20px` }}>
            {/* {deal && deal._id &&  */}
                <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup>
                            <Typography component="div">
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>Deal Mode:</Grid>
                                    <Grid item>Offline</Grid>
                                    <Grid item>
                                        <AntSwitch onChange={setDealMode} checked={latestDeal ?? false} name="checkedC" />
                                    </Grid>
                                    <Grid item>Live</Grid>
                                </Grid>
                            </Typography>
                        </FormGroup>
                    </Grid>
                </Grid>
            {/* } */}
            </CardContent>
       
        </Card>

</React.Fragment>
    )

}


export { DealMode };
