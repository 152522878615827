import { Box, Grid, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RoleAction, UserAction } from '../../../../redux/actions'
import { PostAuthRoutes } from '../../../../routes'
import { Card, CardHeader, CardContent } from '../../../../_components/card'
import { FormInput, FormPassword, FormSelect } from '../../../../_components/form'
import { APP_NAME, handleInputChange, validateForm } from '../../../../_helpers'
import { validate } from '.'
import { CancelButton, SubmitButton } from '../../../../_components/controls'

// Initialize form input values to null
const inputs = { user_id: '', first_name: '', last_name: '', email: '', password: '', role_id: '', role: '' };

function CreateUpdateUser() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** User id and details on update page */
    const location = useLocation();
    const { user } = location.state ?? {};

    /** Redux actions and state */
    const { add_user_loading: isLoading, add_user_errors: createUserErrors } = useSelector((state) => state.UserReducer);
    const { roles_select_list_loading, roles_select_list } = useSelector((state) => state.RoleReducer);
    const addUser = (params) => dispatch(UserAction.addUser(params));
    const updateUser = (params) => dispatch(UserAction.updateUser(params));
    const getRoleSelectList = (params) => dispatch(RoleAction.getRoleSelectList(params));


    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });
    const [isSetUserLoading, setUserLoading] = useState(true);

    useEffect(() => {
        getRoleSelectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setErrors({ ...createUserErrors });
    }, [createUserErrors])

    useEffect(() => {

        if (user && user._id) {
            setData({
                user_id: user._id,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                password: '',
                role_id: user.role_id
            });
            setUserLoading(false);
        } else {
            setUserLoading(false);
        }
    }, [user])


    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle update brand form submit action
     * @param {object} e form object
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchUpdateUserAction(data);
    }

    /**
     * function to dispatch update brand api
     * @author Jasin
     * @created_at 14 June 2022
     */
    async function dispatchUpdateUserAction(formData) {

        const isCreated = user && user._id ? await updateUser(formData) : await addUser(formData);

        if (isCreated && isCreated.status === 1) {
            let redirectPath = `${PostAuthRoutes('users').path}`;
            history.push(redirectPath);
        }
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 14 June 2022 
     */
    function cancelUser() {
        let redirectPath = `${PostAuthRoutes('users').path}`;
        history.push(redirectPath);
    }



    return (

        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${user && user._id ? PostAuthRoutes('update_user').name : PostAuthRoutes('create_user').name}`}</title>
            </Helmet>
            <Box>
                <CardHeader title={user && user._id ? PostAuthRoutes('update_user').name : PostAuthRoutes('create_user').name} />
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" display={`flex`} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Card>
                                <CardContent >
                                    {!isSetUserLoading && !roles_select_list_loading &&
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <FormInput
                                                    tabIndex={1}
                                                    label='First name'
                                                    name='first_name'
                                                    autoFocus={true}
                                                    onChange={handleChange}
                                                    value={data.first_name}
                                                    error={(action.isSubmitted && (errors.first_name)) ? (errors.first_name) : ''}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormInput
                                                    tabIndex={2}
                                                    label='Last name'
                                                    name='last_name'
                                                    onChange={handleChange}
                                                    value={data.last_name}
                                                    error={(action.isSubmitted && (errors.last_name)) ? (errors.last_name) : ''}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormInput
                                                    tabIndex={3}
                                                    label='Email'
                                                    name='email'
                                                    onChange={handleChange}
                                                    value={data.email}
                                                    error={(action.isSubmitted && (errors.email)) ? (errors.email) : ''}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormSelect
                                                    tabIndex={5}
                                                    label='Role'
                                                    placeholder="Select role"
                                                    name='role_id'
                                                    onChange={handleChange}
                                                    value={data.role_id}
                                                    error={(action.isSubmitted && (errors.role_id)) ? (errors.role_id) : ''}
                                                    data={roles_select_list}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <FormPassword
                                                    tabIndex={4}
                                                    label='Password'
                                                    name='password'
                                                    onChange={handleChange}
                                                    value={data.password}
                                                    error={(action.isSubmitted && (errors.password)) ? (errors.password) : ''}
                                                />
                                            </Grid>
                                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                    <SubmitButton label={user && user._id ? 'Update' : 'Create'} loading={isLoading} tabIndex={4} />
                                                    <CancelButton onClick={cancelUser} tabIndex={7} />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    )
}

export { CreateUpdateUser }