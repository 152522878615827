import { apiServices } from '../../_helpers';


export const PermissionServices = {

    /**
     * Service to add permission
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 April 2022
     */
    addPermission: (params) => {
        return apiServices.post('/permissions/add', params)
            .then((response) => { return response; })
    },

    /**
     * Service to update permission
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 April 2022
     */
    updatePermission: (params) => {
        return apiServices.post('/permissions/update', params)
            .then((response) => { return response; })
    },

    /**
     * Service to change permission status
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 April 2022
     */
    changePermissionStatus: (params) => {
        return apiServices.post('/permissions/change-status', params)
            .then((response) => { return response; })
    },

    /**
     * Service for permission select list
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 April 2022
    */
    permissionSelectList: (params) => {
        return apiServices.post('permissions/list/select', params)
            .then((response) => { return response; })
    },

    /**
     * Service for permissions list
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 April 2022
    */
    getPermissionsList: (params) => {
        return apiServices.post('permissions/list', params)
            .then((response) => { return response; })
    },
};
