import {
    GET_SHOPPER_COUNT_REQUEST, GET_SHOPPER_COUNT_SUCCESS, GET_SHOPPER_COUNT_FAILURE,
    SHOPPERS_LIST_REQUEST, SHOPPERS_LIST_SUCCESS, SHOPPERS_LIST_FAILURE,
    SHOPPERS_BRANDS_REQUEST, SHOPPERS_BRANDS_SUCCESS, SHOPPERS_BRANDS_FAILURE,
    SHOPPER_SELECT_LIST_REQUEST, SHOPPER_SELECT_LIST_SUCCESS, SHOPPER_SELECT_LIST_FAILURE
} from '../actions'

const initialState = {
    shoppers_count_loading: true, shoppers_count: 0, shoppers_count_error: {},
    page: 1, per_page: 18, total: 0, total_pages: 0, shoppers_loading: false, shoppers: [], shoppers_errors: {},
    shoppers_page: 1, shoppers_brands_per_page: 18, shoppers_brands_total: 0, shoppers_brands_total_pages: 0, shoppers_brands_loading: false, shoppers_brands: [], shoppers_brands_errors: {},
    shopper_select_loading: false, shopper_select: [], shopper_select_errors: {},
}

export function ShopperReducer(state = initialState, action) {
    switch (action.type) {

        //  begin::Get shoppers list redux 
        case GET_SHOPPER_COUNT_REQUEST: {
            return {
                ...state,
                shoppers_count_loading: true,
                shoppers_count_error: {}
            };
        }
        case GET_SHOPPER_COUNT_SUCCESS: {

            return {
                ...state,
                shoppers_count_loading: false,
                shoppers_count: action.data,
                shoppers_count_error: {},
            };
        }

        case GET_SHOPPER_COUNT_FAILURE: {
            return {
                ...state,
                shoppers_count_loading: false,
                shoppers_count: 0,
                shoppers_count_error: action.errors
            };
        }
        // begin::Get busineee details redux 

        //  begin::Get shoppers list redux 
        case SHOPPERS_LIST_REQUEST: {
            return {
                ...state,
                shoppers_loading: true,
                shoppers_errors: {}
            };
        }
        case SHOPPERS_LIST_SUCCESS: {
            let newArray = [...state.shoppers]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.shoppers.forEach((payload) => {
                const shopperIndex = newArray.findIndex(shopper => shopper._id === payload._id);
                if (shopperIndex !== -1) {
                    newArray[shopperIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                shoppers_loading: false,
                shoppers: newArray,
                shoppers_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };
        }

        case SHOPPERS_LIST_FAILURE: {
            return {
                ...state,
                brands_loading: false,
                brands_errors: action.errors,
            };
        }

        // begin::Get shoppers list redux 

        //  begin::shoppers brand details redux 
        case SHOPPERS_BRANDS_REQUEST: {
            return {
                ...state,
                shoppers_brands_loading: true,
                shoppers_brands_errors: {}
            };
        }
        case SHOPPERS_BRANDS_SUCCESS: {
            let newArray = [...state.shoppers_brands]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.shoppers_brands.forEach((payload) => {
                const shopperIndex = newArray.findIndex(shopper => shopper._id === payload._id);
                if (shopperIndex !== -1) {
                    newArray[shopperIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                shoppers_brands_loading: false,
                shoppers_brands: newArray,
                shoppers_shoppers_brands_errors: {},
                shoppers_brands_page: action.data.page,
                shoppers_brands_per_page: action.data.per_page,
                shoppers_brands_total: action.data.total,
                shoppers_brands_total_pages: action.data.total_pages
            };
        }

        case SHOPPERS_BRANDS_FAILURE: {
            return {
                ...state,
                shoppers_brands_loading: false,
                shoppers_brands_errors: action.errors,
            };
        }
        // begin::shoppers brand details redux 

        //  begin::Get Shopper select list redux 
        case SHOPPER_SELECT_LIST_REQUEST: {
            return {
                ...state,
                shopper_select_loading: true,
                request: action.request,
            }
        }
        case SHOPPER_SELECT_LIST_SUCCESS: {
            return {
                ...state,
                shopper_select_loading: false,
                shopper_select: action.data,
            }
        }
        case SHOPPER_SELECT_LIST_FAILURE: {
            return {
                ...state,
                shopper_select_loading: false,
                shopper_select_errors: action.errors,
            }
        }
        // begin::Get Shopper select list redux 

        default:
            return state

    }
}