import React from 'react'
import { Box, Typography } from '@mui/material';
import { ShortLogo } from '../../../_components/logo/short-logo';
import { useLocation } from 'react-router';
import { PreAuthRoutes } from '../../../routes';

function Header() {

    const location = useLocation();

    return (
        <Box mb={2} >

            <Box display={`flex`} alignItems={`center`} justifyContent={`center`} mb={2}>
                <ShortLogo />
            </Box>

            {(location.pathname === PreAuthRoutes('login').path) && (
                <React.Fragment>
                    <Typography variant="h6" align="center">{PreAuthRoutes('login').name}</Typography>
                    <Typography variant="body1" align="center">Continue to <span></span></Typography>
                </React.Fragment>
            )}

            {(location.pathname === PreAuthRoutes('login_password').path) && (
                <React.Fragment>
                    <Typography variant="h6" align="center">{PreAuthRoutes('login_password').name}</Typography>
                    <Typography variant="body1" align="center">Continue to <span></span></Typography>
                </React.Fragment>
            )}

            {(location.pathname === PreAuthRoutes('register').path) && (
                <React.Fragment>
                    <Typography variant="h6" align="center">{PreAuthRoutes('register').name}</Typography>
                    <Typography variant="body1" align="center">Lets grow your brand together.</Typography>
                </React.Fragment>
            )}

            {(location.pathname === PreAuthRoutes('forgot_password').path) && (
                <React.Fragment>
                    <Typography variant="h6" align="center">Find your <span></span> account</Typography>
                    <Typography variant="body1" align="center" paragraph>Enter your email address to reset password.</Typography>
                </React.Fragment>
            )}
        </Box>
    )
}

export { Header };
