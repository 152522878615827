import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Helmet } from "react-helmet-async";

import { APP_NAME, handleInputChange, validateForm } from '../../../_helpers';
import { PreSignIn } from '../../../_layouts/pre-signin';
import { PreAuthRoutes } from '../../../routes';

import { FormPassword } from '../../../_components/form';
import { SubmitButton } from '../../../_components/controls';

import { Grid } from '@mui/material';
import { SignInAction } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { validate } from '.';

// Initialize form input values to null
const inputs = { email: '', password: '' };

function SignInPassword() {


    /** Initialize plugins and variables */
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { loading: isLoading, insta_signin_loading: isInstaSigninLoading, password_errors: signinErrors } = useSelector((state) => state.SignInReducer);
    const signIn = (params) => dispatch(SignInAction.signIn(params));
    const signInPassword = (params) => dispatch(SignInAction.signInPassword(params));

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...signinErrors });
    }, [signinErrors])

    useEffect(() => {
        checkEmail();
        //eslint-disable-next-line
    }, [])

    /**
      * function to check email exists and assign to data
      * @author Akshay N
      */
    async function checkEmail() {
        const query = new URLSearchParams(location.search);
        let queryEmail = query.get('e');
        if (!queryEmail)
            history.push('/');

        const isSignin = await signIn({ email: queryEmail });
        if (isSignin.status === 1)
            setData({ ...data, email: queryEmail })
        else
            history.push('/');

    }

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchSignInPasswordAction();
    }

    /**
     * function to dispatch sign in
     * @author Akshay N
     */
    async function dispatchSignInPasswordAction() {
        const isSignin = await signInPassword(data);
        if (isSignin && isSignin.status === 1) {
            let redirectPath = ((location.state && location.state.from.pathname) ? (location.state.from.pathname) : '/brands');
            history.push(redirectPath);
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PreAuthRoutes('login_password').name}`}</title>
            </Helmet>
            <PreSignIn>
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} p="0">
                            <FormPassword
                                tabIndex={1}
                                label='Password'
                                name='password'
                                value={data.password}
                                error={action.isSubmitted && errors.password ? errors.password : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <SubmitButton label={'Next'} loading={isLoading || isInstaSigninLoading} fullWidth />
                        </Grid>
                    </Grid>
                </form>
            </PreSignIn>
        </React.Fragment>
    );
}


export { SignInPassword };
