import React, { useState } from 'react'

import { AccountCircle, Logout } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, MenuItem, Menu } from '@mui/material';

import { Link, useHistory } from 'react-router-dom';
import { PostAuthRoutes, PreAuthRoutes } from '../../../../routes';
import { UserAction } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';

function AccountMenu() {

    const history = useHistory();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    /** Redux actions and state */
    const logout = () => dispatch(UserAction.logout());

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    /** Redux actions and state */
    const signOut = () => logout().then(() => {
        let redirectPath = PreAuthRoutes('login').path;
        history.push(redirectPath);
    });

    return (
        <React.Fragment>
            <IconButton onClick={handleClick}>
                <AccountCircle />
            </IconButton>
            <Menu anchorEl={anchorEl} open={openMenu} onClose={handleClose} onClick={handleClose} PaperProps={{ elevation: 0 }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}   >
                <MenuItem component={Link} to={PostAuthRoutes('profile_details').path}>
                    <ListItemIcon>{React.createElement(PostAuthRoutes('profile_details').menuIcon)} </ListItemIcon>
                    {PostAuthRoutes('profile_details').name}
                </MenuItem>
                <Divider />
                <MenuItem onClick={signOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}

export { AccountMenu }
