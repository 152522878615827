import React from 'react';
import { PostAuthRoutes } from '../../../routes';
import { Helmet } from "react-helmet-async";
import { APP_NAME } from '../../../_helpers';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { BrandDetail, CouponDetails, DealDetail } from './brand-detail';
import { DealMode } from './deal-mode';


function BrandDetails() {


    /** Initialize plugins and variables */
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const brandId = params.get('bId'); // plan promo from url

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PostAuthRoutes('brand_details').name}`}</title>
            </Helmet>

            <Grid container spacing={2} justify="center" p={4}>
                <Grid item xs={6} md={6} >
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} md={12} >
                            <BrandDetail brand_id={brandId} />
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <CouponDetails brand_id={brandId} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={6} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} >
                            <DealMode brand_id={brandId} />
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <DealDetail brand_id={brandId} />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>


        </React.Fragment>
    );
}

export { BrandDetails };
