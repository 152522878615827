import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BrandAction } from '../../../../redux/actions'
import { Box } from '@mui/material';


function DailyCouponsIssued({ brand_id }) {



    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { daily_coupons_issued_loading: isLoading, daily_coupons_issued } = useSelector((state) => state.BrandReducer);
    const getDailyCouponsIssued = (params) => dispatch(BrandAction.getDailyCouponsIssued(params));


    useEffect(() => {
        getDailyCouponsIssued({ brand_id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand_id])

    const options = {
        chart: {
            type: 'column',
            width: 500,
        },
        title: {
            text: 'Daily Coupons Issued'
        },
        xAxis: {
            categories: (isLoading ? [] : daily_coupons_issued.date),
            crosshair: true,
            min: 0,
            max: 9
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Coupons'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [{
            name: 'Coupons Issued',
            data: (isLoading ? [] : daily_coupons_issued.count)
        }
        ],
        scrollbar: true,
        credits: false,

    };

    return (

        <Box mt={4}>
            {/* {Object.keys(daily_coupons_issued).length > 0 && */}
            <HighchartsReact highcharts={Highcharts} options={options} />
            {/* } */}
            {/* {Object.keys(daily_coupons_issued).length === 0 && <CardContent>Not found</CardContent>} */}
        </Box>
    );
}

export { DailyCouponsIssued };
