import React, { useEffect } from 'react'

import { Avatar, Grid, Typography } from '@mui/material'
import { Card, CardContent } from './../../../_components/card'
import { Box } from '@mui/system'
import { connect } from 'react-redux'
import { useLocation } from 'react-router';

function ProfileDetails({ user }) {
    const location = useLocation();
    // const [snackBar, setSnackBar] = useState({ type: '', message: '' });

    useEffect(() => {
        if (location.openSnackbar) {
            // setSnackBar({ type: location.snackbarType, message: location.snackbarMessage })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Card>
            <CardContent>
                {/* {snackBar.message && <SnackBarAlert message={snackBar.message} type={snackBar.type} />} */}
                <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Avatar sx={{ width: 150, height: 150 }}>{user.short_name}</Avatar>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Typography variant="h6" color="primary">{user.name}</Typography>
                        <Typography variant="body1" color="initial">{user.email}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

const mapStateToProps = (state) => ({
    user: state.UserReducer.user,
})

const connectedProfileDetails = connect(mapStateToProps, null)(ProfileDetails);
export { connectedProfileDetails as ProfileDetails };


