import { apiServices } from "../../_helpers";

export const EmailLogServices = {

    getEmailLogsList: (params) => {
        return apiServices.post(`/email-logs/list`, params)
            .then((response) => { return response; })
    },

    resendMail: (params) => {
        return apiServices.post(`/email-logs/resend`, { params: params })
            .then((response) => { return response; })
    },

}

