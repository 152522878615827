import { Chip, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormSelect } from "../../../../_components/form";
import { BrandAction } from "../../../../redux/actions";
import { Modal, ModalBody, ModalFooterCloseBtn, ModalFooterSaveBtn, ModalHeader } from "../../../../_components/modal";
import { handleInputChange } from "../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";

// Initialize form input values to null
const inputs = { brand_id: '' };

function ShoppersFilter({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Initialize and declare state */
    const { show, closeModal, applyFilter, shoppersFilter } = props;
    const [data, setData] = useState({ ...inputs });
    const [filteredData, setFilteredData] = useState({});

    /** Redux actions and state */
    const { brand_select_list_loading, brand_select_list } = useSelector((state) => state.BrandReducer);
    const getBrandsSelectList = (params) => dispatch(BrandAction.getBrandsSelectList(params));

    /**
     * function to close permission filter modal
     * @param {string} status Entered status on permission filter modal
     * @param {string} month Entered month on permission filter modal 
     * @return view
     * @author 
     * @created_at 14 June 2022 
     */
    const onClose = () => {
        setData({ brand_id: '' });
        closeModal(false);
    }

    /**
     * function to clear permission filter modal
     * @param {string} status Entered status on permission filter modal
     * @param {string} month Entered month on permission filter modal 
     * @return view
     * @author  
     * @created_at 14 June 2022 
     */
    const onClear = () => {
        setData({ brand_id: '' });
        applyFilter({ brand_id: '' });
        closeModal(false);
    }

    useEffect(() => {
        getBrandsSelectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setData({ brand_id: shoppersFilter.brand_id })

        let filtered = {};
        Object.keys(shoppersFilter).forEach(function (item, i) {
            if (shoppersFilter[item]) {
                filtered[item] = shoppersFilter[item];
                return filtered[item];
            }
        });
        setFilteredData({ ...filtered });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shoppersFilter])


    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Jasin
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author  
     * @created_at 14 June 2022 
     */
    function handleSubmit(e) {
        e.preventDefault();
        applyFilter(data);
        closeModal(false);
    }

    /**
     * function to clear all data entered on permission filter modal
     * @return view
     * @author  
     * @created_at 14 June 2022 
     */
    const clearFilterItem = (item) => {
        const clearItem = { ...data };
        clearItem[item] = '';
        setData({ ...clearItem });
        applyFilter({ ...clearItem });
    }

    return (
        <React.Fragment>
            {
                Object.keys(filteredData).length > 0 &&
                (
                    <Grid container mb={2} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction="row" spacing={1}>
                                {
                                    Object.keys(filteredData).map((item, i) => {
                                        if (item === 'brand_id' && !brand_select_list_loading) {
                                            const brand_id = brand_select_list.find(function (dealAvailable) { return dealAvailable.value === filteredData[item]; });
                                            return <Chip key={i} label={brand_id.label} onDelete={() => clearFilterItem(item)} />
                                        } else {
                                            return <Chip key={i} label={filteredData[item]} onDelete={() => clearFilterItem(item)} style={{ textTransform: `capitalize` }} />
                                        }
                                    })
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                )
            }
            <Modal isDialogOpen={show} onClose={onClose} maxWidth='xs'>
                <form onSubmit={handleSubmit} noValidate >
                    <ModalHeader id="userFilter" onClose={onClose} title='Filter' />
                    <ModalBody>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {!brand_select_list_loading &&
                                    <FormSelect
                                        tabIndex={1}
                                        label={`Brand`}
                                        name={`brand_id`}
                                        placeholder={`Select brand`}
                                        onChange={handleChange}
                                        data={brand_select_list}
                                        value={data.brand_id}
                                    />
                                }
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                                    <ModalFooterSaveBtn tabIndex={4} text='Filter' />
                                    <ModalFooterCloseBtn tabIndex={3} onClose={onClear} text='Clear' />
                                </Stack>
                            </Grid>
                        </Grid>
                    </ModalBody>
                </form>
            </Modal>
        </React.Fragment>
    );
}

export { ShoppersFilter }