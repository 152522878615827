import { apiServices } from "../../_helpers";

export const DealServices = {
    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getDealsCount: (params) => {
        return apiServices.get(`/deals/count`)
            .then((response) => { return response; })
    },
    getBrandDealDetails: (params) => {
        return apiServices.post(`/deals/detail`, params)
            .then((response) => { return response; })
    },

    addDeals: (params) => {

        return apiServices.post(`/deals/create`, params, {
            headers: {
                'form-data': true,
            }
        }).then((response) => { return response; })

    },
    editDeals: (id, params) => {
        return apiServices.post(`/deals/update/${id}`, params, {
            headers: {
                'form-data': true,
            }
        }).then((response) => { return response; })
    },
    getDealUpdateHistory: (params) => {
        return apiServices.post(`/deals/history`, params)
            .then((response) => { return response; })
    },

    changeDealMode: (params) => {
        return apiServices.post(`/deals/update-deal-mode`, params)
            .then((response) => { return response; })
    },


}
