import {
    USER_REQUEST, USER_SUCCESS, USER_FAILURE,
    USER_PERMISSION_REQUEST, USER_PERMISSION_SUCCESS, USER_PERMISSION_FAILURE,
    GET_USERS_LIST_REQUEST, GET_USERS_LIST_SUCCESS, GET_USERS_LIST_FAILURE,
    ADD_USER_REQUEST, ADD_USER_SUCCESS, ADD_USER_FAILURE,
    UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE,
    UPDATE_ADMIN_DETAIL_REQUEST, UPDATE_ADMIN_DETAIL_SUCCESS, UPDATE_ADMIN_DETAIL_FAILURE,
    UPDATE_USER_STATUS_REQUEST, UPDATE_USER_STATUS_SUCCESS, UPDATE_USER_STATUS_FAILURE
} from "../actions";

const initialState = {
    user_loading: true, user: {}, user_errors: [],
    user_permission_loading: true, user_permission: [], user_permission_errors: {},
    users_loading: false, users: [], users_errors: {}, page: 1, per_page: 18, total: 0, total_pages: 0,
    add_user_loading: false, add_user: {}, add_user_errors: {},
    update_user_loading: false, update_user: {}, update_user_errors: {},
    update_admin_loading: false, update_admin: {}, update_admin_errors: {},
    update_user_status_loading: false, update_user_status_errors: {}
};

export function UserReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Sign in password redux */
        case USER_REQUEST: {
            return {
                ...state,
                user_loading: true,
                user_errors: {}
            };
        }
        case USER_SUCCESS: {
            return {
                ...state,
                user_loading: false,
                user: action.data,
                user_errors: {}
            };
        }
        case USER_FAILURE: {
            return {
                ...state,
                user_loading: false,
                user_errors: action.errors
            };
        }
        /** end::Sign in password redux */

        /** begin::User details redux */
        case USER_PERMISSION_REQUEST: {
            return {
                ...state,
                user_permission_loading: true,
                user_permission_errors: {}
            };
        }
        case USER_PERMISSION_SUCCESS: {
            return {
                ...state,
                user_permission_loading: false,
                user_permission: action.data.permissions,
                user_permission_errors: {}
            };
        }
        case USER_PERMISSION_FAILURE: {
            return {
                ...state,
                user_permission_loading: false,
                user_permission_errors: action.errors,
                user_permission: []
            };
        }
        /** end::User details redux */

        //  begin::Get users list redux 
        case GET_USERS_LIST_REQUEST: {
            return {
                ...state,
                users_loading: true,
                users_errors: {},
            }
        }
        case GET_USERS_LIST_SUCCESS: {

            let newArray = [...state.users]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.super_admins.forEach((payload) => {
                const adminIndex = newArray.findIndex(users => users._id === payload._id);
                if (adminIndex !== -1) {
                    newArray[adminIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });


            return {
                ...state,
                users_loading: false,
                users: newArray,
                users_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            }
        }
        case GET_USERS_LIST_FAILURE: {
            return {
                ...state,
                users_loading: false,
                users_errors: action.errors,
            }
        }
        // begin::Get users list redux 


        //  begin::add user redux 
        case ADD_USER_REQUEST: {
            return {
                ...state,
                add_user_loading: true,
                add_user_errors: {}
            }
        }
        case ADD_USER_SUCCESS: {
            return {
                ...state,
                add_user_loading: false,
                add_user: action.data,
            }
        }
        case ADD_USER_FAILURE: {
            return {
                ...state,
                add_user_loading: false,
                add_user_errors: action.errors,
            }
        }
        // begin::add user redux 

        /** begin::Update user redux */
        case UPDATE_USER_REQUEST: {
            return {
                ...state,
                ...state,
                update_user_loading: true,
                update_user_errors: {}
            };
        }
        case UPDATE_USER_SUCCESS: {
            return {
                ...state,
                update_user_loading: false,
                update_user: action.data,
            };
        }
        case UPDATE_USER_FAILURE: {
            return {
                ...state,
                update_user_loading: false,
                update_user_errors: action.errors,
            };
        }
        /** end::Update user redux */

        /** begin::admin details redux */
        case UPDATE_ADMIN_DETAIL_REQUEST: {
            return {
                ...state,
                update_admin_loading: true,
                update_admin_errors: {}
            };
        }
        case UPDATE_ADMIN_DETAIL_SUCCESS: {

            let newArray = [state.users];

            const userIndex = newArray.findIndex(user => user._id === action.data._id);

            if (userIndex !== -1) {
                newArray[userIndex] = action.data;
            }

            return {
                ...state,
                update_admin_loading: false,
                update_admin_errors: {},
                user: newArray[0],
            };
        }
        case UPDATE_ADMIN_DETAIL_FAILURE: {
            return {
                ...state,
                update_admin_loading: false,
                update_admin_errors: action.errors
            };
        }
        /** end::admin details redux */

        //  begin::get user redux 
        case UPDATE_USER_STATUS_REQUEST: {
            return {
                ...state,
                update_user_status_loading: true,
                update_user_status: {}
            }
        }
        case UPDATE_USER_STATUS_SUCCESS: {

            let newArray = [...state.users];
            const userIndex = newArray.findIndex(user => user._id === action.data._id);


            if (userIndex !== -1) {
                newArray[userIndex].status = action.data.status;
            }

            return {
                ...state,
                update_user_status_loading: false,
                update_user_status_errors: {},
                users: newArray
            };
        }
        case UPDATE_USER_STATUS_FAILURE: {
            return {
                ...state,
                update_user_status_loading: false,
                update_user_status_errors: action.errors,
            }
        }
        // begin::get user redux 

        default:
            return state;
    }
}
