import { Backup } from "@mui/icons-material"
import { Button } from "@mui/material"
import React, { useState } from "react"
import { Modal, ModalBody, ModalFooter, ModalFooterCloseBtn, ModalFooterSaveBtn, ModalHeader } from "../../../../_components/modal";
import { FormBrowseFile } from "../../../../_components/form";
import { handleInputChange, validateForm } from "../../../../_helpers";
import validateCouponImport from "./validateCouponImport";
import { useDispatch } from "react-redux";
import { TrialCouponAction } from "../../../../redux/actions";

function ImportTrialCouponCodes({resetTable}) {
    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    const inputs = { file: '' }

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    const importTrialCoupons = (params) => dispatch(TrialCouponAction.importTrialCoupons(params));

    const openImportModal = (e) => {
        e.preventDefault();
        setData({ ...inputs });
        setShow(true);
    }

    const onClose = () => {
        setShow(false);
        setErrors({});
    }

    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateCouponImport(name, value)) });
        data[name] = value;
        setData({ ...data });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateCouponImport(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true, isProcessing: false });
            return;
        }
        formData.append('file', data.file);
        dispatchCouponImport(formData);
    }

    const dispatchCouponImport = (formData) => {
        importTrialCoupons(formData).then((response) => {
            if (response.status === 1) {
                setShow(false);
                setAction({ isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
                resetTable();
            } else {
                setAction({ isSubmitted: true, isProcessing: false });
            }
        });
    }

    return (
        <React.Fragment>

            <Modal isDialogOpen={show} onClose={onClose}>
                <form onSubmit={handleSubmit} noValidate >
                    <ModalHeader id="importCoupon" title="Import Coupon" onClose={onClose} />
                    <ModalBody>
                        <FormBrowseFile
                            label="Choose File"
                            name="file"
                            tabIndex={1}
                            error={(action.isSubmitted && (errors.file)) ? (errors.file) : ''}
                            placeholder="Choose your file"
                            value={data.file}
                            onChange={handleChange}
                            info="Allowed Format:csv , Allowed Maximum Size: 20 MB"
                            acceptType=".csv"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <ModalFooterCloseBtn tabIndex={2} onClose={onClose} isProcessing={action.isProcessing} />
                        <ModalFooterSaveBtn tabIndex={3} isProcessing={onClose.isProcessing} text="Import" />
                    </ModalFooter>
                </form>
            </Modal>
            <Button variant="contained" color="primary" startIcon={<Backup>send</Backup>} onClick={openImportModal}>
                Upload trial coupon codes
            </Button>
        </React.Fragment>
    )
}

export { ImportTrialCouponCodes }