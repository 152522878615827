import { InputLabel, OutlinedInput, FormHelperText } from "@mui/material";
import { maskInput } from "../_helpers";
import React from "react";
import { StyledFormInput } from ".";

function FormTextArea({ name, label, placeholder, type, className, id, value, onChange, isReadOnly, maxLength, maskType, tabIndex, error, info, ...props }) {

    const checkKeyPress = (e) => {
        return maskInput(maskType, e);
    };

    const handleChange = (e) => {
        onChange(e);
    }

    return (
        <StyledFormInput fullWidth>
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <OutlinedInput
                aria-describedby={`helper_${name}`}
                type={type}
                placeholder={placeholder ? placeholder : label}
                name={name}
                value={value}
                onChange={handleChange}
                onKeyPress={checkKeyPress}
                label={label}
                error={error ? true : false}
                inputProps={{ maxLength: maxLength }}
                {...props}
            />
            {(info) && <FormHelperText id={`helper_${name}`} >{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error>{error}</FormHelperText>}
        </StyledFormInput>
    )
}

FormTextArea.defaultProps = {
    rows: "3",
    className: ""
}

export { FormTextArea }


