const validateProfileUpdate = (name, value, data) => {
    // eslint-disable-next-line no-useless-escape
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const errors = {};
    switch (name) {
        case 'first_name': {
            errors.first_name = '';
            if (value === '')
                errors.first_name = 'Enter first name';
            break;
        } case 'last_name': {
            errors.last_name = '';
            if (value === '')
                errors.last_name = 'Enter last name';
            break;
        } case 'email': {
            errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid';
            if (value === '')
                errors.email = 'Enter an email address';
            break;
        } default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}
export default validateProfileUpdate;