import { ShopperServices } from "../services";

export const GET_SHOPPER_COUNT_REQUEST = 'GET_SHOPPERS_COUNT_REQUEST';
export const GET_SHOPPER_COUNT_SUCCESS = 'GET_SHOPPERS_COUNT_SUCCESS';
export const GET_SHOPPER_COUNT_FAILURE = 'GET_SHOPPERS_COUNT_FAILURE';

export const SHOPPERS_LIST_REQUEST = 'SHOPPERS_LIST_REQUEST';
export const SHOPPERS_LIST_SUCCESS = 'SHOPPERS_LIST_SUCCESS';
export const SHOPPERS_LIST_FAILURE = 'SHOPPERS_LIST_FAILURE';

export const SHOPPERS_BRANDS_REQUEST = 'SHOPPERS_BRAND_DETAILS_REQUEST'
export const SHOPPERS_BRANDS_SUCCESS = 'SHOPPERS_BRAND_DETAILS_SUCCESS'
export const SHOPPERS_BRANDS_FAILURE = 'SHOPPERS_BRAND_DETAILS_FAILURE'

export const SHOPPER_SELECT_LIST_REQUEST = 'SHOPPER_SELECT_LIST_REQUEST';
export const SHOPPER_SELECT_LIST_SUCCESS = 'SHOPPER_SELECT_LIST_SUCCESS';
export const SHOPPER_SELECT_LIST_FAILURE = 'SHOPPER_SELECT_LIST_FAILURE';

export const ShopperAction = {

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getShoppersCount: () => {
        return dispatch => {
            dispatch(request())
            return ShopperServices.getShoppersCount()
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        }

        function request() { return { type: GET_SHOPPER_COUNT_REQUEST } }
        function success(response) { return { type: GET_SHOPPER_COUNT_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status.code } }
        function failure(error) { return { type: GET_SHOPPER_COUNT_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }

    },

    /**
     * function get Shopper list
     * @param params
     * @return view
     * @author Jasin
     * @created_at 28/12/2021
     */
    getShoppersList: (params) => {

        return dispatch => {
            dispatch(request(params))
            return ShopperServices.getShoppersList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        }


        function request(request) { return { type: SHOPPERS_LIST_REQUEST, request } }
        function success(request, response) { return { type: SHOPPERS_LIST_SUCCESS, request, message: response.message, data: response.data, totalCount: response.count, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: SHOPPERS_LIST_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }

    },

    /**
     * function to get Shopper brand details
     * @param params
     * @return view
     * @author Naveen
     * @created_at 02/01/2023
     */
    getShoppersBrandsList: (params = null) => {
        return dispatch => {
            dispatch(request(params))
            return ShopperServices.getShoppersBrandsList(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(params, error)); }
                );
        }

        function request(request) { return { type:SHOPPERS_BRANDS_REQUEST, request } }
        function success(request, response) { return { type:SHOPPERS_BRANDS_SUCCESS, request, message: response.message, data: response.data, totalCount: response.count, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type:SHOPPERS_BRANDS_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },

    /**
    * Action for shopper select list
    * @author Naveen 
    */
    shopperSelectList: () => {
        return dispatch => {
            dispatch(request());
            return ShopperServices.shopperSelectList()
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: SHOPPER_SELECT_LIST_REQUEST } }
        function success(response) { return { type: SHOPPER_SELECT_LIST_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: SHOPPER_SELECT_LIST_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

}