import React from 'react';
import { Grid } from '@mui/material';

import { BrandsCountWidget, DealsCountWidget, ShoppersCountWidget } from './count-widgets';

function Dashboard() {

    return (

        <Grid container spacing={2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Grid item xs={2}>
                <BrandsCountWidget />
            </Grid>
            <Grid item xs={2}>
                <DealsCountWidget />
            </Grid>
            <Grid item xs={2}>
                <ShoppersCountWidget />
            </Grid>
        </Grid>

    )
}

export { Dashboard }
